import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, IconSettings, PageHeader, PageHeaderControl } from '@salesforce/design-system-react';
import NoItemsCard from '../../components/NoItemsCard';
import SuppressionRulesTable from './SuppressionRulesTable';
import apiCalls from '../shared/api';
import { constants } from '../../constants';
import { RefreshContext } from '../shared/refresh-context';
import { ToastContext } from '../shared/toast-context';
import { getNameFromLabel, parseFilter } from '../shared/utilities';
import SuppressionRuleModal from './SuppressionRuleModal';
import RuleDeleteModal from './RuleDeleteModal';
import './Rules.scss';

const ruleStateOptions = [
  { label: constants.ACTIVE_SUPPRESSION_RULE_LABEL, value: constants.ACTIVE },
  { label: constants.PENDING_SUPPRESSION_RULE_LABEL, value: constants.PENDING },
  { label: constants.EXPIRED_SUPPRESSION_RULE_LABEL, value: constants.EXPIRED },
];
const defaultRuleState = { label: constants.ACTIVE_SUPPRESSION_RULE_LABEL, value: constants.ACTIVE };

const SuppressionRules = (props) => {
  const serviceOwners = props.serviceOwners;

  const [selectedRuleState, setSelectedRuleState] = useState(defaultRuleState);
  const [sortProperties, setSortProperties] = useState({ sortField: 'label', sortDirection: 'asc' });
  const [suppressionRules, setSuppressionRules] = useState([]);
  const [filteredSuppressionRules, setFilteredSuppressionRules] = useState([]);
  const [loadingSuppressionRules, setLoadingNotificationRules] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const refreshContext = useContext(RefreshContext);

  const setOpenCreate = useCallback(() => {
    setModalData({
      label: '',
      name: '',
      ruleId: -1,
      serviceId: props.serviceId,
      reason: '',
      startDateMs: Date.now(),
      endDateMs: Date.now(),
    });
    setMode(constants.CREATE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEditClosed = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const setEditOpen = useCallback((a) => {
    setModalData({
      label: a.label,
      name: a.name,
      ruleId: a.id,
      serviceId: props.serviceId,
      reason: a.reason,
      startDateMs: a.startDateMs,
      endDateMs: a.endDateMs,
    });
    setMode(constants.EDIT_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeleteOpen = React.useCallback((a) => {
    setModalData({
      name: a.name,
      label: a.label,
      ruleId: a.id,
      serviceId: props.serviceId,
      createdBy: a.createdBy,
      reason: a.reason,
      startDateMs: a.startDateMs,
      endDateMs: a.endDateMs,
    });
    setMode(constants.DELETE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeleteClosed = async (deleteRule) => {
    if (deleteRule === constants.DELETE) {
      const response = await apiCalls.deleteRuleById(modalData.ruleId);
      if (response.status === 200 || response.status === 204) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Suppression rule was deleted.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      }
      await fetchSuppressionRules(-1);
    }
    setIsModalOpen(false);
  };

  const setCloneOpen = React.useCallback((a) => {
    let newLabel = a.label + ' Copy';
    setModalData({
      name: getNameFromLabel(
        'rule-',
        newLabel,
        suppressionRules.map((fsr) => fsr.name)
      ),
      label: newLabel,
      ruleId: a.id,
      serviceId: props.serviceId,
      reason: a.reason,
      startDateMs: Date.now(),
      endDateMs: Date.now(),
    });
    setMode(constants.CLONE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilteredRules = (value) => {
    let data = value.data || suppressionRules;
    let filteredRules;
    let ruleState;
    if (value.value === constants.EXPIRED) {
      ruleState = { label: constants.EXPIRED_SUPPRESSION_RULE_LABEL, value: constants.EXPIRED };
      filteredRules = data.filter((suppressionRule) => {
        return suppressionRule.endDateMs <= Date.now();
      });
    } else if (value.value === constants.ACTIVE) {
      ruleState = { label: constants.ACTIVE_SUPPRESSION_RULE_LABEL, value: constants.ACTIVE };
      filteredRules = data.filter((suppressionRule) => {
        return suppressionRule.startDateMs <= Date.now() && suppressionRule.endDateMs > Date.now();
      });
    } else {
      ruleState = { label: constants.PENDING_SUPPRESSION_RULE_LABEL, value: constants.PENDING };
      filteredRules = data.filter((suppressionRule) => {
        return suppressionRule.startDateMs > Date.now();
      });
    }
    setSelectedRuleState(ruleState);
    setFilteredSuppressionRules(filteredRules);
    // Now sort the data
    sortTable(filteredRules, sortProperties.sortField, sortProperties.sortDirection);
  };

  const fetchSuppressionRules = async (id) => {
    setLoadingNotificationRules(true);

    let response = [];
    try {
      // Get the Notification Rules
      if (id === -1) {
        response = await apiCalls.getRulesByServiceIdAndType(props.serviceId, false, 'SUPPRESSION');
      } else {
        response = await apiCalls.getRuleById(id, false);
      }
      if (response.status !== 200) {
        // no rules returned
        setLoadingNotificationRules(false);
        return;
      }
    } catch {
      setLoadingNotificationRules(false);
      return;
    }

    // We want the results in an array, regardless of whether we got all the rules or just one.
    const rulesData = id === -1 ? response.data : [response.data];
    let rulesList = [];

    // Transform API data to something better suited to DataTable.
    Object.entries(rulesData).forEach((datum) => {
      if (datum[0] !== 'error' && datum[0] !== 'code' && datum[0] !== 'errors') {
        const rule = {};
        rule.id = datum[1].id.toString();
        rule.name = datum[1].name;
        rule.label = datum[1].label;
        rule.modifiedBy = datum[1].modifiedBy;
        rule.modifiedDate = new Date(datum[1].modifiedDate).toLocaleString('en', {
          timeStyle: 'short',
          dateStyle: 'short',
        });
        rule.modifiedDateMs = datum[1].modifiedDate;
        rule.ruleConditions = parseFilter(datum[1].filter);
        rule.filter = datum[1].filter;
        rule.createdBy = datum[1].createdBy;
        rule.startDateMs = datum[1].startDate;
        rule.startDate = new Date(datum[1].startDate).toLocaleString('en', { timeStyle: 'short', dateStyle: 'short' });
        rule.endDateMs = datum[1].endDate;
        rule.endDate = new Date(datum[1].endDate).toLocaleString('en', { timeStyle: 'short', dateStyle: 'short' });
        rule.reason = datum[1].reason;

        if (id === -1) {
          rulesList.push(rule);
        } else {
          let newRulesList = [];
          let added = false;
          for (let i = 0; i < rulesList.length; i++) {
            if (rulesList[i].id === id) {
              newRulesList.push(rule);
              // Rule to refresh replaced in rules
              added = true;
            } else {
              newRulesList.push(rulesList[i]);
            }
          }
          if (!added) {
            // Rule to refresh not found in rules, adding
            newRulesList.push(rule);
          }
          rulesList = newRulesList;
        }
      }
    });

    setSuppressionRules(rulesList);
    setFilteredRules({
      label: selectedRuleState.label,
      value: selectedRuleState.value,
      data: rulesList,
    });
    setLoadingNotificationRules(false);
  };

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      // Sort modified by date using the epoch time field instead of the human readable one
      let actualSortField = sortField;
      if (sortField === 'modifiedDate') {
        actualSortField = 'modifiedDateMs';
      } else if (sortField === 'startDate') {
        actualSortField = 'startDateMs';
      } else if (sortField === 'endDate') {
        actualSortField = 'endDateMs';
      }

      let result = 1;
      if (typeof x[actualSortField] === 'string') {
        result = x[actualSortField].localeCompare(y[actualSortField], 'en', { sensitivity: 'case' });
      } else if (x[actualSortField] < y[actualSortField]) {
        result = -1;
      } else if (x[actualSortField] === y[actualSortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });

    setFilteredSuppressionRules(data);
  };

  const handleSort = useCallback(
    (sortColumn, ...rest) => {
      sortTable(filteredSuppressionRules, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [filteredSuppressionRules]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      label: 'Rule Name',
      ruleConditions: 'Rule Criteria',
      reason: 'Reason',
      startDate: 'Start Time',
      endDate: 'End Time',
      modifiedBy: 'Last Modified By',
      modifiedDate: 'Last Modified',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by&nbsp;
        {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  useEffect(() => {
    async function fetchSuppressionRulesData() {
      await fetchSuppressionRules(-1);
    }

    fetchSuppressionRulesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceId, refreshContext.suppressionRules]);

  const foundItems = filteredSuppressionRules.length;

  const actions = () => (
    <>
      <PageHeaderControl>
        <Button label="New Suppression Rule" onClick={setOpenCreate} />
      </PageHeaderControl>
    </>
  );

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        <PageHeader
          info={getListInfo(foundItems)}
          nameSwitcherDropdown={
            <Dropdown
              assistiveText={{ icon: 'Rule State Switcher' }}
              buttonClassName="slds-button_icon-small"
              buttonVariant="icon"
              checkmark
              iconCategory="utility"
              iconName="down"
              iconVariant="border"
              id="page-header-name-switcher-dropdown"
              options={ruleStateOptions}
              onSelect={setFilteredRules}
              value={defaultRuleState.value}
              width="xx-small"
            />
          }
          title={selectedRuleState.label}
          truncate
          joined
          variant="object-home"
          onRenderActions={actions}
        />
        {isModalOpen && mode !== constants.DELETE_MODE ? (
          <SuppressionRuleModal
            isOpen={true}
            setClosed={setEditClosed}
            mode={mode}
            existingRuleNames={props.existingRuleNames}
            existingRuleLabels={props.existingRuleLabels}
            serviceId={props.serviceId}
            serviceName={props.serviceName}
            data={modalData}
          />
        ) : null}
        {isModalOpen && mode === constants.DELETE_MODE ? (
          <RuleDeleteModal
            type="Suppression"
            serviceOwners={serviceOwners}
            data={modalData}
            onRequestCloseCallback={setDeleteClosed}
          />
        ) : null}
        {foundItems > 0 && !loadingSuppressionRules ? (
          <>
            <SuppressionRulesTable
              onEdit={setEditOpen}
              onDelete={setDeleteOpen}
              onClone={setCloneOpen}
              suppressionRules={filteredSuppressionRules}
              sortField={sortProperties.sortField}
              sortDirection={sortProperties.sortDirection}
              handleSort={handleSort}
              selectedRuleState={selectedRuleState.value}
            />
            <div className="footer-bar"></div>
          </>
        ) : !loadingSuppressionRules ? (
          <NoItemsCard
            itemName="Suppression Rule"
            createDescription="Create a suppression rule to specify which alerts should be dropped."
            onCreateClicked={setOpenCreate}
          />
        ) : null}
      </IconSettings>
    </div>
  );
};

export default SuppressionRules;
