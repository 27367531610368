import React, { Fragment, useState, useContext } from 'react';
import './AlertEditModal.scss';
import { Input, Modal, Button, Textarea, Combobox } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../constants';
import apiCalls from '../../../shared/api';
import { ToastContext } from '../../../shared/toast-context';

const AlertEditModal = (props) => {
  // Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const sourceAlertId = props.data.sourceAlertId;
  const [component, setComponent] = useState(props.data.component);
  const [description, setDescription] = useState(props.data.description);
  const [knowledgeArticleId, setKnowledgeArticleId] = useState(props.data.knowledgeArticleId);
  const [name, setName] = useState(props.data.name);
  const [severity, setSeverity] = useState(props.data.severity);
  const [source, setSource] = useState(props.data.source);
  const [status, setStatus] = useState(props.data.status);

  const [statusError, setStatusError] = useState('');
  const [nameError, setNameError] = useState('');
  const [severityError, setSeverityError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [sourceError, setSourceError] = useState('');

  const [selectedSeverity, setSelectedSeverity] = useState(
    props.data.severity === undefined
      ? []
      : [{ id: props.data.severity.toString(), label: props.data.severity.toString(), value: props.data.severity }]
  );
  const [selectedStatus, setSelectedStatus] = useState([
    {
      id: props.data.status,
      label:
        props.data.status === constants.ALERT_STATUS_ACTIVE
          ? 'Active'
          : props.data.status === constants.ALERT_STATUS_ACKNOWLEDGE
          ? 'Acknowledged'
          : 'Cleared',
    },
  ]);

  const close = () => {
    props.setIsModalOpen(false);
  };

  const onSave = async () => {
    let hasError = validateInput();

    if (!hasError) {
      const alertPayload = {
        modifiedByUI: true,
        sourceAlertId: props.data.sourceAlertId,
        serviceId: props.data.serviceId,
        service: props.data.service,
        ...(props.data.custom !== undefined && { custom: props.data.custom }),
        status: status,
        ...(name !== undefined && { name: name }),
        ...(severity !== undefined && { severity: severity }),
        ...(description !== undefined && { description: description }),
        ...(source !== undefined && { source: source }),
        ...(component !== undefined && { component: component }),
        ...(knowledgeArticleId !== undefined && { knowledgeArticleId: knowledgeArticleId }),
      };
      try {
        const alertResponse = await apiCalls.createAlert(alertPayload);
        if (alertResponse.status === 200) {
          toastDispatch({
            type: 'SET_TOAST_STATE',
            value: { ...toastState, toastMessage: 'Alert was saved.', toastVariant: 'success', showToast: true },
          });
          // Refresh after a timeout
          setTimeout(() => {
            props.setRefresh(!props.refresh);
          }, constants.ELASTIC_SEARCH_UPDATE_DELAY);
          close();
        }
      } catch {
        return;
      }
    }
  };

  const validateInput = () => {
    let statusHasError = false;
    let nameHasError = false;
    let severityHasError = false;
    let descriptionHasError = false;
    let sourceHasError = false;

    // Status
    if (status === undefined) {
      statusHasError = true;
      setStatusError(errors.REQUIRED);
    } else {
      setStatusError('');
    }

    // For alerts required fields are: status, name, severity, description, source
    // Name
    if (name === undefined || name.trim() === '') {
      nameHasError = true;
      setNameError(errors.REQUIRED);
    } else {
      setNameError('');
    }

    // Severity
    if (severity === undefined) {
      severityHasError = true;
      setSeverityError(errors.REQUIRED);
    } else {
      setSeverityError('');
    }

    // Description
    if (description === undefined || description.trim() === '') {
      descriptionHasError = true;
      setDescriptionError(errors.REQUIRED);
    } else {
      setDescriptionError('');
    }

    // Source
    if (source === undefined || source.trim() === '') {
      sourceHasError = true;
      setSourceError(errors.REQUIRED);
    } else {
      setSourceError('');
    }

    return statusHasError || nameHasError || severityHasError || descriptionHasError || sourceHasError;
  };

  const SEVERITY_OPTIONS = [...Array(6).keys()].map((i) => {
    return { id: i.toString(), label: i.toString(), value: i };
  });

  const STATUS_OPTIONS = [
    {
      id: 'ACKNOWLEDGE',
      label: 'Acknowledged',
    },
    {
      id: 'ACTIVE',
      label: 'Active',
    },
    {
      id: 'CLEAR',
      label: 'Cleared',
    },
  ];

  return (
    <Fragment>
      <Modal
        containerClassName="alert-edit-modal-container"
        contentClassName="alert-edit-modal-content"
        isOpen={true}
        heading={'Edit Alert'}
        dismissOnClickOutside={false}
        onRequestClose={() => {
          close();
        }}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => close()} />,
          <Button key="save" label="Save" onClick={() => onSave()} variant="brand" />,
        ]}
      >
        <Input
          className="input-alert-source-alert-id"
          id="input-alert-source-alert-id"
          label="Source Alert ID"
          value={sourceAlertId}
          disabled
        />
        <Combobox
          className="input-alert-status"
          classNameMenu="modal-combobox-dropdown"
          id="input-alert-status"
          labels={{
            label: 'Status',
            placeholder: 'Status',
          }}
          events={{
            onSelect: (event, data) => {
              setSelectedStatus(data.selection);
              if (data.selection.length !== 0) {
                setStatus(data.selection[0].id);
              } else {
                setStatus(undefined);
              }
            },
          }}
          selection={selectedStatus}
          options={
            props.data.status !== constants.ALERT_STATUS_CLEAR
              ? STATUS_OPTIONS
              : STATUS_OPTIONS.filter((e) => e.id !== constants.ALERT_STATUS_ACKNOWLEDGE)
          }
          variant="readonly"
          menuPosition="overflowBoundaryElement"
          required
          errorText={statusError}
        />
        <Combobox
          className="input-alert-severity"
          classNameMenu="modal-combobox-dropdown"
          id="input-alert-severity"
          labels={{
            label: 'Severity',
            placeholder: 'Severity',
          }}
          events={{
            onSelect: (event, data) => {
              setSelectedSeverity(data.selection);
              if (data.selection.length !== 0) {
                setSeverity(data.selection[0].value);
              } else {
                setSeverity(undefined);
              }
            },
          }}
          selection={selectedSeverity}
          options={SEVERITY_OPTIONS}
          variant="readonly"
          menuPosition="overflowBoundaryElement"
          menuItemVisibleLength={7}
          required
          errorText={severityError}
        />
        <Input
          className="input-alert-name"
          id="input-alert-name"
          label="Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          required
          errorText={nameError}
        />
        <Textarea
          className="input-alert-description"
          id="input-alert-description"
          label="Description"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          required
          errorText={descriptionError}
        />
        <Input
          className="input-alert-source"
          id="input-alert-source"
          label="Source"
          onChange={(e) => {
            setSource(e.target.value);
          }}
          value={source}
          required
          errorText={sourceError}
        />
        <Input
          className="input-alert-component"
          id="input-alert-component"
          label="Component"
          onChange={(e) => {
            setComponent(e.target.value);
          }}
          value={component}
          required={false}
        />
        <Input
          className="input-alert-kb"
          id="input-alert-kb"
          label="KB Article"
          onChange={(e) => {
            setKnowledgeArticleId(e.target.value);
          }}
          value={knowledgeArticleId}
          required={false}
        />
      </Modal>
    </Fragment>
  );
};

export default AlertEditModal;
