import './CenteredSpinner.scss';
import React from 'react';
import { Spinner } from '@salesforce/design-system-react';

// Renders spinner that is centered vertically and horizontally within parent div
const CenteredSpinner = (props) => {
  return (
    <Spinner
      containerClassName="spinner-container"
      hasContainer={true}
      variant={props.variant}
      isInline={true}
      size={props.size}
    />
  );
};

export default CenteredSpinner;
