import React, { useEffect, useState, Fragment, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../constants';
import apiCalls from '../../../shared/api';
import useDebounce from '../../../shared/hooks/useDebounce';
import './UserCombobox.scss';

// Fetches user info based on first and last name
// Autofills by user id passed as prop
// Can be required passed as prop
// Will return in callback onSelect function either undefined or json object with user details
const UserCombobox = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      if (props.required) {
        if (selectedUser.length === 0 || selectedUser[0].id === '') {
          hasError = true;
          setError(errors.REQUIRED);
        }
      } else {
        hasError = false;
      }
      return hasError;
    },
  }));
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, constants.SEARCH_DELAY);

  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  useEffect(() => {
    const setSelectedUserFromProps = async () => {
      if (props.userId !== undefined && props.userId !== '') {
        const response = await apiCalls.getUserById(props.userId);
        const data = response.data;
        if (data[0] !== undefined) {
          setSelectedUser([
            {
              id: data[0].id,
              label: data[0].name,
              icon: (
                <Fragment>
                  <img className="user-combobox-profile-img" src={data[0].photoUrl} alt={data[0].name} />
                </Fragment>
              ),
            },
          ]);
        }
      }
    };
    setSelectedUserFromProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUser.length === 0) {
      props.onSelect(undefined);
    } else {
      props.onSelect(selectedUser[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    const fetchUsersList = async () => {
      let query = debouncedSearchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.getUsers(query);
        const data = response.data;
        if (data !== '') {
          const results = data.map((datum) => {
            return {
              ...datum,
              id: datum.id,
              label: datum.name,
              subTitle: datum.title,
              icon: (
                <Fragment>
                  <img className="user-combobox-profile-img" src={datum.photoUrl} alt={datum.name} />
                </Fragment>
              ),
            };
          });
          setUsers(results);
        }
        setComboboxDropdown(true);
        setSpinner(false);
      }
    };
    fetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <Fragment>
      <Combobox
        className="user-combobox"
        classNameMenu="modal-combobox-dropdown"
        labels={{
          label: props.label,
          placeholder: props.placeholder,
        }}
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedUser(data.selection);
            setUsers([]);
          },
          onSelect: (event, data) => {
            setComboboxDropdown(false);
            setSelectedUser(data.selection);
            setSearchValue('');
            setError('');
          },
        }}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: users,
          selection: selectedUser,
        })}
        selection={selectedUser}
        value={searchValue}
        menuItemVisibleLength={7}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        required={props.required}
        errorText={error}
      />
    </Fragment>
  );
});

UserCombobox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default UserCombobox;
