import React, { createContext, useReducer } from 'react';

const initialState = {
  showToast: false,
  toastMessage: '',
  toastVariant: 'success',
  errorHeading: '',
};

const ToastContext = createContext(initialState);

const ToastContextProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_TOAST_STATE': {
        const newState = {
          ...state,
          showToast: action.value.showToast,
          toastMessage: action.value.toastMessage,
          toastVariant: action.value.toastVariant,
          errorHeading: action.value.errorHeading,
        };
        return newState;
      }
      default:
        throw new Error();
    }
  }, initialState);
  return <ToastContext.Provider value={{ state, dispatch }}>{props.children}</ToastContext.Provider>;
};

export { ToastContext, ToastContextProvider };
