import React, { useEffect, useState, forwardRef, useImperativeHandle, Fragment } from 'react';
import { Combobox, comboboxFilterAndLimit, Icon } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../constants';
import PropTypes from 'prop-types';
import apiCalls from '../../../shared/api';
import useDebounce from '../../../shared/hooks/useDebounce';

const TeamCombobox = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      if (props.required) {
        if (selectedTeam.length === 0 || selectedTeam[0].id === '') {
          hasError = true;
          setError(errors.REQUIRED);
        }
      } else {
        hasError = false;
      }
      return hasError;
    },
  }));

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, constants.SEARCH_DELAY);

  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  useEffect(() => {
    const setSelectedTeamFromProps = async () => {
      if (props.teamId !== undefined && props.teamId !== '') {
        const response = await apiCalls.getTeamById(props.teamId);
        const data = response.data;
        if (data[0] !== undefined) {
          setSelectedTeam([
            {
              id: data[0].id,
              label: data[0].name,
              icon: <Icon assistiveText={{ label: 'Team' }} category="custom" size="small" name="custom15" />,
            },
          ]);
        }
      }
    };
    setSelectedTeamFromProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTeam.length === 0) {
      props.onSelect(undefined);
    } else {
      props.onSelect(selectedTeam[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    const fetchTeamsList = async () => {
      let query = debouncedSearchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.getTeams(query);
        const data = response.data;
        if (data !== '') {
          const results = data.map((datum) => {
            return {
              ...datum,
              id: datum.id,
              label: datum.name,
              icon: <Icon assistiveText={{ label: 'Team' }} category="custom" size="small" name="custom15" />,
            };
          });
          setTeams(results);
        }
        setComboboxDropdown(true);
        setSpinner(false);
      }
    };
    fetchTeamsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <Fragment>
      <Combobox
        className="team-combobox"
        classNameMenu="modal-combobox-dropdown"
        labels={{
          label: props.label,
          placeholder: props.placeholder,
        }}
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedTeam(data.selection);
            setTeams([]);
          },
          onSelect: (event, data) => {
            setComboboxDropdown(false);
            setSelectedTeam(data.selection);
            setSearchValue('');
            setError('');
          },
          onBlur: (e) => {
            setComboboxDropdown(false);
          },
          onFocus: (e) => {
            if (searchValue !== '') {
              setComboboxDropdown(true);
            }
          },
        }}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: teams,
          selection: selectedTeam,
        })}
        selection={selectedTeam}
        value={searchValue}
        menuItemVisibleLength={7}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        required={props.required}
        errorText={error}
      />
    </Fragment>
  );
});

TeamCombobox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default TeamCombobox;
