import React, { Fragment, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Combobox, comboboxFilterAndLimit, Icon } from '@salesforce/design-system-react';
import apiCalls from '../../../shared/api';
import './ChannelEditorProductTag.scss';
import { constants, errors } from '../../../../constants';

const ChannelEditorProductTag = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;

      if (productTagId.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      }
      return hasError;
    },
  }));
  const [error, setError] = useState('');

  // Combobox Variables
  const [searchValue, setSearchValue] = useState('');
  const [productTags, setProductTags] = useState([]);
  const [selectedProductTag, setSelectedProductTag] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  const [productTagId, setProductTagId] = useState(
    props.payload !== undefined ? (props.payload.productTag !== undefined ? props.payload.productTag : '') : ''
  );

  useEffect(() => {
    const setProductTagLabel = async () => {
      if (props.payload !== undefined && props.payload.productTag !== undefined) {
        const response = await apiCalls.getProductTagNameById(props.payload.productTag);
        const data = response.data;
        if (data[0] !== undefined) {
          setSelectedProductTag([
            {
              id: props.payload.productTag,
              subTitle: props.payload.productTag,
              label: data[0].name,
              icon: (
                <Icon
                  containerClassName="channel-editor-type-product-tag-icon-container"
                  className="channel-editor-type-product-tag-icon-svg"
                  assistiveText={{ label: 'Service' }}
                  category="custom"
                  size="x-small"
                  name="custom78"
                />
              ),
            },
          ]);
        }
      }
    };
    setProductTagLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setPayload({ ...props.payload, productTag: productTagId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductTag]);

  useEffect(() => {
    const fetchProductTags = async () => {
      let query = searchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.getProductTags(query);
        const data = response.data;
        if (data !== '') {
          const results = data.map(function (datum) {
            return {
              id: datum.id,
              label: datum.name,
              subTitle: datum.id,
              icon: (
                <Icon
                  containerClassName="channel-editor-type-product-tag-icon-container"
                  className="channel-editor-type-product-tag-icon-svg"
                  assistiveText={{ label: 'Service' }}
                  category="custom"
                  size="medium"
                  name="custom78"
                />
              ),
            };
          });
          setProductTags(results);
        }
        setComboboxDropdown(true);
        setSpinner(false);
      }
    };
    fetchProductTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Fragment>
      <Combobox
        className="channel-editor-type-product-tag"
        classNameMenu="modal-combobox-dropdown"
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedProductTag(data.selection);
            setProductTags([]);
            setProductTagId('');
          },
          onSelect: (event, data) => {
            setSearchValue('');
            setSelectedProductTag(data.selection);
            setProductTagId(data.selection[0].id);
            setError('');
          },
        }}
        labels={{
          label: 'Product Tag',
          placeholder: 'Search Product Tags...',
        }}
        menuItemVisibleLength={7}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: productTags,
          selection: selectedProductTag,
        })}
        selection={selectedProductTag}
        value={searchValue}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        required={props.required !== undefined ? props.required : true}
        errorText={error}
      />
    </Fragment>
  );
});

export default ChannelEditorProductTag;
