import React, { Fragment, forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { Input } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import { constants, errors } from '../../../../constants';

const ServiceEditorName = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [isInputMounted, setInputMounted] = useState(false);

  const serviceId = props.id;
  const serviceToIdMap = props.services.reduce((service, { id, serviceName }) => {
    service[serviceName.toLowerCase()] = id;
    return service;
  }, {});

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let name = props.name !== undefined ? props.name.trim() : '';

      // Validate name
      if (name.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      } else if (name.length > constants.TEXT_CHAR_LIMIT) {
        hasError = true;
        setError(errors.CHAR_LIMIT);
      } else if (serviceId === '' && serviceToIdMap.hasOwnProperty(name.toLowerCase())) {
        hasError = true;
        setError(errors.NON_UNIQUE_NAME);
      } else if (serviceToIdMap[name.toLowerCase()] !== undefined && serviceToIdMap[name.toLowerCase()] !== props.id) {
        hasError = true;
        setError(errors.NON_UNIQUE_NAME);
      } else {
        setError('');
      }

      return hasError;
    },
  }));

  // Focus this input field if focusOnMount prop passed in
  useEffect(() => {
    if (props.focusOnMount && inputRef.current !== undefined) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputMounted]);

  const populateApiName = (input) => {
    let parsed = input;
    parsed = parsed
      .replace(/\s/g, '-')
      .replace(/[^\w-]+/g, '')
      .toLowerCase();
    props.setApiName(parsed);
  };

  const [error, setError] = useState('');

  return (
    <Fragment>
      <Input
        inputRef={(el) => {
          inputRef.current = el;
          setInputMounted(!!el);
        }}
        className="input-service-name"
        id="input-service-name"
        label="Service Name"
        errorText={error}
        required
        onChange={(e) => {
          props.setName(e.target.value);
          if (props.populateApiName) {
            populateApiName(e.target.value);
          }
        }}
        value={props.name}
      />
    </Fragment>
  );
});

ServiceEditorName.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  setName: PropTypes.func,
  services: PropTypes.array,
  focusOnMount: PropTypes.bool,
};

export default ServiceEditorName;
