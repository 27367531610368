import React from 'react';
import {
  Tooltip,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
} from '@salesforce/design-system-react';
import { getChannelTypePill, getFilterInfo } from '../shared/utilities';
import { constants } from '../../constants';

const NotificationRulesTable = (props) => {
  const notificationRules = props.notificationRules;
  const sortField = props.sortField;
  const sortDirection = props.sortDirection;
  const groupingRules = props.groupingRules;

  const createFilterContent = React.useCallback(
    (filter) => {
      return (
        <div className="rules-display">
          <div className="rules-header">Rule Criteria</div>

          {/* <div className="rules-display-cell" style={{ float: 'right' }}>
            <Icon 
              size="x-small"
              name="clear"
              category="utility"></Icon>
          </div> */}

          <div className="rules-display-row">
            <div className="rules-display-cell rules-low-text">Alert matches when:</div>
          </div>
          <div className="rules-display-row ">
            <div className="rules-display-cell rules-bold-text ">{getFilterInfo(filter, groupingRules)}</div>
          </div>

          {/* 
        <div className="rules-display-row">
          <div className="rules-display-cell rules-low-text">Alert matches when:</div>
        </div>
        <div className="rules-display-row rules-hr-after">
          <div className="rules-display-cell rules-bold-text ">Any Condition Is Met</div>
        </div>
        <div className="rules-display-row">
          <div className="rules-display-cell rules-logic">&nbsp;</div> 
          <div className="rules-display-cell rules-low-text">Name contains:</div>
        </div>
        <div className="rules-display-row rules-hr-after">
          <div className="rules-display-cell rules-logic">&nbsp;</div> 
          <div className="rules-display-cell rules-bold-text">High CPU Usage</div>
        </div>
        <div className="rules-display-row">
          <div className="rules-display-cell rules-logic">OR</div> 
          <div className="rules-display-cell rules-low-text">Description contains:</div>
        </div>
        <div className="rules-display-row ">
          <div className="rules-display-cell rules-logic">&nbsp;</div> 
          <div className="rules-display-cell rules-bold-text">High CPU Detected</div>
        </div> */}
        </div>
      );
    },
    [groupingRules]
  );

  const PillDataTableCell = ({ item, field }) => {
    return (
      <DataTableCell className="notifier-pill" key={item.name} title="Notification Channels">
        {item[field].map((channel, index) => {
          return <>{getChannelTypePill(channel.type, channel.typeLabel, 'notifier-pills', index)}</>;
        })}
      </DataTableCell>
    );
  };
  PillDataTableCell.displayName = DataTableCell.displayName;

  const LinkDataTableCell = ({ item, field }) => {
    if (field === 'ruleConditions') {
      return (
        <DataTableCell title={item.ruleConditions} key="ruleConditions">
          <span className="rules-cell-space-to-icon">{item.ruleConditions}</span>
          <Tooltip
            id="base"
            align="top left"
            content={createFilterContent(item.filter)}
            variant="learnMore"
            position="overflowBoundaryElement"
            dialogClassName="dialog-className rules-popup"
          />
        </DataTableCell>
      );
    }
    return null;
  };
  LinkDataTableCell.displayName = DataTableCell.displayName;

  const handleRowAction = (item, action) => {
    switch (action.value) {
      case constants.EDIT_MODE:
        props.onEdit(item);
        break;
      case constants.DELETE_MODE:
        props.onDelete(item);
        break;
      default:
    }
  };

  return (
    <DataTable
      id="notifyrules"
      className="notification-rules"
      assistiveText={{
        actionsHeader: 'actions',
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
        selectAllRows: 'Select all rows',
        selectRow: 'Select this row',
      }}
      fixedHeader
      fixedLayout
      joined
      items={notificationRules}
    >
      <DataTableColumn
        isSorted={sortField === 'label'}
        sortDirection={sortField === 'label' ? sortDirection : null}
        sortable
        width="22%"
        key="ruleName"
        label="Rule Name"
        primaryColumn
        property="label"
        onSort={props.handleSort}
      />

      <DataTableColumn
        key="ruleCriteria"
        label="Rule Criteria"
        property="ruleConditions"
        width="11%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'ruleConditions'}
        sortDirection={sortField === 'ruleConditions' ? sortDirection : null}
      >
        <LinkDataTableCell field="ruleConditions" property="ruleConditions" />
      </DataTableColumn>

      <DataTableColumn key="notificationChannels" label="Notification Channels" property="notificationChannels">
        <PillDataTableCell field="notificationChannels" property="notificationChannels" />
      </DataTableColumn>

      <DataTableColumn
        key="lastModifiedBy"
        label="Last Modified By"
        property="modifiedBy"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedBy'}
        sortDirection={sortField === 'modifiedBy' ? sortDirection : null}
      />

      <DataTableColumn
        key="lastModified"
        label="Last Modified"
        property="modifiedDate"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedDate'}
        sortDirection={sortField === 'modifiedDate' ? sortDirection : null}
      />

      <DataTableRowActions
        className="alerts-list-actions"
        options={[
          {
            id: 0,
            label: 'Edit',
            value: constants.EDIT_MODE,
          },
          {
            id: 1,
            label: 'Delete',
            value: constants.DELETE_MODE,
          },
        ]}
        menuPosition="overflowBoundaryElement"
        onAction={handleRowAction}
        dropdown={<Dropdown length="5" />}
      />
    </DataTable>
  );
};

export default NotificationRulesTable;
