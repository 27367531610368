import React, { Fragment } from 'react';
import images from '../../constants_img';
import { Pill } from '@salesforce/design-system-react';
import moment from 'moment';
import { constants, fieldLabelOverrides } from '../../constants';
import * as utils from '../shared/generalUtils';

export const getChannelTypePill = (type, typeLabel, cssBase, index) => {
  const cssType = cssBase + '-type';
  const key = 'pill-type-' + index;
  if (type === constants.TYPE_GOC) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.GOC} alt="GOC" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_BUG) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.BUG} alt="Bug" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_USER_STORY) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.USER_STORY} alt="UserStory" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_INVESTIGATION) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.INVESTIGATION} alt="Investigation" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (
    type === constants.TYPE_INCIDENT ||
    type === constants.TYPE_SF_MARKETING_CLOUD_INCIDENT ||
    type === constants.TYPE_RMA_CASE
  ) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.GUS_CASE} alt="GUS" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_GUS_CHATTER || type === constants.TYPE_ORG62_CHATTER) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.CHATTER} alt="Chatter" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_EMAIL) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.EMAIL} alt="Email" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_PAGERDUTY) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.PAGERDUTY} alt="PagerDuty" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_SERVICE) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.SERVICE} alt="Service" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_SLACK) {
    return (
      <Pill
        resourceFields
        key={key}
        icon={<img className={cssType} src={images.SLACK} alt="Slack" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  } else if (type === constants.TYPE_NOOP) {
    return (
      <Pill
        key={key}
        icon={<img className={cssType} src={images.NOOP} alt="No op" />}
        variant="option"
        labels={{ label: typeLabel, title: typeLabel }}
      />
    );
  }

  return null;
};

export const getChannelTypeImageAndLabel = (type, typeLabel, cssBase) => {
  const cssType = cssBase + '-type';
  const cssTypeLabel = cssBase + '-typeLabel';
  return type === constants.TYPE_GOC ? (
    <Fragment>
      <img className={cssType} src={images.GOC} alt="GOC" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_BUG ? (
    <Fragment>
      <img className={cssType} src={images.BUG} alt="Bug" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_USER_STORY ? (
    <Fragment>
      <img className={cssType} src={images.USER_STORY} alt="UserStory" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_INVESTIGATION ? (
    <Fragment>
      <img className={cssType} src={images.INVESTIGATION} alt="Investigation" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_INCIDENT ||
    type === constants.TYPE_SF_MARKETING_CLOUD_INCIDENT ||
    type === constants.TYPE_RMA_CASE ? (
    <Fragment>
      <img className={cssType} src={images.GUS_CASE} alt="Case" />
      {type === constants.TYPE_INCIDENT ? <span className={cssTypeLabel}> {typeLabel}</span> : null}
      {type === constants.TYPE_SF_MARKETING_CLOUD_INCIDENT ? <span> {typeLabel}</span> : null}
      {type === constants.TYPE_RMA_CASE ? <span className={cssTypeLabel}> {typeLabel}</span> : null}
    </Fragment>
  ) : type === constants.TYPE_GUS_CHATTER || type === constants.TYPE_ORG62_CHATTER ? (
    <Fragment>
      <img className={cssType} src={images.CHATTER} alt="Chatter" />
      {type === constants.TYPE_GUS_CHATTER ? <span className={cssTypeLabel}> {typeLabel}</span> : null}
      {type === constants.TYPE_ORG62_CHATTER ? <span className={cssTypeLabel}> {typeLabel}</span> : null}
    </Fragment>
  ) : type === constants.TYPE_EMAIL ? (
    <Fragment>
      <img className={cssType} src={images.EMAIL} alt="Email" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_PAGERDUTY ? (
    <Fragment>
      <img className={cssType} src={images.PAGERDUTY} alt="PagerDuty" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_SERVICE ? (
    <Fragment>
      <img className={cssType} src={images.SERVICE} alt="Service" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_SLACK ? (
    <Fragment>
      <img className={cssType} src={images.SLACK} alt="Slack" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type === constants.TYPE_NOOP ? (
    <Fragment>
      <img className={cssType} src={images.NOOP} alt="Noop" />
      <span className={cssTypeLabel}> {typeLabel}</span>
    </Fragment>
  ) : type !== '' ? (
    <Fragment>{type}</Fragment>
  ) : null;
};

export const getChannelTypeImage = (type, cssBase) => {
  const cssType = cssBase + '-image';
  return type === constants.TYPE_GOC ? (
    <Fragment>
      <img className={cssType} src={images.GOC} alt="GOC" />
    </Fragment>
  ) : type === constants.TYPE_BUG ? (
    <Fragment>
      <img className={cssType} src={images.BUG} alt="Bug" />
    </Fragment>
  ) : type === constants.TYPE_USER_STORY ? (
    <Fragment>
      <img className={cssType} src={images.USER_STORY} alt="UserStory" />
    </Fragment>
  ) : type === constants.TYPE_INVESTIGATION ? (
    <Fragment>
      <img className={cssType} src={images.INVESTIGATION} alt="Investigation" />
    </Fragment>
  ) : type === constants.TYPE_INCIDENT ||
    type === constants.TYPE_SF_MARKETING_CLOUD_INCIDENT ||
    type === constants.TYPE_RMA_CASE ? (
    <Fragment>
      <img className={cssType} src={images.GUS_CASE} alt="Case" />
    </Fragment>
  ) : type === constants.TYPE_GUS_CHATTER || type === constants.TYPE_ORG62_CHATTER ? (
    <Fragment>
      <img className={cssType} src={images.CHATTER} alt="Chatter" />
    </Fragment>
  ) : type === constants.TYPE_EMAIL ? (
    <Fragment>
      <img className={cssType} src={images.EMAIL} alt="Email" />
    </Fragment>
  ) : type === constants.TYPE_PAGERDUTY ? (
    <Fragment>
      <img className={cssType} src={images.PAGERDUTY} alt="PagerDuty" />
    </Fragment>
  ) : type === constants.TYPE_SERVICE ? (
    <Fragment>
      <img className={cssType} src={images.SERVICE} alt="Service" />
    </Fragment>
  ) : type === constants.TYPE_SLACK ? (
    <Fragment>
      <img className={cssType} src={images.SLACK} alt="Slack" />
    </Fragment>
  ) : type === constants.TYPE_NOOP ? (
    <Fragment>
      <img className={cssType} src={images.NOOP} alt="Noop" />
    </Fragment>
  ) : null;
};

// Helpers for RuleExpressionBuilder
export const getNameFromLabel = (prefix, label, existingNames) => {
  let autoGeneratedName = label;
  autoGeneratedName = prefix + autoGeneratedName.trim();
  autoGeneratedName = autoGeneratedName
    .replace(/\s/g, '-')
    .replace(/[^\w-]+/g, '')
    .toLowerCase();
  autoGeneratedName = autoGeneratedName.replace(/-{2,}/g, '-');
  // Check collision
  // eslint-disable-next-line no-loop-func
  while (existingNames.find((ern) => ern.localeCompare(autoGeneratedName.trim()) === 0)) {
    autoGeneratedName = autoGeneratedName + '-1';
  }
  return autoGeneratedName;
};

// Resource name to object containing label or type lookup for resourceFields/displayResourceFields
export const getFieldInfo = (arr, resource) => {
  const isCustom = resource.toLowerCase().startsWith(constants.CUSTOM_PREFIX);
  let field = arr.find((r) => (isCustom ? r.id === resource : r.id.toLowerCase() === resource.toLowerCase()));
  return field;
};

// Get the label to display in the combo for the given API field
export const getFieldLabel = (fieldName) => {
  let mapping = fieldLabelOverrides.find((kv) => kv.api.toLowerCase() === fieldName.toLowerCase());
  if (mapping) {
    return mapping.display;
  }
  if (fieldName.toLowerCase().startsWith(constants.CUSTOM_PREFIX)) {
    let normalizedFieldName = fieldName.substring(7);
    return constants.CUSTOM_PREFIX + normalizedFieldName;
  }
  return utils.capitalizeCamelAndSplit(fieldName);
};

// Given the field (resource), what operators can it use?
export const getValidOperators = (resource) => {
  const typeName = resource ? resource.type.name.toLowerCase() : undefined;
  switch (typeName) {
    case 'string':
      return [
        { id: 'EQ', label: 'Equals', sqlOp: '==' },
        { id: 'NOT_EQUAL', label: 'Does Not Equal' },
        { id: 'CONTAINS', label: 'Contains' },
        { id: 'NOT_CONTAINS', label: 'Does Not Contain' },
        { id: 'STARTS_WITH', label: 'Starts With' },
        { id: 'NOT_STARTS_WITH', label: 'Does Not Start With' },
        { id: 'ENDS_WITH', label: 'Ends With' },
        { id: 'NOT_ENDS_WITH', label: 'Does Not End With' },
      ];
    case 'integer':
    case 'long':
    case 'date':
      return [
        { id: 'EQ', label: 'Equals', sqlOp: '==' },
        { id: 'NOT_EQUAL', label: 'Does Not Equal' },
        { id: 'GT', label: 'Greater Than', sqlOp: '>' },
        { id: 'GE', label: 'Greater Than Or Equal', sqlOp: '>=' },
        { id: 'LT', label: 'Less Than', sqlOp: '<' },
        { id: 'LE', label: 'Less Than Or Equal', sqlOp: '<=' },
      ];
    case 'custom':
      return [
        { id: 'EQ', label: 'Equals', sqlOp: '==' },
        { id: 'NOT_EQUAL', label: 'Does Not Equal' },
        { id: 'CONTAINS', label: 'Contains' },
        { id: 'NOT_CONTAINS', label: 'Does Not Contain' },
        { id: 'STARTS_WITH', label: 'Starts With' },
        { id: 'NOT_STARTS_WITH', label: 'Does Not Start With' },
        { id: 'ENDS_WITH', label: 'Ends With' },
        { id: 'NOT_ENDS_WITH', label: 'Does Not End With' },
        { id: 'GT', label: 'Greater Than', sqlOp: '>' },
        { id: 'GE', label: 'Greater Than Or Equal', sqlOp: '>=' },
        { id: 'LT', label: 'Less Than', sqlOp: '<' },
        { id: 'LE', label: 'Less Than Or Equal', sqlOp: '<=' },
      ];
    case 'groupingrule':
      return [{ id: 'EQ', label: 'Equals', sqlOp: '==' }];
    default:
      return [
        { id: 'EQ', label: 'Equals', sqlOp: '==' },
        { id: 'NOT_EQUAL', label: 'Does Not Equal' },
      ];
  }
};

export const getDateInMs = (date) => {
  return moment(date, 'MM/DD/YYYY').toDate().getTime();
};

export const getDateAndTimeInMs = (date, time) => {
  let convertedTime = moment(time, 'h:mm A').format('HH:mm');
  let formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  let dateAndTimeMs = moment(formattedDate + ' ' + convertedTime, 'MM/DD/YYYY HH:mm')
    .toDate()
    .getTime();
  return dateAndTimeMs;
};

export const getTimeStrFromMs = (ms) => {
  return moment(ms).format('HH:mm');
};

export const getUIDate = (date) => {
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const parseFilter = (filter) => {
  const regex = /\b(AND|OR)\b/g;
  const terms = filter.match(regex);
  if (terms) {
    return terms.length === 0 ? '1 condition' : terms.length + 1 + ' conditions';
  }
  return '1 condition';
};

/** Returns the rule filter with all occurrences of 'groupingRuleId == {grouping rule's id}' replaced with 'groupingRule == {grouping rule's label}'
 * This is used in the Tooltip for each entry in the NotificationRulesTable
 */
export const getFilterInfo = (filter, groupingRules) => {
  const groupingRuleRegex = RegExp('groupingRuleId == \\d+');
  let updatedFilter = filter;
  while (groupingRuleRegex.test(updatedFilter)) {
    let filterMatch = updatedFilter.match(groupingRuleRegex);
    console.log('filterMatch:', filterMatch);
    if (filterMatch) {
      // Don't match if 'groupingRuleId == {grouping rule's id}' is operand2 of the expression (aka prefixed by a space and quotes)
      const groupingRuleOpr2Regex = RegExp('((\\s")(groupingRuleId == \\d+))');
      const groupingRuleOpr2Match = updatedFilter.match(groupingRuleOpr2Regex);
      if (groupingRuleOpr2Match) {
        break;
      }
      // Extract the id of the grouping rule
      const idMatch = filterMatch[0].match('\\d+');
      // Find that grouping rule in the groupingRules list
      const groupingRuleMatch = groupingRules.find((rule) => rule.id === idMatch[0]);
      // If grouping rule was found, replace the rule id with the rule name
      if (groupingRuleMatch) {
        updatedFilter = updatedFilter.replace(filterMatch[0], `groupingRule == "${groupingRuleMatch.label}"`);
      } else {
        // Leave rule criteria as is if no grouping rule match is found
        break;
      }
    }
  }
  return updatedFilter;
};
