import React, { useContext } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Header from './components/Header';
import './App.scss';
import ServicesList from './modules/services/ServicesList';
import TokenCard from './modules/token/TokenCard';
import ServiceDetail from './modules/services/ServiceDetail';
import WhoAmI from './components/WhoAmI';
import ProtectedRoute from './components/ProtectedRoute';
import { uamStore, getToken, setToken } from './modules/shared/store';
import { ToastContext } from './modules/shared/toast-context';
import { Toast, ToastContainer } from '@salesforce/design-system-react';
import { constants } from '../src/constants';
import axios from 'axios';

const App = (props) => {
  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  let errorFooter = (
    <span className="slds-show slds-p-top_small">
      For assistance, please post on{' '}
      <a href="https://salesforce-internal.slack.com/archives/C01MKJWJJNN" target="_blank" rel="noopener noreferrer">
        #moncloud-alert-router-goc-support
      </a>
      .
    </span>
  );

  const toastContainer = (
    <ToastContainer>
      <Toast
        labels={{
          heading: toastState.toastMessage,
        }}
        onRequestClose={() => toastDispatch({ type: 'SET_TOAST_STATE', value: { ...toastState, showToast: false } })}
        duration={constants.TOAST_DURATION}
        variant={toastState.toastVariant}
      />
    </ToastContainer>
  );
  const errorToastContainer = (
    <ToastContainer>
      <Toast
        labels={{
          heading: toastState.errorHeading,
          details: (
            <>
              {toastState.toastMessage}
              {errorFooter}
            </>
          ),
        }}
        onRequestClose={() => toastDispatch({ type: 'SET_TOAST_STATE', value: { ...toastState, showToast: false } })}
        variant={toastState.toastVariant}
      />
    </ToastContainer>
  );
  axios.interceptors.response.use(
    (success) => success,
    (error) => {
      // 401 handled in api.js
      if (error.response.status !== 401) {
        let errorText = '';
        if (error.response.data !== undefined && error.response.data.errors !== undefined) {
          errorText = (
            <>
              {error.response.data.errors.map((item, i) => {
                return (
                  <span className="errorItem" key={i}>
                    {item}
                  </span>
                );
              })}
            </>
          );
        }
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: errorText,
            toastVariant: 'error',
            showToast: true,
            errorHeading: 'An unexpected error was encountered.',
          },
        });
        throw error;
      }
    }
  );

  const handleAuthentication = (prop) => {
    const token = getToken();
    // console.log('handleAuth: ' + token);
    if (!token) {
      if (window.location.search.length > 0 && window.location.search.startsWith('?token=')) {
        // Token is in URL! Storing token and redirecting to /services
        // console.log('Token is in URL! Storing token and redirecting to /services');
        setToken(window.location.search.substring(7));
      } else {
        // No Token in URL. Try getting credentials by returning WhoAmI
        // console.log('No Token in URL. Try getting credentials by returning WhoAmI');
        return <WhoAmI />;
      }
    }
    // Return main menu
    return <Redirect to="/services" />;
  };

  return (
    <BrowserRouter>
      {toastState.showToast && toastState.toastVariant !== 'error' ? toastContainer : null}
      {toastState.showToast && toastState.toastVariant === 'error' ? errorToastContainer : null}
      <div className="App" data-testid="app">
        <Header userName={globalState.userName} userEmail={globalState.userEmail} />
        <div className="app-content-container">
          <Switch>
            <ProtectedRoute exact path="/services/:serviceId" component={ServiceDetail} />
            <ProtectedRoute exact path="/tokens" component={TokenCard} />
            <ProtectedRoute exact path="/" component={ServicesList} />
            <ProtectedRoute exact path="/services" component={ServicesList} />
            <Route
              path="/login"
              render={(props) => {
                return handleAuthentication(props);
              }}
            />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {};

export default App;
