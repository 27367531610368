import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Expression, /*ExpressionCondition,*/ Tooltip, Spinner } from '@salesforce/design-system-react';
import ExpressionCondition from '../shared/ExpressionCondition';
import { parseCustomLogic } from '../shared/parser';
import { constants, errors } from '../../constants';
import InputFieldModal from './InputFieldModal';
import { getValidOperators, getFieldInfo, getFieldLabel, getUIDate } from '../shared/utilities';
import './RuleExpressionBuilder.scss';

const RuleExpressionBuilder = forwardRef(
  (
    {
      showLoading,
      triggerType,
      setTriggerType,
      resourceFields,
      setResourceFields,
      displayResourceFields,
      setDisplayResourceFields,
      conditions,
      setConditions,
      customLogic,
      setCustomLogic,
      fetchGroupingRulesList,
      hasGroupingRuleCondition,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      validateInput() {
        return validateInput();
      },

      // Get the complete SQL-like filter expression for this rule.
      getFilter() {
        let ret = '';
        if (triggerType === 'custom') {
          // Let the parser call us back with each found term and replace it with the condition.
          ret = parseCustomLogic(customLogic, (termNumber) => getTermStatement(parseInt(termNumber) - 1));
        } else {
          // String together all terms with the given boolean operator
          for (let i = 0; i < conditions.length; i++) {
            if (ret.length > 0) {
              ret += triggerType === 'all' ? ' AND ' : ' OR ';
            }

            ret += getTermStatement(i);
          }
        }
        return ret;
      },
    }));

    const [expressionErrorText, setExpressionErrorText] = useState([]);

    const addCondition = () => {
      const newCondition = {
        isGroup: false,
        resource: '',
        operator: '',
        value: '',
        valuesList: undefined, // so the value type defaults to the Input box (not Combobox)
        valueSelected: { id: 'none', label: '' },
      };
      setConditions([...conditions, newCondition]);
    };

    // Delete the i-th condition, but not if it's the only one left. In that case, just clear the fields.
    const deleteCondition = (i) => {
      if (i === 0 && conditions.length === 1) {
        let cond = conditions[0];
        cond.isGroup = false;
        cond.resource = '';
        cond.operator = '';
        cond.value = '';
        cond.valuesList = undefined; // so the value type defaults to the Input box (not Combobox)
        cond.valueSelected = { id: 'none', label: '' };
        setConditions([cond]);
      } else {
        setConditions([...conditions.filter((val, index) => index !== i)]);

        // Replace the term that was deleted in the custom logic with 'XXX' and
        // adjust the numbering of all the ones that came after it.
        if (triggerType === 'custom') {
          let index = i + 1; // condition passed in is zero-based, but terms are 1-based
          let newLogic = parseCustomLogic(customLogic, (termNumberString) => {
            if (termNumberString.startsWith('[')) {
              return termNumberString;
            }
            const termNumber = parseInt(termNumberString);
            if (termNumber === index) {
              return '[DELETED]';
            }
            if (termNumber > index) {
              return (termNumber - 1).toString();
            }
            return termNumber.toString(); // Remove any leading zeros, etc.
          });
          setCustomLogic(newLogic);
        }
      }
    };

    // Get the trigger type currently in use.
    const getTriggerType = (i, trigger) => {
      if (trigger === 'custom') return String(i + 1);
      if (i > 0) {
        if (trigger === 'all') return 'AND';
        if (trigger === 'any') return 'OR';
      }
      return '';
    };

    // Check the custom logic formula to make sure it is valid, uses all terms and only existing terms
    const validateCustomLogic = (logicString, errorText) => {
      let usedNumbers = [];

      // .. check that only numbers, the letters A, N, D, O, R, space, opening, and closing parenthesis are present
      parseCustomLogic(
        logicString,
        (termNumber) => {
          if (termNumber.startsWith('[')) {
            if (termNumber === '[DELETED]') {
              if (errorText.indexOf(errors.DELETED_TERMS_STILL_PRESENT) < 0) {
                errorText.push(errors.DELETED_TERMS_STILL_PRESENT);
              }
            } else {
              errorText.push(errors.UNEXPECTED_CHAR);
            }
            return termNumber;
          }

          usedNumbers.push(parseInt(termNumber));
          return termNumber;
        },
        (err, pos) => {
          errorText.push(`Custom logic expression is invalid. At position ${pos}: ${err}`);
        }
      );

      // Only check whether all conditions are used if the formula is valid
      if (errorText.length === 0) {
        // Check that each placeholder is a valid condition ...
        usedNumbers.forEach((usedTerm) => {
          if (usedTerm < 1 || usedTerm > conditions.length) {
            errorText.push(`Custom logic expression references a condition ${usedTerm}, which does not exist.`);
          }
        });

        // ... and check that each condition is used at least once
        conditions.forEach((cond, index) => {
          if (!usedNumbers.find((usedTerm) => usedTerm === index + 1)) {
            errorText.push(`Custom logic expression needs to reference condition ${index + 1}.`);
          }
        });
      }
    };

    const sortAndSeparateDisplayResourceFields = (fields) => {
      let oobResources = [];
      let customResources = [];
      fields.forEach((field) => {
        if (field.id.toLowerCase().startsWith(constants.CUSTOM_PREFIX)) {
          customResources.push(field);
        } else {
          oobResources.push(field);
        }
      });
      oobResources.sort((x, y) => x.label.localeCompare(y.label));
      customResources.sort((x, y) => x.label.localeCompare(y.label));
      return customResources.length !== 0
        ? [...oobResources, { type: 'separator' }, ...customResources]
        : [...oobResources];
    };

    // Logic for custom field modal
    const [isCustomFieldModalOpen, setIsCustomFieldModalOpen] = useState(false);
    const [customFieldHeading, setCustomFieldHeading] = useState('');
    const [customFieldValue, setCustomFieldValue] = useState('');
    const [customFieldError, setCustomFieldError] = useState('');
    const [customFieldIdx, setCustomFieldIdx] = useState();
    const validateCustomField = async (input) => {
      let hasError = false;
      let testInput = input.trim();
      const customFieldRegex = RegExp('^[^a-zA-Z]|[^a-zA-Z0-9_.]');
      const consecutivePeriodsOrEndsWithPeriodRegex = RegExp('\\.{2,}|[.]$');
      if (testInput.length === 0) {
        hasError = true;
        setCustomFieldError(errors.REQUIRED);
      } else if (testInput.length > constants.TEXT_CHAR_LIMIT) {
        hasError = true;
        setCustomFieldError(errors.CHAR_LIMIT);
      } else if (customFieldRegex.test(testInput)) {
        hasError = true;
        setCustomFieldError(errors.INVALID_CUSTOM_FIELD);
      } else if (consecutivePeriodsOrEndsWithPeriodRegex.test(testInput)) {
        hasError = true;
        setCustomFieldError(errors.INVALID_CUSTOM_FIELD_PERIODS);
      }
      return hasError;
    };
    const onCustomFieldClose = () => {
      setCustomFieldError('');
      setIsCustomFieldModalOpen(false);
    };
    const onCustomFieldSave = (input) => {
      // Create new custom field object and update condition with saved custom field name
      let customField = constants.CUSTOM_PREFIX + input.trim();
      const fields = [...resourceFields];
      const displayFields = [...displayResourceFields];
      const fieldLabel = getFieldLabel(customField);
      let field = getFieldInfo(resourceFields, customField);
      if (field === undefined) {
        field = {
          id: customField,
          label: fieldLabel,
          type: { name: 'custom' },
        };
        fields.push(field);
        displayFields.push({ id: field.id, label: field.label });
        setResourceFields(fields);
        setDisplayResourceFields(displayFields);
      }
      updateRuleRowData(customFieldIdx, { selection: [{ id: field.id, label: field.label }] }, 'resource');
    };
    // End logic for custom field modal

    // Update i-th condition row.
    const updateRuleRowData = async (i, val, type) => {
      if (
        val.selection !== undefined &&
        val.selection[0] !== undefined &&
        val.selection[0].id.toString() === 'custom'
      ) {
        setCustomFieldHeading('Specify Custom Alert Field');
        setCustomFieldValue('');
        setCustomFieldIdx(i);
        setIsCustomFieldModalOpen(true);
      } else {
        const newCondition = {
          isGroup: conditions[i].isGroup,
          resource: conditions[i].resource,
          operator: conditions[i].operator,
          value: conditions[i].value,
          valuesList: conditions[i].valuesList,
          valueSelected: conditions[i].valueSelected,
        };

        if (type === 'value') {
          // If the value was selected via Combobox, set the valueSelected
          if (val.selection && val.selection[0]) {
            newCondition.valueSelected = val.selection[0];
          } else {
            // Otherwise, store the value
            const resourceType = getFieldInfo(resourceFields, conditions[i].resource);
            if (resourceType.name === 'AlertAction') {
              newCondition.value = resourceType.values.find((v) => v.contains(val.value));
            } else {
              newCondition.value = val.value;
            }
          }
        } else if (type === 'operator') {
          if (val.selection[0] !== undefined) {
            newCondition[type] = val.selection[0];
          }
        } else if (type === 'resource') {
          if (val.selection[0] !== undefined) {
            newCondition[type] = val.selection[0].id.toString();
            const resourceType = getFieldInfo(resourceFields, val.selection[0].id);
            // For resources with a preset list of values to choose from, populate the options for the Combobox
            let valuesList = resourceType.valuesList;
            if (resourceType.id === 'groupingRuleId') {
              // Lazy load the grouping rules
              valuesList = await fetchGroupingRulesList();
            }
            newCondition.valuesList = valuesList;
            // For resources with a preset list of values to choose from, clear the selected value
            if (resourceType.type.name === 'enum' || resourceType.type.name === 'groupingRule') {
              newCondition.valueSelected = { id: 'none', label: '' };
            }
            if (!newCondition.operator) {
              newCondition.operator = { id: 'EQ', label: 'Equals', sqlOp: '==' };
            }
          }
        }

        let newConditions = [];
        for (let index = 0; index < conditions.length; index++) {
          if (index === i) {
            newConditions[index] = newCondition;
          } else {
            newConditions[index] = conditions[index];
          }
        }
        setConditions(newConditions);
      }
    };

    // When the trigger type is changed from ALL or ANY to CUSTOM, regenerate the custom logic string.
    const handleChangeTrigger = (newTriggerType) => {
      if (newTriggerType === 'custom' && triggerType !== 'custom') {
        let customLogic = '';

        // String together all terms with the given boolean operator
        for (let i = 0; i < conditions.length; i++) {
          if (customLogic.length > 0) {
            customLogic += triggerType === 'all' ? ' AND ' : ' OR ';
          }

          customLogic += (i + 1).toString();
        }

        setCustomLogic(customLogic);
      }

      setTriggerType(newTriggerType);
    };

    // Validate that all the conditions are set correctly and a notification has been selected
    const validateInput = () => {
      let errorText = [];
      let conditionsChanged = false;
      let newConditions = [...conditions];

      // Check that each condition has a correct value
      conditions.forEach((cond, index) => {
        // Find the Resource field definition
        const resourceField = getFieldInfo(resourceFields, cond.resource);
        if (resourceField === undefined) {
          // The resource field hasn't been selected from the dropdown
          errorText.push(`Condition ${index + 1} field is required.`);
        } else if (!cond.valuesList && (cond.value.includes('"') || cond.value.includes('\\'))) {
          // If the value was populated via an Input box, validate the string input
          errorText.push(`Condition ${index + 1} value cannot contain backslashes or double quotes.`);
        } else if (!cond.valuesList && cond.value.length > constants.TEXT_CHAR_LIMIT) {
          errorText.push(`Condition ${index + 1} value exceeds the maximum of 256 characters.`);
        } else if (!cond.valuesList && cond.value.length === 0) {
          errorText.push(`Condition ${index + 1} value is required.`);
        } else {
          // Verify that the value can be interpreted according to the expected type of the field
          switch (resourceField.type.name.toLowerCase()) {
            case 'long':
              {
                let num = Number(cond.value);
                if (isNaN(num) && isNaN(Date.parse(cond.value))) {
                  errorText.push(`Condition ${index + 1} expects a number.`);
                }
              }
              break;

            case 'integer':
              {
                let num = Number(cond.value);
                if (isNaN(num) || Math.round(num) !== num) {
                  errorText.push(`Condition ${index + 1} expects a whole number.`);
                }
              }
              break;

            case 'enum':
              {
                let resourceTypeVal;
                if (cond.valueSelected && cond.valueSelected.id !== 'none') {
                  resourceTypeVal = resourceField.type.values.find(
                    (rfv) => rfv.toLowerCase() === cond.valueSelected.id.toLowerCase().trim()
                  );
                }
                if (!resourceTypeVal) {
                  errorText.push(
                    `Condition ${index + 1} expects one of these values: ${resourceField.type.values.join(', ')}.`
                  );
                } else if (resourceTypeVal !== cond.value) {
                  // Fix casing differences
                  newConditions[index].value = resourceTypeVal;
                  conditionsChanged = true;
                }
              }
              break;

            case 'groupingrule':
              if (cond.valueSelected && cond.valueSelected.id === 'none') {
                errorText.push(`Condition ${index + 1} expects a value to be selected.`);
              }
              break;

            case 'date':
              {
                const date = new Date(cond.value);
                const dateNum = new Date(Number(cond.value));

                if (isNaN(date.getTime()) && isNaN(dateNum.getTime())) {
                  // It's neither date nor number...
                  errorText.push(
                    `Condition ${index + 1} does not have a valid date (e.g. 'Jan 1, 2021 2:45 AM' ` +
                      `or '3/17/2021 4:00 PM') or epoch time as its value.`
                  );
                } else {
                  // Update UI field to standard date/time format
                  newConditions[index].value = getUIDate(isNaN(date) ? dateNum : date);
                  conditionsChanged = true;
                }
              }
              break;

            case 'map':
            case 'custom':
            case 'string':
              {
                if (typeof cond.value !== 'string' || cond.value.length === 0) {
                  errorText.push(`Condition ${index + 1} value is required.`);
                }
              }
              break;

            default:
              break;
          }

          // Verify that a operator has been selected
          if (cond.operator === undefined || cond.operator.id === undefined) {
            errorText.push(`Condition ${index + 1} operator is required.`);
          }
        }
      });

      if (conditionsChanged) {
        setConditions(newConditions);
      }

      // If using custom logic...
      if (triggerType === 'custom') {
        validateCustomLogic(customLogic, errorText);
      }

      setExpressionErrorText(errorText);
      return errorText.length !== 0;
    };

    // Get the SQL-like text for the given condition to use in the payload for the API
    const getTermStatement = (conditionIndex) => {
      // Find the Resource field definition
      const resourceField = getFieldInfo(resourceFields, conditions[conditionIndex].resource);

      // Get the operators that are valid for this resource field
      const ops = getValidOperators(resourceField);

      // Find the one the user chose
      const oper = ops.find((op) => conditions[conditionIndex].operator.id === op.id);

      // Add the resource name
      let term = conditions[conditionIndex].resource;

      // Add operation, checking whether a override exists for this operator. Use it if it does, otherwise use its ID
      term += ' ' + (oper.sqlOp === undefined ? oper.id : oper.sqlOp) + ' ';

      // Finally add the value, quoting everything for now, except date, which gets converted to milliseconds.
      const lowerType = resourceField.type.name.toLowerCase();
      if (lowerType === 'date') {
        const dtParsed = Date.parse(conditions[conditionIndex].value);
        const date = isNaN(dtParsed) ? new Date(parseInt(conditions[conditionIndex].value)) : dtParsed;
        term += date.toFixed();
      } else if (resourceField.id === 'severity') {
        term += conditions[conditionIndex].value.trim();
      } else if (resourceField.id === 'groupingRuleId') {
        term += conditions[conditionIndex].valueSelected.id;
      } else {
        term += '"' + conditions[conditionIndex].value + '"';
      }

      return term;
    };

    // Check whether the custom logic formula is valid so we can hint at it
    // in the UI and prevent deletion of conditions.
    let logicErrors = false;
    if (triggerType === 'custom') {
      let errors = [];
      validateCustomLogic(customLogic, errors);
      logicErrors = errors.length > 0;
    }

    return (
      <>
        <Expression
          id="rule-criteria"
          className="rule-expression-builder"
          labels={{
            title: '',
            takeAction: 'Alert Matches When',
            customLogic: (
              <div>
                Custom Logic{' '}
                <Tooltip
                  id="base"
                  align="top left"
                  content={
                    <div>
                      Use custom logic to express a more complex conditional expression.
                      <br /> For example: <strong>(1 AND 2) OR (3 AND 4)</strong>
                    </div>
                  }
                  variant="info"
                  position="absolute"
                  dialogClassName="dialog-className rule-expression-builder-custom-logic-tooltip"
                />
                {logicErrors ? (
                  <span className="custom-logic-invalid">Invalid expression. Click Save to see details.</span>
                ) : null}
              </div>
            ),
          }}
          events={{
            onChangeTrigger: (event, data) => {
              handleChangeTrigger(data.triggerType);
            },
            onChangeCustomLogicValue: (event, data) => {
              setCustomLogic(data.value);
            },
            onAddCondition: addCondition,
          }}
          triggerType={triggerType}
          customLogicValue={customLogic}
        >
          {showLoading ? (
            <div className="rule-expression-builder-spinner">
              <Spinner
                size="x-small"
                variant="brand"
                isInline
                hasContainer={false}
                assistiveText={{ label: 'Loading rule conditions...' }}
              />
              <span className="rule-expression-builder-spinner-message">Loading rule conditions...</span>
            </div>
          ) : (
            conditions.map((condition, i) => {
              return (
                <ExpressionCondition
                  className={'rule-condition-row'}
                  focusOnMount
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`rule-condition-${i}`}
                  id={`rule-condition-${i}`}
                  labels={{
                    label: getTriggerType(i, triggerType),
                    resource: 'Field',
                  }}
                  resourcesList={[
                    ...sortAndSeparateDisplayResourceFields(displayResourceFields),
                    { type: 'separator' },
                    {
                      id: 'custom',
                      label: 'Use Custom Alert Field',
                    },
                  ]}
                  resourceSelected={getFieldInfo(displayResourceFields, condition.resource)}
                  operatorsList={getValidOperators(getFieldInfo(resourceFields, condition.resource))}
                  operatorSelected={getValidOperators(getFieldInfo(resourceFields, condition.resource)).find(
                    (o) => o.id === (condition.operator === undefined ? -1 : condition.operator.id)
                  )}
                  value={condition.value}
                  valuesList={condition.valuesList}
                  valueSelected={condition.valueSelected}
                  events={{
                    onChangeOperator: (event, obj) => {
                      updateRuleRowData(i, obj, 'operator');
                    },
                    onChangeResource: (event, obj) => {
                      updateRuleRowData(i, obj, 'resource');
                    },
                    onChangeValue: (event, data) => {
                      updateRuleRowData(i, data, 'value');
                    },
                    onDelete: () => {
                      deleteCondition(i);
                    },
                  }}
                />
              );
            })
          )}
        </Expression>
        <ul className="rule-expression-builder-error">
          {expressionErrorText.map((s) => (
            <li>{s}</li>
          ))}
        </ul>
        {hasGroupingRuleCondition ? (
          <span className="rule-expression-builder-note">
            Note: Alert Grouping is only available for the Case notifier.
          </span>
        ) : null}
        {isCustomFieldModalOpen ? (
          <InputFieldModal
            heading={customFieldHeading}
            label={'Custom Alert Field'}
            placeholder={'Enter the name of the custom field...'}
            onClose={onCustomFieldClose}
            onSave={onCustomFieldSave}
            checkError={validateCustomField}
            errorText={customFieldError}
            value={customFieldValue}
          />
        ) : null}
      </>
    );
  }
);

RuleExpressionBuilder.propTypes = {
  showLoading: PropTypes.bool,
  triggerType: PropTypes.string,
  setTriggerType: PropTypes.func,
  resourceFields: PropTypes.array,
  setResourceFields: PropTypes.func,
  displayResourceFields: PropTypes.array,
  setDisplayResourceFields: PropTypes.func,
  conditions: PropTypes.array,
  setConditions: PropTypes.func,
  customLogic: PropTypes.string,
  setCustomLogic: PropTypes.func,
};

export default RuleExpressionBuilder;
