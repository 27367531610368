import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { deleteToken, getToken, uamStore } from '../modules/shared/store';
import apiCalls from '../modules/shared/api';

const ProtectedRoute = ({ path, exact, component: Component }) => {
  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;
  const dispatch = globalContext.dispatch;
  const token = getToken();

  useEffect(() => {
    // console.log('ProtectedRoute.useEffect() started. username: ' + globalState.userName + '  Token: ' + token);
    if (token && !globalState.userName) {
      // console.log('App.useEffect(): token set, but name is not, calling whoami API');
      apiCalls
        .whoAmI()
        .then((response) => {
          // console.log('ProtectedRoute.useEffect().whoamiAPI.then: ', response.data);
          dispatch({ type: 'SET_USER_DATA', value: response.data });
        })
        .catch((response) => {
          // console.log('ProtectedRoute.useEffect().whoamiAPI.catch. remove token, refresh: ', response);
          // WhoAmI API failed, deleting token...
          deleteToken();

          // ... and refreshing the whole site.
          window.location.assign('/');
        });
    }
    // console.log('ProtectedRoute.useEffect() done');
  }, [token, dispatch, globalState.userName]);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (!token) {
          // No token, redirecting to login
          // console.log('ProtectedRoute(' + path + ').render: No token set, redirecting to /login');
          return <Redirect to="/login" />;
        }
        if (globalState.userName) {
          // Token is present, proceeding to requested component
          // console.log('ProtectedRoute(' + path + ').render: Token is set, rendering component');
          return <Component {...props} />;
        }
        return <div />;
      }}
    />
  );
};

export default ProtectedRoute;
