/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  DataTableRowActions,
  Dropdown,
} from '@salesforce/design-system-react';
import { constants, endpoints } from '../../../constants';
import './AlertsList.scss';
import AlertDetailsModal from './AlertDetails/AlertDetailsModal';
import queryString from 'query-string';
import apiCalls from '../../shared/api';
import moment from 'moment';
import AlertEditModal from './AlertEdit/AlertEditModal';

const AlertsList = (props) => {
  const alerts = props.alerts;
  const sortField = props.sortField;
  const sortDirection = props.sortDirection;

  const [modalData, setModalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.DETAILS_MODE);

  const handleRowAction = (item, action) => {
    switch (action.value) {
      case constants.EDIT_MODE:
        setMode(constants.EDIT_MODE);
        setModalData(item);
        setIsModalOpen(true);
        break;
      default:
    }
  };

  // Logic that opens the Alert Details Modal after data is fetched
  useEffect(() => {
    let serviceId = queryString.parse(props.history.location.search).serviceId;
    let sourceAlertId = queryString.parse(props.history.location.search).sourceAlertId;

    if (serviceId !== undefined && sourceAlertId !== undefined && Object.keys(modalData).length !== 0) {
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  // Deep linking into Alert Details
  useEffect(() => {
    const fetchData = async (serviceId, sourceAlertId) => {
      const response = await apiCalls.getAlertsByServiceIdQueryBySourceAlertId(serviceId, sourceAlertId);
      const data = response.data;
      if (response.status === 200 && data[0] !== undefined) {
        const alert = {};
        alert.component = data[0].component;
        alert.custom = data[0].custom;
        alert.description = data[0].description;
        alert.knowledgeArticleId = data[0].knowledgeArticleId;
        alert.modifiedDateMs = data[0].modifiedDateMs;
        alert.modifiedDate = moment(data[0].modifiedDateMs).format(constants.DATE_TIME_FORMAT);
        alert.modifiedDateUTC = moment(data[0].modifiedDateMs).utc().format(constants.DATE_TIME_FORMAT);
        alert.fromNow = new moment(data[0].modifiedDateMs).fromNow();
        alert.name = data[0].name;
        alert.requestId = data[0].requestId;
        alert.service = data[0].service;
        alert.serviceId = data[0].serviceId;
        alert.severity = data[0].severity;
        alert.source = data[0].source;
        alert.sourceAlertId = data[0].sourceAlertId;
        alert.status = data[0].status;
        alert.visitedServices = data[0].visitedServices;
        setModalData(alert);
      }
    };

    let serviceId = queryString.parse(props.history.location.search).serviceId;
    let sourceAlertId = queryString.parse(props.history.location.search).sourceAlertId;
    if (serviceId !== undefined && sourceAlertId !== undefined) {
      // query for modal data
      fetchData(serviceId, sourceAlertId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StatusDataTableCell = ({ children, ...props }) => {
    const STATUS_ENUMS = {
      ACTIVE: 'Active',
      ACKNOWLEDGE: 'Acknowledged',
      CLEAR: 'Cleared',
    };
    return (
      <DataTableCell {...props} title={props.item.status}>
        <div
          className={
            props.item.status === 'ACTIVE'
              ? 'status-active'
              : props.item.status === 'ACKNOWLEDGE'
              ? 'status-acknowledge'
              : 'status-clear'
          }
        >
          {STATUS_ENUMS[props.item.status]}
        </div>
      </DataTableCell>
    );
  };
  StatusDataTableCell.displayName = DataTableCell.displayName;

  const KBDataTableCell = ({ children, ...props }) => {
    const url = `${endpoints.GUS_ENDPOINT}${props.item.knowledgeArticleId}`;
    return (
      <DataTableCell {...props} title={props.item.knowledgeArticleId}>
        {props.item.knowledgeArticleId !== null &&
        props.item.knowledgeArticleId !== undefined &&
        props.item.knowledgeArticleId.trim() !== '' ? (
          <Button
            iconCategory="utility"
            iconName="knowledge_base"
            iconSize="medium"
            variant="icon"
            onClick={() => {
              window.open(url);
            }}
          />
        ) : null}
      </DataTableCell>
    );
  };
  KBDataTableCell.displayName = DataTableCell.displayName;

  const LastModifiedDataTableCell = ({ children, ...props }) => {
    const locale = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    return (
      <DataTableCell
        {...props}
        title={props.item.modifiedDate + ' (' + locale + ')\n' + props.item.modifiedDateUTC + ' (UTC)'}
      >
        {props.item.fromNow}
      </DataTableCell>
    );
  };
  LastModifiedDataTableCell.displayName = DataTableCell.displayName;

  const AlertDetailCell = ({ children, ...props }) => {
    return (
      <DataTableCell width="16%">
        <div className="slds-truncate" title={props.item.sourceAlertId}>
          <a
            // eslint-disable-next-line no-script-url
            href={`${props.history.location.pathname}?${new URLSearchParams({
              serviceId: props.item.serviceId,
              sourceAlertId: props.item.sourceAlertId,
            }).toString()}`}
            onClick={(e) => {
              e.preventDefault();
              props.history.replace({
                search:
                  '?' +
                  new URLSearchParams({
                    serviceId: props.item.serviceId,
                    sourceAlertId: props.item.sourceAlertId,
                  }).toString(),
              });
              setMode(constants.DETAILS_MODE);
              setModalData(props.item);
              setIsModalOpen(true);
            }}
          >
            {props.item.sourceAlertId}
          </a>
        </div>
      </DataTableCell>
    );
  };
  AlertDetailCell.displayName = DataTableCell.displayName;

  return (
    <>
      {isModalOpen && mode === constants.DETAILS_MODE ? (
        <AlertDetailsModal history={props.history} data={modalData} setIsModalOpen={setIsModalOpen} />
      ) : isModalOpen && mode === constants.EDIT_MODE ? (
        <AlertEditModal
          data={modalData}
          setIsModalOpen={setIsModalOpen}
          refresh={props.refresh}
          setRefresh={props.setRefresh}
        />
      ) : null}
      <DataTable
        id="alertsTable"
        className="alerts-list"
        assistiveText={{
          actionsHeader: 'actions',
          columnSort: 'sort this column',
          columnSortedAscending: 'asc',
          columnSortedDescending: 'desc',
          selectAllRows: 'Select all rows',
          selectRow: 'Select this row',
        }}
        fixedHeader
        fixedLayout
        joined
        items={alerts}
      >
        <DataTableColumn
          label="Source Alert ID"
          onSort={props.handleSort}
          isSorted={sortField === constants.SOURCE_ALERT_ID}
          sortDirection={sortField === constants.SOURCE_ALERT_ID ? sortDirection : undefined}
          sortable
          property={constants.SOURCE_ALERT_ID}
          width="30%"
        >
          <AlertDetailCell history={props.history} />
        </DataTableColumn>
        <DataTableColumn
          label="Status"
          onSort={props.handleSort}
          isSorted={sortField === constants.STATUS}
          sortDirection={sortField === constants.STATUS ? sortDirection : undefined}
          sortable
          property={constants.STATUS}
          width="7%"
        >
          <StatusDataTableCell />
        </DataTableColumn>
        <DataTableColumn
          label="Severity"
          onSort={props.handleSort}
          isSorted={sortField === constants.SEVERITY}
          sortDirection={sortField === constants.SEVERITY ? sortDirection : undefined}
          sortable
          property={constants.SEVERITY}
          width="5%"
        />
        <DataTableColumn
          label="Name"
          onSort={props.handleSort}
          isSorted={sortField === constants.NAME}
          sortDirection={sortField === constants.NAME ? sortDirection : undefined}
          sortable
          property={constants.NAME}
          width="18%"
        />
        <DataTableColumn
          label="Description"
          onSort={props.handleSort}
          isSorted={sortField === constants.DESCRIPTION}
          sortDirection={sortField === constants.DESCRIPTION ? sortDirection : undefined}
          sortable
          property={constants.DESCRIPTION}
          width="10%"
        />
        <DataTableColumn
          label="Component"
          onSort={props.handleSort}
          isSorted={sortField === constants.COMPONENT}
          sortDirection={sortField === constants.COMPONENT ? sortDirection : undefined}
          sortable
          property={constants.COMPONENT}
          width="10%"
        />
        <DataTableColumn
          label="Source"
          onSort={props.handleSort}
          isSorted={sortField === constants.SOURCE}
          sortDirection={sortField === constants.SOURCE ? sortDirection : undefined}
          sortable
          property={constants.SOURCE}
          width="7%"
        />
        <DataTableColumn label="KB Article" property={constants.KNOWLEDGE_ARTICLE_ID} width="5%">
          <KBDataTableCell />
        </DataTableColumn>
        <DataTableColumn
          label="Last Modified"
          onSort={props.handleSort}
          isSorted={sortField === constants.MODIFIED_DATE}
          sortDirection={sortField === constants.MODIFIED_DATE ? sortDirection : null}
          sortable
          property={constants.MODIFIED_DATE}
          width="8%"
        >
          <LastModifiedDataTableCell />
        </DataTableColumn>
        <DataTableRowActions
          className="alerts-list-actions"
          options={[
            {
              id: 0,
              label: 'Edit',
              value: constants.EDIT_MODE,
            },
          ]}
          menuPosition="overflowBoundaryElement"
          onAction={handleRowAction}
          dropdown={<Dropdown length="5" />}
        />
      </DataTable>
    </>
  );
};

export default AlertsList;
