import React, { useState, Fragment, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Input, Tooltip } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../../constants';

const ChannelEditorTypeSlack = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let key = inputChannelId.trim();

      if (key.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      } else if (
        key.length < constants.SLACK_CHANNEL_ID_CHAR_LIMIT_MIN ||
        key.length > constants.SLACK_CHANNEL_ID_CHAR_LIMIT_MAX
      ) {
        hasError = true;
        setError(errors.SLACK_CHANNEL_ID_CHAR_LIMIT);
      } else {
        setError('');
      }

      return hasError;
    },
  }));

  const [inputChannelId, setInputChannelId] = useState(
    props.payload !== undefined ? (props.payload.slackChannelId !== undefined ? props.payload.slackChannelId : '') : ''
  );
  const [error, setError] = useState('');

  useEffect(() => {
    props.setPayload({ ...props.payload, slackChannelId: inputChannelId.trim() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputChannelId]);

  return (
    <Fragment>
      <Input
        id="input-slack-channel-id"
        label="Slack Channel ID"
        placeholder="e.g. C0123456789"
        required
        errorText={error}
        value={inputChannelId}
        onChange={(e) => {
          setInputChannelId(e.target.value);
        }}
        fieldLevelHelpTooltip={
          <Tooltip
            id="input-slack-channel-tooltip"
            align="right"
            content="Slack Channel ID can be obtained from the 'About' tab in the Slack Channel details modal"
          />
        }
      />
    </Fragment>
  );
});

export default ChannelEditorTypeSlack;
