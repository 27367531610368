import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxEx from '../../ServicesModal/ServiceEditor/CheckboxEx';

const ChannelEditorDefault = (props) => {
  const [required, setRequired] = useState(false);

  useEffect(() => {
    var defaultChannels = props.notifiers
      .filter(function (notificationChannel) {
        return notificationChannel.isDefault === true;
      })
      .map(function (notificationChannel) {
        return notificationChannel.name;
      });
    if (defaultChannels.length === 0 || (defaultChannels.length === 1 && defaultChannels.includes(props.name))) {
      props.setIsDefault(true);
      setRequired(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <CheckboxEx
        label={'Default Channel'}
        checked={props.isDefault}
        tooltip={
          'If an inbound alert does not match any notification rules, all notification channels marked as a default channel will be executed automatically to route the alert.'
        }
        disabled={required}
        disabledText={'Each service must have at least one default notification channel.'}
        onChange={(checked) => {
          props.setIsDefault(checked);
        }}
      />
    </Fragment>
  );
};

ChannelEditorDefault.propTypes = {
  name: PropTypes.string,
  isDefault: PropTypes.bool,
  setIsDefault: PropTypes.func,
  notifiers: PropTypes.array,
};

export default ChannelEditorDefault;
