import React, { useState, useEffect, Fragment, useContext } from 'react';
import {
  IconSettings,
  PageHeader,
  PageHeaderControl,
  Button,
  Toast,
  ToastContainer,
} from '@salesforce/design-system-react';
import NoItemsCard from '../../components/NoItemsCard.jsx';
import apiCalls from '../shared/api';
import './NotificationChannels.scss';
import NotificationChannelsModal from './ChannelModal/NotificationChannelsModal';
import NotificationChannelsDeleteModal from './ChannelModal/NotificationChannelsDeleteModal';
import NotificationChannelsTable from './NotificationChannelsTable';
import { constants, errors } from '../../constants';
import { RefreshContext } from '../shared/refresh-context';
import ConfirmModal from '../shared/Modal/ConfirmModal';

const NotificationChannels = (props) => {
  const serviceId = props.serviceId;
  const serviceName = props.serviceName;
  const serviceOwners = props.serviceOwners;
  const [notificationChannels, setNotificationChannels] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [sortProperties, setSortProperties] = useState({ sortField: 'label', sortDirection: 'asc' });

  // used to table refresh
  const refreshContext = useContext(RefreshContext);

  // modal
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  // toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const modalToast = (
    <ToastContainer>
      <Toast
        labels={{
          heading: toastMessage,
        }}
        onRequestClose={() => setShowToast(false)}
        duration={constants.TOAST_DURATION}
        variant={toastVariant}
      />
    </ToastContainer>
  );
  const nonSupportedChannelContent = (
    <Fragment>
      <span>
        Editing of this notification channel type, <strong>{constants.TYPE_MAP[modalData.type]}</strong>, is not
        currently supported in the UI. Until support is added, please use the API to edit the channel.
      </span>
    </Fragment>
  );

  const setOpenCreate = React.useCallback(() => {
    setModalData({
      id: '',
      label: '',
      name: '',
      type: '',
      isDefault: false,
      typeConfig: {},
    });
    setMode(constants.CREATE_MODE);
    setIsOpen(true);
  }, []);

  const setOpenEdit = React.useCallback((data) => {
    setModalData({
      id: data.id,
      label: data.label,
      name: data.name,
      type: data.type,
      isDefault: data.isDefault,
      typeConfig: data.typeConfig,
    });
    setMode(constants.EDIT_MODE);
    setIsOpen(true);
  }, []);

  const setOpenDelete = React.useCallback((data) => {
    setModalData({
      id: data.id,
      label: data.label,
      name: data.name,
      type: data.type,
      isDefault: data.isDefault,
      typeConfig: data.typeConfig,
      createdBy: data.createdBy,
    });
    setMode(constants.DELETE_MODE);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCalls.getNotifiersByServiceId(serviceId);
        const data = response.data;
        if (response.status === 200) {
          const notifications = [];
          Object.entries(data).forEach((datum) => {
            const notification = {};
            notification.id = datum[1].id.toString();
            notification.label = datum[1].label;
            notification.name = datum[1].name;
            notification.type = datum[1].type;
            notification.typeLabel =
              constants.TYPE_MAP[datum[1].type] !== undefined ? constants.TYPE_MAP[datum[1].type] : datum[1].type;
            notification.isDefault = datum[1].isDefault;
            notification.modifiedBy = datum[1].modifiedBy;
            notification.modifiedDate = new Date(datum[1].modifiedDate).toLocaleString('en', {
              timeStyle: 'short',
              dateStyle: 'short',
            });
            notification.modifiedDateMs = datum[1].modifiedDate;
            notification.typeConfig = datum[1].typeConfig;
            notification.service = datum[1].service;
            notification.createdBy = datum[1].createdBy;
            notifications.push(notification);
          });
          sortTable(notifications, sortProperties.sortField, sortProperties.sortDirection);
          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(true);
        }
      } catch {
        setIsDataLoaded(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContext.notifiers]);

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      let actualSortField = sortField;
      // Sort modified by date using the epoch time field instead of the human readable one
      if (sortField === 'modifiedDate') {
        actualSortField = 'modifiedDateMs';
      }

      let result = 1;
      if (typeof x[actualSortField] === 'string') {
        result = x[actualSortField].localeCompare(y[actualSortField], 'en', { sensitivity: 'case' });
      } else if (x[actualSortField] < y[actualSortField]) {
        result = -1;
      } else if (x[actualSortField] === y[actualSortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });
    setNotificationChannels(data);
  };

  const actions = () => (
    <Fragment>
      <PageHeaderControl variant="list" id="button-group-page-header-actions">
        <Button label="New Notification Channel" onClick={setOpenCreate} />
      </PageHeaderControl>
    </Fragment>
  );

  const handleSort = React.useCallback(
    (sortColumn, ...rest) => {
      sortTable(notificationChannels, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [notificationChannels]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      label: 'Channel Name',
      typeLabel: 'Type',
      isDefault: 'Default Channel',
      modifiedBy: 'Last Modified By',
      modifiedDate: 'Last Modified',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        {showToast ? modalToast : null}
        {isOpen &&
        (mode === constants.CREATE_MODE ||
          (mode === constants.EDIT_MODE && constants.SUPPORTED_CHANNEL_TYPES.includes(modalData.type))) ? (
          <NotificationChannelsModal
            isOpen={true}
            setIsOpen={setIsOpen}
            mode={mode}
            serviceName={serviceName}
            serviceId={serviceId}
            data={modalData}
            notifiers={notificationChannels}
            setToastMessage={setToastMessage}
            setToastVariant={setToastVariant}
            setShowToast={setShowToast}
          />
        ) : null}
        {isOpen && mode === constants.EDIT_MODE && !constants.SUPPORTED_CHANNEL_TYPES.includes(modalData.type) ? (
          <ConfirmModal
            heading={errors.UNABLE_TO_EDIT}
            dismissOnClickOutside={false}
            onRequestCloseCallback={() => {
              setIsOpen(false);
            }}
            informContent={nonSupportedChannelContent}
            variant={constants.INFORM}
          />
        ) : null}
        {isOpen && mode === constants.DELETE_MODE ? (
          <NotificationChannelsDeleteModal
            isOpen={true}
            setIsOpen={setIsOpen}
            mode={mode}
            serviceName={serviceName}
            serviceOwners={serviceOwners}
            serviceId={serviceId}
            data={modalData}
            notifiers={notificationChannels}
            setToastMessage={setToastMessage}
            setToastVariant={setToastVariant}
            setShowToast={setShowToast}
          />
        ) : null}
        {isDataLoaded && notificationChannels.length === 0 ? (
          <NoItemsCard
            itemName="Notification Channel"
            createDescription="Create a channel to specify where an alert should be routed to for a notification rule."
            onCreateClicked={setOpenCreate}
          />
        ) : null}
        {isDataLoaded && notificationChannels.length > 0 ? (
          <Fragment>
            <PageHeader
              title="Notification Channels"
              info={getListInfo(notificationChannels.length)}
              onRenderActions={actions}
              variant="object-home"
              joined
            />
            <NotificationChannelsTable
              notificationChannels={notificationChannels}
              sortField={sortProperties.sortField}
              sortDirection={sortProperties.sortDirection}
              onEdit={setOpenEdit}
              onDelete={setOpenDelete}
              handleSort={handleSort}
            />
            <div className="footer-bar"></div>
          </Fragment>
        ) : null}
      </IconSettings>
    </div>
  );
};

export default NotificationChannels;
