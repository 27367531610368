import React, { useCallback } from 'react';
import {
  Tooltip,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
} from '@salesforce/design-system-react';
import { constants } from '../../constants';

const GroupingRulesTable = (props) => {
  const groupingRules = props.groupingRules;
  const sortField = props.sortField;
  const sortDirection = props.sortDirection;

  const options = [
    {
      id: 0,
      label: 'Edit',
      value: constants.EDIT_MODE,
    },
    {
      id: 1,
      label: 'Delete',
      value: constants.DELETE_MODE,
    },
  ];

  const handleRowAction = (item, action) => {
    switch (action.value) {
      case constants.EDIT_MODE:
        props.onEdit(item);
        break;
      case constants.DELETE_MODE:
        props.onDelete(item);
        break;
      default:
    }
  };

  const createFilterContent = useCallback((filter) => {
    return (
      <div className="rules-display">
        <div className="rules-header">Rule Criteria</div>
        <div className="rules-display-row">
          <div className="rules-display-cell rules-low-text">Alert matches when:</div>
        </div>
        <div className="rules-display-row ">
          <div className="rules-display-cell rules-bold-text ">{filter}</div>
        </div>
      </div>
    );
  }, []);

  const LinkDataTableCell = ({ item, field }) => {
    if (field === 'ruleConditions') {
      return (
        <DataTableCell title={item.ruleConditions} key="ruleConditions">
          <span className="rules-cell-space-to-icon">{item.ruleConditions}</span>
          <Tooltip
            id="base"
            align="top left"
            content={createFilterContent(item.filter)}
            variant="learnMore"
            position="overflowBoundaryElement"
            dialogClassName="dialog-className rules-popup"
          />
        </DataTableCell>
      );
    }
    return null;
  };
  LinkDataTableCell.displayName = DataTableCell.displayName;

  return (
    <DataTable
      id="groupingrules"
      className="grouping-rules"
      assistiveText={{
        actionsHeader: 'actions',
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
        selectAllRows: 'Select all rows',
        selectRow: 'Select this row',
      }}
      fixedHeader
      fixedLayout
      joined
      items={groupingRules}
    >
      <DataTableColumn
        isSorted={sortField === 'label'}
        sortDirection={sortField === 'label' ? sortDirection : null}
        sortable
        width="35%"
        key="ruleName"
        label="Rule Name"
        primaryColumn
        property="label"
        onSort={props.handleSort}
      />

      <DataTableColumn
        key="ruleCriteria"
        label="Rule Criteria"
        property="ruleConditions"
        width="20%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'ruleConditions'}
        sortDirection={sortField === 'ruleConditions' ? sortDirection : null}
      >
        <LinkDataTableCell field="ruleConditions" property="ruleConditions" />
      </DataTableColumn>

      <DataTableColumn
        key="lastModifiedBy"
        label="Last Modified By"
        property="modifiedBy"
        width="21%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedBy'}
        sortDirection={sortField === 'modifiedBy' ? sortDirection : null}
      />

      <DataTableColumn
        key="lastModified"
        label="Last Modified"
        property="modifiedDate"
        width="21%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedDate'}
        sortDirection={sortField === 'modifiedDate' ? sortDirection : null}
      />

      <DataTableRowActions
        className="alerts-list-actions"
        options={options}
        menuPosition="overflowBoundaryElement"
        onAction={handleRowAction}
        dropdown={<Dropdown length="5" />}
      />
    </DataTable>
  );
};

export default GroupingRulesTable;
