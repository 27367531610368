import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Settings } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import apiCalls from '../../shared/api';
import { constants, errors } from '../../../constants';
import ConfirmModal from '../../shared/Modal/ConfirmModal';
import { RefreshContext } from '../../shared/refresh-context';
import { uamStore } from '../../shared/store';

Settings.setAppElement('#root');

const NotificationChannelsDeleteModal = (props) => {
  const serviceId = props.serviceId;
  const name = props.data.name;
  const label = props.data.label;
  const serviceOwners = props.serviceOwners;
  const createdBy = props.data.createdBy;

  // Delete Variables
  const [dependentRulesList, setDependentRulesList] = useState([]);
  const [existsDependentRule, setExistsDependentRule] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [hasPermissionToDelete, setHasPermissionToDelete] = useState(false);
  const [validated, setValidated] = useState(false);

  // ConfirmModal Variables
  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;
  const [deleteHeader, setDeleteHeader] = useState('');
  const [variant, setVariant] = useState('');

  // Context used to Refresh notifiers tab
  const refreshContext = useContext(RefreshContext);

  useEffect(() => {
    const checkDependentRules = async () => {
      let dependentRules = [];
      const response = await apiCalls.getRulesByServiceId(serviceId, false);
      let data = response.data;
      if (response.status === 200) {
        // Ask backend empty rules returns string instead of empty array
        if (data === '') {
          data = [];
        }
        data.forEach(function (rule) {
          let notifiers = rule.typeConfig.notifiers;
          if (notifiers.includes(name)) {
            dependentRules.push(rule.label);
          }
        });
        dependentRules.sort((x, y) => {
          let result = x.localeCompare(y, 'en', { sensitivity: 'case' });
          return result;
        });
        if (dependentRules.length !== 0) {
          setDependentRulesList(dependentRules);
          setExistsDependentRule(true);
          return true;
        }
      }
      return false;
    };

    const checkIsRequired = async () => {
      var defaultChannels = props.notifiers
        .filter(function (notificationChannel) {
          return notificationChannel.isDefault === true;
        })
        .map(function (notificationChannel) {
          return notificationChannel.name;
        });
      if (defaultChannels.length === 0 || (defaultChannels.length === 1 && defaultChannels.includes(name))) {
        setIsRequired(true);
        return true;
      }
      return false;
    };

    const checkHasPermissionToDelete = async () => {
      const isAdmin = globalState.userNameLowercase === constants.ADMIN;
      const isServiceOwner = serviceOwners.includes(globalState.userNameLowercase);
      const isCreator = globalState.userNameLowercase === createdBy.toLowerCase();
      if (isAdmin || isServiceOwner || isCreator) {
        setHasPermissionToDelete(true);
        return true;
      }
      return false;
    };

    const validateDelete = async () => {
      let existsDependancy = await checkDependentRules();
      let isRequiredChannel = await checkIsRequired();
      let hasPermsToDelete = await checkHasPermissionToDelete();
      if (!existsDependancy && !isRequiredChannel && hasPermsToDelete) {
        setDeleteHeader('Delete Notification Channel');
        setVariant(constants.CONFIRM);
      } else {
        setDeleteHeader(errors.UNABLE_TO_DELETE);
        setVariant(constants.INFORM);
      }
      setValidated(true);
    };
    validateDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Modal Content
  const existsDependentRuleContent = (
    <Fragment>
      <span>
        This notification channel cannot be deleted because it's currently being used in the following notification
        rules:
      </span>
      <div className="notification-channels-modal-dependent-rules-list">
        {dependentRulesList.map(function (item, i) {
          return <li key={i}>{item}</li>;
        })}
      </div>
      <span>In order to delete, either update the rules to use a different channel or delete the rules.</span>
    </Fragment>
  );
  const isRequiredContent = (
    <Fragment>
      <span>
        This notification channel cannot be deleted because your service requires at least one{' '}
        <strong>default channel</strong> to exist in order to route alerts that don't match any notification rules.
        <br />
        <br />
        To delete, designate another notification channel as a default and try again.
      </span>
    </Fragment>
  );
  const hasNoPermissionsContent = (
    <Fragment>
      <span>
        You do not have permission to delete the channel, <strong>{label}</strong>. Only the channel creator, service
        owners or an administrator are permitted to delete the channel.
      </span>
    </Fragment>
  );
  const deleteChannelContent = (
    <Fragment>
      Are you sure you want to delete the channel, <strong>{label}</strong>? This operation cannot be undone.
    </Fragment>
  );

  const close = () => {
    props.setIsOpen(false);
  };

  const onDelete = async () => {
    await sendDelete();
    close();
  };

  const sendDelete = async () => {
    const response = await apiCalls.deleteNotifierEndpoint(props.data.id);
    refreshContext.refreshNotifiers();
    if (response.status === 200 || response.status === 204) {
      if (
        // Toast Optional
        props.setToastMessage !== undefined &&
        props.setToastVariant !== undefined &&
        props.setShowToast !== undefined
      ) {
        props.setToastMessage('Notification channel was deleted.');
        props.setToastVariant('success');
        props.setShowToast(true);
      }
    }
  };

  const onRequestCloseCallback = (optionSelected) => {
    if (optionSelected === constants.DELETE) {
      onDelete();
    } else {
      close();
    }
  };

  return (
    <Fragment>
      {validated ? (
        <ConfirmModal
          heading={deleteHeader}
          dismissOnClickOutside={false}
          onRequestCloseCallback={onRequestCloseCallback}
          deleteContent={deleteChannelContent}
          informContent={
            !hasPermissionToDelete ? (
              hasNoPermissionsContent
            ) : existsDependentRule ? (
              existsDependentRuleContent
            ) : isRequired ? (
              isRequiredContent
            ) : (
              <span />
            )
          }
          variant={variant}
        />
      ) : null}
    </Fragment>
  );
};

NotificationChannelsDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  mode: PropTypes.string,
  serviceName: PropTypes.string,
  data: PropTypes.object,
  notifiers: PropTypes.array,
  setToastMessage: PropTypes.func, // optional
  setToastVariant: PropTypes.func, // optional
  setShowToast: PropTypes.func, // optional
};

export default NotificationChannelsDeleteModal;
