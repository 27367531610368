import React, { Fragment, forwardRef, useImperativeHandle, useRef } from 'react';
import ChannelEditorProductTag from '../ChannelEditorProductTag';
import ChannelEditorAssignee from '../ChannelEditorAssignee';
import PropTypes from 'prop-types';
import { constants } from '../../../../../constants';

const InvestigationFields = forwardRef((props, ref) => {
  const productTagRef = useRef();
  const assigneeRef = useRef();

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let productTagError = await productTagRef.current.validateInput();
      let assigneeError = await assigneeRef.current.validateInput();
      return productTagError || assigneeError;
    },
  }));

  return (
    <Fragment>
      <ChannelEditorProductTag ref={productTagRef} payload={props.payload} setPayload={props.setPayload} />
      <ChannelEditorAssignee
        ref={assigneeRef}
        payload={props.payload}
        setPayload={props.setPayload}
        required={true}
        label={constants.ASSIGNED_TO_LABEL}
      />
    </Fragment>
  );
});

InvestigationFields.propTypes = {
  payload: PropTypes.object,
  setPayload: PropTypes.func,
};

export default InvestigationFields;
