import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Button, Input, Modal, Settings } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import './InputFieldModal.scss';

Settings.setAppElement('#root');

const InputFieldModal = (props) => {
  const inputRef = useRef();
  const [isInputMounted, setInputMounted] = useState(false);
  const [input, setInput] = useState(props.value);

  useEffect(() => {
    if (inputRef.current !== undefined) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputMounted]);

  const onSave = async () => {
    let isError = await props.checkError(input);
    if (!isError) {
      props.onSave(input);
      props.onClose();
    }
  };

  return (
    <Fragment>
      <Modal
        containerClassName={'input-modal-container'}
        contentClassName={'input-modal-content'}
        isOpen={true}
        heading={props.heading}
        dismissOnClickOutside={false}
        onRequestClose={() => props.onClose()}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => props.onClose()} />,
          <Button key="save" label="Save" variant="brand" onClick={() => onSave()} />,
        ]}
      >
        <Input
          inputRef={(el) => {
            inputRef.current = el;
            setInputMounted(!!el);
          }}
          required
          label={props.label}
          placeholder={props.placeholder}
          onChange={(e) => {
            setInput(e.target.value);
          }}
          errorText={props.errorText}
          value={input}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === '13') {
              onSave();
            }
          }}
        />
      </Modal>
    </Fragment>
  );
};

InputFieldModal.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  checkError: PropTypes.func,
  errorText: PropTypes.string,
  value: PropTypes.string,
};

export default InputFieldModal;
