import React from 'react';
import {
  Checkbox,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
} from '@salesforce/design-system-react';
import { getChannelTypeImageAndLabel } from '../shared/utilities';
import { constants } from '../../constants';

const NotificationChannelsTable = (props) => {
  const notificationChannels = props.notificationChannels;
  const sortField = props.sortField;
  const sortDirection = props.sortDirection;

  const CheckboxDataTableCell = ({ children, ...props }) => {
    return (
      <DataTableCell {...props} title={props.item.isDefault.toString()}>
        <Checkbox checked={props.item.isDefault} disabled></Checkbox>
      </DataTableCell>
    );
  };
  CheckboxDataTableCell.displayName = DataTableCell.displayName;

  const TypeDataTableCell = ({ children, ...props }) => {
    return (
      <DataTableCell {...props} title={props.item.typeLabel}>
        {getChannelTypeImageAndLabel(props.item.type, props.item.typeLabel, 'notification-channels')}
      </DataTableCell>
    );
  };
  TypeDataTableCell.displayName = DataTableCell.displayName;

  const handleRowAction = (item, action) => {
    switch (action.value) {
      case constants.EDIT_MODE:
        props.onEdit(item);
        break;
      case constants.DELETE_MODE:
        props.onDelete(item);
        break;
      default:
    }
  };

  return (
    <DataTable
      id="notificationChannels"
      className="notification-channels-list"
      assistiveText={{
        actionsHeader: 'actions',
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
        selectAllRows: 'Select all rows',
        selectRow: 'Select this row',
      }}
      onSort={props.handleSort}
      fixedHeader
      fixedLayout
      joined
      items={notificationChannels}
    >
      <DataTableColumn
        label="Channel Name"
        isSorted={sortField === 'label'}
        sortDirection={sortField === 'label' ? sortDirection : undefined}
        sortable
        primaryColumn
        property="label"
        width="30%"
      />
      <DataTableColumn
        label="Type"
        property="typeLabel"
        sortable
        isSorted={sortField === 'typeLabel'}
        sortDirection={sortField === 'typeLabel' ? sortDirection : undefined}
        width="20%"
      >
        <TypeDataTableCell />
      </DataTableColumn>
      <DataTableColumn
        label="Default Channel"
        property="isDefault"
        sortable
        isSorted={sortField === 'isDefault'}
        sortDirection={sortField === 'isDefault' ? sortDirection : undefined}
        width="10%"
      >
        <CheckboxDataTableCell />
      </DataTableColumn>
      <DataTableColumn
        label="Last Modified By"
        property="modifiedBy"
        sortable
        isSorted={sortField === 'modifiedBy'}
        sortDirection={sortField === 'modifiedBy' ? sortDirection : undefined}
        width="20%"
      />
      <DataTableColumn
        label="Last Modified"
        property="modifiedDate"
        sortable
        isSorted={sortField === 'modifiedDate'}
        sortDirection={sortField === 'modifiedDate' ? sortDirection : undefined}
        width="20%"
      />
      <DataTableRowActions
        className="alerts-list-actions"
        options={[
          {
            id: 0,
            label: 'Edit',
            value: constants.EDIT_MODE,
          },
          {
            id: 1,
            label: 'Delete',
            value: constants.DELETE_MODE,
          },
        ]}
        menuPosition="overflowBoundaryElement"
        onAction={handleRowAction}
        dropdown={<Dropdown length="5" />}
      />
    </DataTable>
  );
};

export default NotificationChannelsTable;
