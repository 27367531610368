import React, { Fragment, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../constants';
import { getChannelTypeImage } from '../../../shared/utilities';
import EmailInput from './ChannelEditorTypes/ChannelEditorTypeEmail';
import PropTypes from 'prop-types';
import ServiceInput from './ChannelEditorTypes/ChannelEditorTypeService';
import PagerDutyInput from './ChannelEditorTypes/ChannelEditorTypePagerDuty';
import GOCInput from './ChannelEditorTypes/ChannelEditorTypeGOC';
import ChatterGroupInput from './ChannelEditorTypes/ChannelEditorTypeChatterGroup';
import InvestigationFields from './ChannelEditorTypes/InvestigationFields';
import BugFields from './ChannelEditorTypes/BugFields';
import UserStoryFields from './ChannelEditorTypes/UserStoryFields';
import IncidentFields from './ChannelEditorTypes/IncidentFields';
import SlackInput from './ChannelEditorTypes/ChannelEditorTypeSlack';

const ChannelEditorType = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let typeError = false;
      let typeConfigHasError = false;

      if (props.type === constants.TYPE_NOOP) {
        return typeError;
      }

      //Validate type
      if (props.type === undefined || props.type.length === 0) {
        typeError = true;
        setError(errors.REQUIRED);
      } else {
        setError('');
      }

      if (typeConfigRef.current !== undefined) {
        typeConfigHasError = await typeConfigRef.current.validateInput();
      }

      return typeError || typeConfigHasError;
    },
  }));

  const [error, setError] = useState('');

  const typeConfigRef = useRef();

  const types = [];
  Object.entries(constants.TYPE_MAP).forEach((datum, idx) => {
    const type = {};
    type.id = idx.toString();
    type.type = datum[0];
    type.label = datum[1];
    types.push(type);
  });
  const typesWithIcon = types.map((elem) => ({
    ...elem,
    ...{
      icon: getChannelTypeImage(elem.type, 'notification-channels-modal'),
    },
  }));
  const availableTypes = constants.SUPPORTED_CHANNEL_TYPES;
  const availableTypesWithIcon = types
    .filter((datum) => {
      return availableTypes.includes(datum.type);
    })
    .map((elem) => ({
      ...elem,
      ...{
        icon: getChannelTypeImage(elem.type, 'notification-channels-modal'),
      },
    }));

  // Select channel type combobox
  const [typeSearchValue, setTypeSearchValue] = useState('');
  const [selectedType, setSelectedType] = useState(
    typesWithIcon.filter((elem) => {
      return elem.type === props.type;
    })
  );

  return (
    <Fragment>
      <Combobox
        classNameMenu="modal-combobox-dropdown"
        events={{
          onChange: (event, { value }) => {
            setTypeSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setTypeSearchValue('');
            setSelectedType(data.selection);
            props.setType('');
            props.setTypeConfig({});
          },
          onSelect: (event, data) => {
            setTypeSearchValue('');
            setSelectedType(data.selection);
            props.setType(data.selection[0].type);
            props.setTypeConfig({});
            setError('');
          },
        }}
        labels={{
          label: 'Channel Type',
          placeholder: 'Search types...',
        }}
        errorText={error}
        menuItemVisibleLength={7}
        options={comboboxFilterAndLimit({
          inputValue: typeSearchValue,
          limit: 100,
          options: availableTypesWithIcon,
          selection: selectedType,
        })}
        selection={selectedType}
        value={typeSearchValue}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        required
      />
      {props.type === constants.TYPE_EMAIL ? (
        <EmailInput ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_SERVICE ? (
        <ServiceInput
          ref={typeConfigRef}
          payload={props.typeConfig}
          setPayload={props.setTypeConfig}
          serviceName={props.serviceName}
        />
      ) : props.type === constants.TYPE_PAGERDUTY ? (
        <PagerDutyInput ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_GOC ? (
        <GOCInput ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_GUS_CHATTER ? (
        <ChatterGroupInput
          type={constants.TYPE_GUS_CHATTER}
          ref={typeConfigRef}
          payload={props.typeConfig}
          setPayload={props.setTypeConfig}
        />
      ) : props.type === constants.TYPE_INVESTIGATION ? (
        <InvestigationFields ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_BUG ? (
        <BugFields ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_USER_STORY ? (
        <UserStoryFields ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_INCIDENT ? (
        <IncidentFields ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : props.type === constants.TYPE_SLACK ? (
        <SlackInput ref={typeConfigRef} payload={props.typeConfig} setPayload={props.setTypeConfig} />
      ) : null}
    </Fragment>
  );
});

ChannelEditorType.propTypes = {
  serviceName: PropTypes.string,
  type: PropTypes.string,
  setType: PropTypes.func,
  typeConfig: PropTypes.object,
  setTypeConfig: PropTypes.func,
};

export default ChannelEditorType;
