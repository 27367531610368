import React, { useContext, useState, useRef, Fragment } from 'react';
import { Button, Modal, Settings } from '@salesforce/design-system-react';
import './ServiceModal.scss';
import apiCalls from '../../shared/api';
import ServiceNameInput from './ServiceEditor/ServiceEditorName';
import ApiInput from './ServiceEditor/ServiceEditorApiName';
import ServiceCatalogIdInput from './ServiceEditor/ServiceEditorServiceCatalog';
import NotifierNameInput from '../ChannelModal/ChannelEditor/ChannelEditorName';
import TypeInput from '../ChannelModal/ChannelEditor/ChannelEditorType';
import { ToastContext } from '../../shared/toast-context';
import { constants } from '../../../constants';

Settings.setAppElement('#root');

const ServicesModal = (props) => {
  const [step, setStep] = useState('1');
  // Input Variables
  const serviceNameRef = useRef();
  const apiNameRef = useRef();
  const notifierNameRef = useRef();
  const typeRef = useRef();

  // Service Inputs
  const [serviceName, setServiceName] = useState('');
  const [apiName, setApiName] = useState('');
  const [serviceCatalogId, setServiceCatalogId] = useState('');

  // Notifier Inputs
  const [notifierLabel, setNotifierLabel] = useState('');
  const [notifierName, setNotifierName] = useState('');
  const [type, setType] = useState('');
  const [typeConfig, setTypeConfig] = useState({});
  // End Input Variables
  const [populateApiName, setPopulateApiName] = useState(true);

  // Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const close = () => {
    props.setIsModalOpen(false);
  };

  const onBack = () => {
    setStep('1');
  };

  const onNext = async () => {
    let hasError = false;
    hasError = await validateStep1Input();

    if (!hasError) {
      setStep('2');
    }
  };

  // Create service, then create the notifier onSave
  const onSave = async () => {
    let hasError = false;
    hasError = await validateStep2Input();

    if (!hasError) {
      close();
      const servicePayload = {
        name: apiName.trim(),
        label: serviceName.trim(),
        ...(serviceCatalogId !== '' && { serviceCatalogId: serviceCatalogId.trim() }),
        isActive: true,
      };
      const notifierPayload = {
        name: notifierName.trim(),
        label: notifierLabel.trim(),
        type: type,
        isDefault: true,
        service: apiName.trim(),
        typeConfig: typeConfig,
      };
      const serviceResponse = await apiCalls.createServiceEndpoint(servicePayload);
      if (serviceResponse.status === 200 || serviceResponse.status === 201) {
        const notifierResponse = await apiCalls.createNotifierEndpoint(notifierPayload);
        if (notifierResponse.status === 200 || notifierResponse.status === 201) {
          toastDispatch({
            type: 'SET_TOAST_STATE',
            value: { ...toastState, toastMessage: 'Service was created.', toastVariant: 'success', showToast: true },
          });
          if (process.env.REACT_APP_ALERTS_ENABLED === 'TRUE') {
            props.history.push('services/' + serviceResponse.data.id + '?tab=3');
          } else {
            props.history.push('services/' + serviceResponse.data.id + '?tab=0');
          }
        }
      }
    }
  };

  const validateStep1Input = async () => {
    let hasError = false;
    let nameError = await serviceNameRef.current.validateInput();
    let apiNameError = await apiNameRef.current.validateInput();

    if (nameError || apiNameError) {
      hasError = true;
    }
    return hasError;
  };

  const validateStep2Input = async () => {
    let hasError = false;

    let nameError = await notifierNameRef.current.validateInput();
    let typeError = await typeRef.current.validateInput();

    if (nameError || typeError) {
      hasError = true;
    }

    return hasError;
  };

  return (
    <Fragment>
      <Modal
        containerClassName="service-modal-container"
        contentClassName="service-modal-content"
        isOpen={props.isOpen}
        heading="New Service"
        dismissOnClickOutside={false}
        onRequestClose={() => close()}
        footer={
          step === '1' ? (
            <Button key="next" label="Next" onClick={() => onNext()}></Button>
          ) : step === '2' ? (
            [
              <Button className="service-modal-back-button" key="back" label="Back" onClick={() => onBack()}></Button>,
              <Button key="save" label="Save" onClick={() => onSave()} variant="brand"></Button>,
            ]
          ) : null
        }
      >
        {step === '1' ? (
          <>
            <ServiceNameInput
              id={props.data.id}
              name={serviceName}
              setName={setServiceName}
              apiName={apiName}
              setApiName={setApiName}
              populateApiName={populateApiName}
              setPopulateApiName={setPopulateApiName}
              services={props.services}
              ref={serviceNameRef}
              focusOnMount={true}
            />
            <ApiInput
              id={props.data.id}
              apiName={apiName}
              setApiName={setApiName}
              populateApiName={populateApiName}
              setPopulateApiName={setPopulateApiName}
              services={props.services}
              ref={apiNameRef}
            />
            <ServiceCatalogIdInput serviceCatalogId={serviceCatalogId} setServiceCatalogId={setServiceCatalogId} />
          </>
        ) : step === '2' ? (
          <>
            <div className="service-modal-create-channel-heading">
              <span>Set up a default notification channel</span>
            </div>
            <div className="service-modal-create-channel-description">
              <span>
                Each service requires at least one default channel to route alerts to when they don't match any defined
                notification rules.
              </span>
            </div>
            <NotifierNameInput
              mode={constants.CREATE_MODE}
              id=""
              label={notifierLabel}
              setLabel={setNotifierLabel}
              setName={setNotifierName}
              notifiers={[]}
              ref={notifierNameRef}
              focusOnMount={true}
            />
            <TypeInput
              serviceName={serviceName}
              type={type}
              setType={setType}
              typeConfig={typeConfig}
              setTypeConfig={setTypeConfig}
              ref={typeRef}
            />
          </>
        ) : null}
      </Modal>
    </Fragment>
  );
};

export default ServicesModal;
