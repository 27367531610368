import React, { useEffect, useState, Fragment, forwardRef } from 'react';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { useImperativeHandle } from 'react';
import apiCalls from '../../../shared/api';
import useDebounce from '../../../shared/hooks/useDebounce';
import { constants } from '../../../../constants';
import PropTypes from 'prop-types';
import './UsersCombobox.scss';

const UsersCombobox = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      if (props.required) {
        if (selectedUsers.length === 0) {
          setError(props.errorMessage);
          hasError = true;
        }
      }
      return hasError;
    },
  }));
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, constants.SEARCH_DELAY);

  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  useEffect(() => {
    props.onSelect(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  useEffect(() => {
    // Set selected user(s) from optional prop
    const setSelectedUsersFromProps = async () => {
      if (props.preselectedUsers !== undefined) {
        const users = props.preselectedUsers.map((username, idx) => {
          return {
            id: idx,
            label: username,
            username: username,
          };
        });
        setSelectedUsers(users);
      }
    };
    setSelectedUsersFromProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchUsersList = async () => {
      const selectedUsernames = selectedUsers.map((user) => {
        return user.username;
      });

      let query = debouncedSearchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.getUsers(query);
        const data = response.data;
        if (data !== '') {
          const results = data
            .filter((datum) => !selectedUsernames.includes(datum.username))
            .map((datum) => {
              return {
                ...datum,
                id: datum.id,
                label: datum.name,
                subTitle: datum.title,
                icon: <img className="users-combobox-profile-img" src={datum.photoUrl} alt={datum.name} />,
              };
            });
          setUsers(results);
        }
        setComboboxDropdown(true);
        setSpinner(false);
      }
    };
    fetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <Fragment>
      <Combobox
        className="users-combobox"
        classNameMenu="modal-combobox-dropdown"
        labels={{
          label: props.label,
          placeholder: props.placeholder,
        }}
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSelectedUsers(data.selection);
          },
          onSelect: (event, data) => {
            setComboboxDropdown(false);
            setSelectedUsers(
              // Show username as label instead of name
              data.selection.map((user) => {
                let userWithoutIcon = {
                  ...user,
                  label: user.username,
                };
                delete userWithoutIcon.icon;
                return userWithoutIcon;
              })
            );
            setSearchValue('');
            setError('');
          },
          onBlur: (e) => {
            setComboboxDropdown(false);
          },
          onFocus: (e) => {
            if (searchValue !== '') {
              setComboboxDropdown(true);
            }
          },
        }}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: users,
          selection: selectedUsers,
        })}
        selection={selectedUsers}
        value={searchValue}
        menuItemVisibleLength={7}
        multiple
        menuPosition="overflowBoundaryElement"
        variant="base"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        required={props.required}
        errorText={error}
      />
    </Fragment>
  );
});

UsersCombobox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  preselectedUsers: PropTypes.array,
  onSelect: PropTypes.func,
  required: PropTypes.bool,
};

export default UsersCombobox;
