import React, { Fragment, forwardRef, useImperativeHandle, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { constants } from '../../../../../constants';
import { getChannelTypeImage } from '../../../../shared/utilities';
import apiCalls from '../../../../shared/api';
import { ToastContext } from '../../../../shared/toast-context';

const ChannelEditorTypeService = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;

      if (serviceName.length === 0) {
        hasError = true;
        setError('This field is required.');
      }
      return hasError;
    },
  }));
  const [error, setError] = useState('');

  const [serviceName, setServiceName] = useState(
    props.payload !== undefined ? (props.payload.serviceName !== undefined ? props.payload.serviceName : '') : ''
  );
  const [services, setServices] = useState([]);
  const [fetchingServices, setFetchingServices] = useState(false);

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  // Update payload only after input variable is set
  useEffect(() => {
    props.setPayload({ ...props.payload, serviceName: serviceName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);

  useEffect(() => {
    const fetchServices = async () => {
      setFetchingServices(true);
      try {
        const response = await apiCalls.getServices();
        const data = response.data;
        if (response.status === 200) {
          let servicesList = [];
          data.forEach((datum, idx) => {
            if (datum.name !== props.serviceName) {
              servicesList.push({
                id: idx.toString(),
                name: datum.name,
                label: datum.label,
                icon: getChannelTypeImage(constants.TYPE_SERVICE, 'notification-channels-modal'),
              });
            }
          });
          servicesList.sort((x, y) => {
            let result = x.label.localeCompare(y.label, 'en', { sensitivity: 'case' });
            return result;
          });
          setServices(servicesList);
        }
      } catch (ex) {
        console.log('ERROR getting services');
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: ex.message,
            toastVariant: 'error',
            showToast: true,
            errorHeading: 'An unexpected error was encountered while fetching the services.',
          },
        });
      } finally {
        setFetchingServices(false);
      }
    };
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedService(
      props.payload !== undefined
        ? props.payload.serviceName !== undefined
          ? services.filter((elem) => {
              return elem.name === props.payload.serviceName;
            })
          : []
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  // Combobox Variables
  const [searchValue, setSearchValue] = useState('');
  const [selectedService, setSelectedService] = useState([]);
  return (
    <Fragment>
      <Combobox
        classNameMenu="modal-combobox-dropdown"
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedService(data.selection);
            setServiceName('');
          },
          onSelect: (event, data) => {
            setSearchValue('');
            setSelectedService(data.selection);
            setServiceName(data.selection[0].name);
            setError('');
          },
        }}
        labels={
          serviceName.length === 0
            ? { label: 'Alert Router Service', placeholder: 'Search Alert Router Services...' }
            : { label: 'Alert Router Service', placeholder: 'Loading Alert Router Service...' }
        }
        errorText={error}
        menuItemVisibleLength={7}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: services,
          selection: selectedService,
        })}
        selection={selectedService}
        value={searchValue}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        required
        hasInputSpinner={fetchingServices}
      />
    </Fragment>
  );
});

ChannelEditorTypeService.propTypes = {
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  serviceName: PropTypes.string,
};

export default ChannelEditorTypeService;
