import React, { useContext, useState, useRef, Fragment } from 'react';
import { Button, Modal, Settings } from '@salesforce/design-system-react';
import './NotificationChannelsModal.scss';
import ChannelEditorName from './ChannelEditor/ChannelEditorName';
import IsDefaultInput from './ChannelEditor/ChannelEditorDefault';
import ChannelEditorType from './ChannelEditor/ChannelEditorType';
import PropTypes from 'prop-types';
import apiCalls from '../../shared/api';
import { constants } from '../../../constants';
import { RefreshContext } from '../../shared/refresh-context';

Settings.setAppElement('#root');

const NotificationChannelsModal = (props) => {
  const serviceName = props.serviceName;
  const mode = props.mode;

  // Input Variables
  const nameRef = useRef();
  const typeRef = useRef();

  const [label, setLabel] = useState(props.data.label);
  const [name, setName] = useState(props.data.name);
  const [isDefault, setIsDefault] = useState(props.data.isDefault);
  const [type, setType] = useState(props.data.type);
  const [typeConfig, setTypeConfig] = useState(props.data.typeConfig);
  // End Input Variables

  // Context used to Refresh notifiers tab
  const refreshContext = useContext(RefreshContext);

  const close = () => {
    props.setIsOpen(false);
  };

  const onSave = async () => {
    let hasError = false;
    hasError = await validateInput();

    if (!hasError) {
      await sendRequest();
      close();
    }
  };

  const validateInput = async () => {
    let hasError = false;

    let nameError = await nameRef.current.validateInput();
    let typeError = await typeRef.current.validateInput();

    if (nameError || typeError) {
      hasError = true;
    }

    return hasError;
  };

  const sendRequest = async () => {
    // Supported subset of types
    const availableTypes = constants.SUPPORTED_CHANNEL_TYPES;
    let payload = {};
    if (mode === constants.CREATE_MODE) {
      payload = {
        label: label.trim(),
        name: name.trim(),
        type: type,
        isDefault: isDefault,
        service: serviceName,
        typeConfig: typeConfig,
      };
      if (availableTypes.includes(type)) {
        const response = await apiCalls.createNotifierEndpoint(payload);
        refreshContext.refreshNotifiers();
        if (response.status === 200 || response.status === 201) {
          if (
            // Toast Optional
            props.setToastMessage !== undefined &&
            props.setToastVariant !== undefined &&
            props.setShowToast !== undefined
          ) {
            props.setToastMessage('Notification channel was created.');
            props.setToastVariant('success');
            props.setShowToast(true);
          }
        }
      }
    } else if (mode === constants.EDIT_MODE) {
      payload = {
        id: props.data.id,
        label: label.trim(),
        name: name.trim(),
        type: type,
        isDefault: isDefault,
        service: serviceName,
        typeConfig: typeConfig,
      };
      if (availableTypes.includes(type)) {
        const response = await apiCalls.updateNotifierEndpoint(payload);
        refreshContext.refreshNotifiers();
        refreshContext.refreshRules(); // use case: renamed channel displayed properly in rules tab
        if (response.status === 200 || response.status === 201) {
          if (
            // Toast Optional
            props.setToastMessage !== undefined &&
            props.setToastVariant !== undefined &&
            props.setShowToast !== undefined
          ) {
            props.setToastMessage('Notification channel was saved.');
            props.setToastVariant('success');
            props.setShowToast(true);
          }
        }
      }
    }
  };

  return (
    <Fragment>
      {mode === constants.CREATE_MODE ? (
        <Modal
          containerClassName="notification-channels-modal-container"
          contentClassName="notification-channels-modal-content"
          isOpen={props.isOpen}
          heading="New Notification Channel"
          dismissOnClickOutside={false}
          onRequestClose={() => close()}
          footer={[
            <Button key="cancel" label="Cancel" onClick={() => close()} />,
            <Button key="save" label="Save" variant="brand" onClick={() => onSave()} />,
          ]}
        >
          <ChannelEditorName
            mode={mode}
            id={props.data.id}
            label={label}
            setLabel={setLabel}
            setName={setName}
            notifiers={props.notifiers}
            ref={nameRef}
            focusOnMount={true}
          />
          <IsDefaultInput name={name} isDefault={isDefault} setIsDefault={setIsDefault} notifiers={props.notifiers} />
          <ChannelEditorType
            serviceName={serviceName}
            type={type}
            setType={setType}
            typeConfig={typeConfig}
            setTypeConfig={setTypeConfig}
            ref={typeRef}
          />
        </Modal>
      ) : mode === constants.EDIT_MODE ? (
        <Modal
          containerClassName="notification-channels-modal-container"
          contentClassName="notification-channels-modal-content"
          isOpen={props.isOpen}
          heading="Edit Notification Channel"
          dismissOnClickOutside={false}
          onRequestClose={() => props.setIsOpen(false)}
          footer={[
            <Button key="cancel" label="Cancel" onClick={() => close()} />,
            <Button key="save" label="Save" variant="brand" onClick={() => onSave()} />,
          ]}
        >
          <ChannelEditorName
            mode={mode}
            id={props.data.id}
            label={label}
            setLabel={setLabel}
            setName={setName}
            notifiers={props.notifiers}
            ref={nameRef}
          />
          <IsDefaultInput name={name} isDefault={isDefault} setIsDefault={setIsDefault} notifiers={props.notifiers} />
          <ChannelEditorType
            serviceName={serviceName}
            type={type}
            setType={setType}
            typeConfig={typeConfig}
            setTypeConfig={setTypeConfig}
            ref={typeRef}
          />
        </Modal>
      ) : null}
    </Fragment>
  );
};

NotificationChannelsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  mode: PropTypes.string,
  serviceName: PropTypes.string,
  data: PropTypes.object,
  notifiers: PropTypes.array,
  setToastMessage: PropTypes.func, // optional
  setToastVariant: PropTypes.func, // optional
  setShowToast: PropTypes.func, // optional
};

export default NotificationChannelsModal;
