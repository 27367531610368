///////////////////////////////////////////
// Simple state machine based parser for parsing custom logic string
// containing numbers (as terms), parentheses, and the operators AND and OR only.
// Can also be used to replace the found terms via a callback.
// Can also be asked to return the first error encountered, including at which character position.
// Return value will be a cleaned up version of the input string (basically just correct whitespacing)

import { errors } from '../../constants';

export const parseCustomLogic = (filterString, replaceFunc, errorFunc) => {
  const NONE = 0;
  const NORMAL = 1;
  const NUMBER = 2;
  const LOGIC_OP = 3;
  const SQUARE_BRACKET = 4;

  let state = NORMAL;
  let brackets = 0;
  let exprNum = '';
  let logicOp = '';
  let err = '';
  let result = '';
  let lastToken = NONE;
  let bracketText = '';

  // Handle any errors during parsing. Call the error function if we have one.
  const handleError = (error, charPos) => {
    err = charPos + ': ' + error;
    if (errorFunc) {
      errorFunc(error, charPos);
    }
  };

  // Change the state of the state machine of the parser.
  const changeState = (newState, charPos) => {
    if (newState !== state) {
      switch (state) {
        case SQUARE_BRACKET:
          result += (replaceFunc ? replaceFunc(bracketText) : '[DELETED]') + ' ';
          lastToken = NUMBER;
          break;
        case NUMBER:
          if (lastToken === NUMBER) {
            // Logic operator must follow number
            handleError(errors.EXPECT_OPERATOR, charPos - exprNum.length);
          } else {
            result += (replaceFunc ? replaceFunc(exprNum) : exprNum) + ' ';
            lastToken = NUMBER;
          }
          if (newState !== NORMAL) {
            // Expect whitespace after number (bracket counts as whitespace)
            handleError(errors.EXPECT_WHITESPACE, charPos);
          }
          break;
        case LOGIC_OP:
          if (logicOp !== 'AND' && logicOp !== 'OR') {
            handleError(errors.EXPECT_OPERATOR, charPos);
          } else if (lastToken === LOGIC_OP) {
            // No two logic ops in a row
            handleError(errors.EXPECT_NUMBER, charPos - logicOp.length);
          } else if (result === '') {
            // No logic op as first item
            handleError(errors.EXPECT_NUMBER, 0);
          } else {
            // Valid logic operation found
            result += logicOp + ' ';
            lastToken = LOGIC_OP;
          }
          if (newState !== NORMAL) {
            // Expect whitespace after number (bracket counts as whitespace)
            handleError(errors.EXPECT_WHITESPACE, charPos);
          }
          break;
        default:
          break;
      }
      state = newState;
      exprNum = '';
      logicOp = '';
      bracketText = '';
    }
  };

  // Go through the logic string character by character and run a state machine to track what we're parsing.
  for (let i = 0; i < filterString.length; i++) {
    let ch = filterString[i].toUpperCase();

    if (state === SQUARE_BRACKET) {
      if (ch === ']') {
        bracketText += ']';
        lastToken = SQUARE_BRACKET;
        changeState(NORMAL, i);
      } else if (ch === '[') {
        handleError(errors.UNEXPECTED_CHAR, i - bracketText.length);
      } else {
        bracketText += ch;
      }
      continue;
    }

    switch (ch) {
      case '(':
        changeState(NORMAL, i);
        brackets++;
        if (lastToken !== LOGIC_OP && lastToken !== NONE) {
          // Opening parens only after a logical operator or as the first character
          handleError(errors.EXPECT_OPERATOR, i);
        }
        result += '(';
        break;

      case ')':
        changeState(NORMAL, i);
        brackets--;
        if (lastToken !== NUMBER && lastToken !== SQUARE_BRACKET) {
          // Last thing before a closing parens must be a number (or the [DELETED] token)
          handleError(errors.EXPECT_NUMBER, i);
        }
        if (brackets < 0) {
          // More parens closed than opened.
          handleError(errors.BRACKETS_UNBALANCED, i);
        }
        result = result.trim() + ') ';
        break;

      case '[':
        changeState(SQUARE_BRACKET, i);
        bracketText += '[';
        break;

      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        changeState(NUMBER, i);
        exprNum += ch;
        break;

      case ' ':
        changeState(NORMAL, i);
        break;

      case 'A':
      case 'N':
      case 'D':
      case 'O':
      case 'R':
        changeState(LOGIC_OP, i);
        logicOp += ch;
        break;
      default:
        handleError(errors.UNEXPECTED_CHAR, i);
    }

    if (err) {
      return filterString;
    }
  }

  // Parsing complete, finish the last state
  changeState(NORMAL, filterString.length);

  // Formula must end in a number
  if (lastToken !== NUMBER) {
    handleError(errors.EXPECT_NUMBER, filterString.length);
  }

  // All open parnes must be closed
  if (brackets !== 0) {
    handleError(errors.BRACKETS_UNBALANCED, filterString.length);
  }

  return result.trim();
};
