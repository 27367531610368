export const isLowerCase = (str) => {
  return str === str.toLowerCase() && str !== str.toUpperCase();
}

export const isUpperCase = (str) => {
  return str === str.toUpperCase() && str !== str.toLowerCase();
}

// Return the given string with its first letter upper cased and the rest lower cased.
// Always returns a valid string.
export const capitalize = (str) => {
  if (str && (str.length > 0)) {
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
  }
  return '';
}

// Return the given string with its first letter upper cased and the rest unchanged.
// Always returns a valid string.
export const capitalizeCamel = (str) => {
  if (str && (str.length > 0)) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
  return '';
}

// Return the given string with its first letter upper cased and the rest with a space inserer before upper cased words.
// Always returns a valid string.
export const capitalizeCamelAndSplit = (str) => {
  let s = '';
  if (str && (str.length > 0)) {
    for (let i = 0; i < str.length; i++) {
      if (i === 0) {
        s += str.charAt(0).toUpperCase();
      }
      else if (isUpperCase(str.charAt(i))) {
        s += ' ' + str.charAt(i);
      }
      else {
        s += str.charAt(i);
      }
    }
  }
  return s;
}
