import React, { Fragment, forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { Input } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import { constants, errors } from '../../../../constants';
import { getNameFromLabel } from '../../../shared/utilities';

const ChannelEditorName = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [isInputMounted, setInputMounted] = useState(false);

  const notifierId = props.id;
  const allChannelLabels = props.notifiers.map((notificationChannel) => notificationChannel.label.toLowerCase());
  const channelToIdMap = props.notifiers.reduce((notifier, { id, label }) => {
    notifier[label.toLowerCase()] = id;
    return notifier;
  }, {});
  const existingNotifierNames = props.notifiers.map((notificationChannel) => notificationChannel.name.toLowerCase());

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let label = props.label !== undefined ? props.label.trim() : '';

      // Validate name
      if (label.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      } else if (label.length > constants.TEXT_CHAR_LIMIT) {
        hasError = true;
        setError(errors.CHAR_LIMIT);
      } else if (notifierId === '' && allChannelLabels.includes(label.toLowerCase())) {
        hasError = true;
        setError(errors.NON_UNIQUE_CHANNEL_NAME);
      } else if (
        channelToIdMap[label.toLowerCase()] !== undefined &&
        channelToIdMap[label.toLowerCase()] !== props.id
      ) {
        hasError = true;
        setError(errors.NON_UNIQUE_CHANNEL_NAME);
      } else {
        setError('');
      }

      return hasError;
    },
  }));

  const [error, setError] = useState('');

  // Focus this input field if focusOnMount prop passed in
  useEffect(() => {
    if (props.focusOnMount && inputRef.current !== undefined) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputMounted]);

  return (
    <Fragment>
      <Input
        inputRef={(el) => {
          inputRef.current = el;
          setInputMounted(!!el);
        }}
        className="input-channel-name"
        id="input-channel-name"
        label="Channel Name"
        errorText={error}
        placeholder="e.g. Email Support Team"
        required
        onChange={(e) => {
          props.setLabel(e.target.value);

          if (props.mode === constants.CREATE_MODE) {
            let autoGeneratedName = getNameFromLabel('notifier-', e.target.value, existingNotifierNames);
            props.setName(autoGeneratedName);
          }
        }}
        value={props.label}
      />
    </Fragment>
  );
});

ChannelEditorName.propTypes = {
  mode: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  setLabel: PropTypes.func,
  setName: PropTypes.func,
  notifiers: PropTypes.array,
  focusOnMount: PropTypes.bool,
};

export default ChannelEditorName;
