import React, { Fragment, forwardRef, useImperativeHandle, useState } from 'react';
import { Input } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import { constants, errors } from '../../../../constants';

const ServiceEditorApiName = forwardRef((props, ref) => {
  const serviceId = props.id;
  const serviceApiToIdMap = props.services.reduce((service, { id, apiName }) => {
    service[apiName.toLowerCase()] = id;
    return service;
  }, {});
  // Match if first character is not a letter, or there contains a character that's not a alphanumeric, hyphen, or underscore character
  const apiNameRegex = RegExp('^[^a-zA-Z]|[^\\w-]');
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let apiName = props.apiName !== undefined ? props.apiName.trim() : '';

      // Validate API Name
      if (apiName.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      } else if (apiName.length > constants.TEXT_CHAR_LIMIT) {
        hasError = true;
        setError(errors.CHAR_LIMIT);
      } else if (serviceId === '' && serviceApiToIdMap.hasOwnProperty(apiName)) {
        hasError = true;
        setError(errors.NON_UNIQUE_API_NAME);
      } else if (
        serviceApiToIdMap[apiName.toLowerCase()] !== undefined &&
        serviceApiToIdMap[apiName.toLowerCase()] !== props.id
      ) {
        hasError = true;
        setError(errors.NON_UNIQUE_API_NAME);
      } else if (apiNameRegex.test(apiName)) {
        hasError = true;
        setError(errors.INVALID_API_NAME);
      } else {
        setError('');
      }
      return hasError;
    },
  }));

  const [error, setError] = useState('');

  return (
    <Fragment>
      <Input
        id="input-service-api-name"
        label="API Name"
        errorText={error}
        placeholder="Used to reference this service via the API"
        required
        onChange={(e) => {
          if (e.target.value === '') {
            props.setPopulateApiName(true);
          }
          props.setApiName(e.target.value);
        }}
        onInput={(e) => {
          props.setPopulateApiName(false);
        }}
        value={props.apiName}
      />
    </Fragment>
  );
});

ServiceEditorApiName.propTypes = {
  id: PropTypes.string,
  apiName: PropTypes.string,
  setApiName: PropTypes.func,
  services: PropTypes.array,
};

export default ServiceEditorApiName;
