const endpoints = {
  GUS_ENDPOINT: 'https://gus.my.salesforce.com/',
  WHO_AM_I_ENDPOINT: 'whoami',
  AUTH_ENDPOINT: 'auth?RelayState={returnUrl}',
  LOGIN_ENDPOINT: 'login',
  SEND_ALERT_ENDPOINT: 'alerts',
  GET_EVENTS_ENDPOINT: 'events?serviceId={id}&sourceAlertId={sourceAlertId}',
  GET_TEAMS_ENDPOINT: 'teams',
  CREATE_TEAM_ENDPOINT: 'teams',
  UPDATE_TEAM_ENDPOINT: 'teams',
  VIEW_TEAM_ENDPOINT: 'teams/%s',
  CREATE_TOKEN_ENDPOINT: 'tokens',
  DELETE_TOKEN_ENDPOINT: 'tokens/{id}',
  GET_TOKEN_ENDPOINT: 'tokens/%s',
  GET_TOKENS_FOR_USER_ENDPOINT: 'tokens/user/{userId}',
  GET_SERVICE_DETAILS_ENDPOINT: 'services/{id}',
  GET_RULES_BY_SERVICE_ID_ENDPOINT: 'rules?serviceId={id}&tree={tree}',
  GET_RULES_BY_SERVICE_ID_AND_TYPE_ENDPOINT: 'rules?serviceId={id}&tree={tree}&type={type}',
  GET_RULES_BY_ID_ENDPOINT: 'rules/{id}?tree={tree}',
  DELETE_RULE_BY_ID_ENDPOINT: 'rules/{id}',
  CREATE_NOTIFIER_ENDPOINT: 'notifiers',
  DELETE_NOTIFIER_ENDPOINT: 'notifiers/{id}',
  GET_NOTIFIERS_BY_SERVICE_ID_ENDPOINT: 'notifiers?serviceId={id}',
  GET_SERVICES_ENDPOINT: 'services',
  GET_SERVICES_BY_USERNAME_ENDPOINT: 'services?serviceOwner={username}',
  DELETE_SERVICES_ENDPOINT: 'services/{id}',
  GET_NOTIFIERS_QUERY_BY_SERVICE_ENDPOINT: 'notifiers?serviceId={id}',
  GET_ALERT_FIELDS_ENDPOINT: 'fields/alerts',
  UPDATE_RULE_ENDPOINT: 'rules',
  GET_PRODUCT_TAG_QUERY_BY_NAME_ENDPOINT: 'sobjects?type=ADM_Product_Tag__c&name={query}',
  GET_PRODUCT_TAG_NAME_QUERY_BY_ID_ENDPOINT: 'sobjects?type=ADM_Product_Tag__c&id={id}',
  CREATE_SERVICE_ENDPOINT: 'services',
  UPDATE_SERVICE_ENDPOINT: 'services',
  GET_GUS_SERVICE_CATALOG_ID_QUERY_BY_NAME_ENDPOINT: 'sobjects?type=Service__c&name={query}',
  GET_GUS_SERVICE_CATALOG_NAME_QUERY_BY_ID_ENDPOINT: 'sobjects?type=Service__c&id={id}',
  GET_TOKENS_ENDPOINT: 'tokens',
  GET_GUS_CHATTER_GROUP_QUERY_BY_NAME_ENDPOINT: 'sobjects?type=CollaborationGroup&name={query}',
  GET_GUS_CHATTER_GROUP_NAME_QUERY_BY_ID_ENDPOINT: 'sobjects?type=CollaborationGroup&id={id}',
  GET_USERS_QUERY_BY_NAME_ENDPOINT: 'sobjects?type=User&name={query}',
  GET_USER_QUERY_BY_ID_ENDPOINT: 'sobjects?type=User&id={id}',
  GET_ALERTS_BY_SERVICE_ID_ENDPOINT: 'alerts?serviceId={query}&limit=100',
  GET_ALERTS_BY_SERVICE_ID_AND_SOURCE_ALERT_ID_ENDPOINT: 'alerts?serviceId={query}&sourceAlertId={id}&limit=100',
  CREATE_ALERT_ENDPOINT: 'alerts',
  GET_TEAMS_QUERY_BY_NAME_ENDPOINT: 'sobjects?type=ADM_Scrum_Team__c&name={query}',
  GET_TEAM_QUERY_BY_ID_ENDPOINT: 'sobjects?type=ADM_Scrum_Team__c&id={id}',
};

const constants = {
  ELASTIC_SEARCH_UPDATE_DELAY: 1000,
  DATE_TIME_FORMAT: 'MM/DD/YYYY h:mm a',
  ADMIN: 'admin',
  TEXT_CHAR_LIMIT: 256,
  LONG_TEXT_CHAR_LIMIT: 2048,
  SLACK_CHANNEL_ID_CHAR_LIMIT_MIN: 9,
  SLACK_CHANNEL_ID_CHAR_LIMIT_MAX: 11,
  TEXTAREA_CHAR_LIMIT: 65536,
  TOAST_DURATION: 3000,
  SEARCH_DELAY: 500,
  MIN_SEARCH_LENGTH: 3,
  CREATE_MODE: 'create',
  EDIT_MODE: 'edit',
  DELETE_MODE: 'delete',
  CLONE_MODE: 'clone',
  DETAILS_MODE: 'details',
  TOKEN: 'token',
  TYPE_MAP: {
    BUG: 'Bug',
    INCIDENT: 'Case: Incident',
    SF_MARKETING_CLOUD_INCIDENT: 'Case: Marketing Cloud Incident',
    RMA_CASE: 'Case: RMA',
    GUS_CHATTER: 'Chatter Group (GUS)',
    ORG62_CHATTER: 'Chatter Group (Org62)',
    EMAIL: 'Email',
    GOC: 'GOC++',
    INVESTIGATION: 'Investigation',
    NOOP: 'Noop',
    PAGERDUTY: 'PagerDuty',
    SERVICE: 'Service',
    SLACK: 'Slack',
    USER_STORY: 'User Story',
  },
  SUPPORTED_CHANNEL_TYPES: [
    'NOOP',
    'EMAIL',
    'SERVICE',
    'PAGERDUTY',
    'GOC',
    'GUS_CHATTER',
    'INVESTIGATION',
    'BUG',
    'USER_STORY',
    'INCIDENT',
    'SLACK',
  ],
  TYPE_BUG: 'BUG',
  TYPE_INCIDENT: 'INCIDENT',
  TYPE_SF_MARKETING_CLOUD_INCIDENT: 'SF_MARKETING_CLOUD_INCIDENT',
  TYPE_RMA_CASE: 'RMA_CASE',
  TYPE_GUS_CHATTER: 'GUS_CHATTER',
  TYPE_ORG62_CHATTER: 'ORG62_CHATTER',
  TYPE_EMAIL: 'EMAIL',
  TYPE_GOC: 'GOC',
  TYPE_INVESTIGATION: 'INVESTIGATION',
  TYPE_NOOP: 'NOOP',
  TYPE_PAGERDUTY: 'PAGERDUTY',
  TYPE_SERVICE: 'SERVICE',
  TYPE_USER_STORY: 'USER_STORY',
  TYPE_SLACK: 'SLACK',
  SERVICE_CATALOG: 'serviceCatalog',
  SERVICE_NAME: 'serviceName',
  SERVICE: 'service',
  SERVICE_ID: 'serviceId',
  API_NAME: 'apiName',
  OWNERS: 'owners',
  ACTIVE: 'active',
  PENDING: 'pending',
  EXPIRED: 'expired',
  CONFIRM: 'confirm',
  INFORM: 'inform',
  DELETE: 'delete',
  CLOSE: 'close',
  CUSTOM_PREFIX: 'custom.',
  SOURCE_ALERT_ID: 'sourceAlertId',
  STATUS: 'status',
  SEVERITY: 'severity',
  NAME: 'name',
  DESCRIPTION: 'description',
  COMPONENT: 'component',
  SOURCE: 'source',
  KNOWLEDGE_ARTICLE_ID: 'knowledgeArticleId',
  MODIFIED_DATE: 'modifiedDate',
  FROM_NOW: 'fromNow',
  REQUEST_ID: 'requestId',
  VISITED_SERVICES: 'visitedServices',
  ALERT_STATUS_ACTIVE: 'ACTIVE',
  ALERT_STATUS_ACKNOWLEDGE: 'ACKNOWLEDGE',
  ALERT_STATUS_CLEAR: 'CLEAR',
  ASSIGNED_TO_LABEL: 'Assigned To',
  CASE_OWNER_LABEL: 'Case Owner',
  SLACK_CHANNEL_ID_LABEL: 'Slack Channel ID',
  ACTIVE_SUPPRESSION_RULE_LABEL: 'Active Suppression Rules',
  PENDING_SUPPRESSION_RULE_LABEL: 'Pending Suppression Rules',
  EXPIRED_SUPPRESSION_RULE_LABEL: 'Expired Suppression Rules',
  MY_SERVICES: 'myServices',
  MY_SERVICES_LABEL: 'My Services',
  ALL_SERVICES: 'allServices',
  ALL_SERVICES_LABEL: 'All Services',
};

const errors = {
  REQUIRED: 'This field is required.',
  CHAR_LIMIT: 'This field exceeds the maximum of 256 characters.',
  SLACK_CHANNEL_ID_CHAR_LIMIT: 'Slack channel ID must between 9 and 11 characters.',
  CHAR_LIMIT_SQL: 'This field exceeds the maximum of 65,536 characters.',
  CHAR_LIMIT_LONG: 'This field exceeds the maximum of 2048 characters.',
  INVALID_EMAIL:
    'Invalid format. Only Salesforce, Slack, PagerDuty, and Chatter email addresses allowed. For multiple emails, separate with a comma.',
  NON_UNIQUE_NAME: 'This name is already in use.',
  NON_UNIQUE_CHANNEL_NAME: 'Name is already in use by your service.',
  NON_UNIQUE_API_NAME: 'This API name is already in use.',
  INVALID_API_NAME: 'API name must start with a letter and can only contain letters, numbers, hyphens, or underscores.',
  INVALID_CUSTOM_FIELD:
    'Field name must start with a letter and can only contain letters, numbers, periods, or underscores.',
  INVALID_CUSTOM_FIELD_PERIODS: 'Field name may not contain consecutive periods or end with a period.',
  INVALID_END_DATE_IN_PAST: 'End date must be in the future.',
  INVALID_END_DATE_BEFORE_START_DATE: 'End date must be after the start date.',
  INVALID_DATE_TIME: 'Invalid date or time',
  EXPECT_NUMBER: 'Number expected.',
  EXPECT_OPERATOR: 'AND or OR operator expected.',
  EXPECT_WHITESPACE: 'Whitespace expected.',
  BRACKETS_UNBALANCED: 'Unbalanced parentheses.',
  UNEXPECTED_CHAR: 'Unexpected character. Only numbers, AND, OR and parentheses are allowed.',
  DELETED_TERMS_STILL_PRESENT: 'Custom logic expression still contains deleted conditions.',
  UNABLE_TO_EDIT: 'Unable to Edit',
  UNABLE_TO_DELETE: 'Unable to Delete',
};

const fieldLabelOverrides = [
  { api: 'modifiedDateMs', display: 'Last Modified' },
  { api: 'sourceAlertId', display: 'Source Alert ID' },
  { api: 'knowledgeArticleId', display: 'Knowledge Article ID' },
  { api: 'groupingRuleId', display: 'Grouping Rule' },
];

const excludedFieldLabels = ['requestId'];

export { endpoints, constants, errors, fieldLabelOverrides, excludedFieldLabels };
