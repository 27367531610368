import React, { Fragment, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../../constants';
import apiCalls from '../../../../shared/api';
import { getChannelTypeImage } from '../../../../shared/utilities';
import PropTypes from 'prop-types';
import useDebounce from '../../../../shared/hooks/useDebounce';

const ChannelEditorTypeChatterGroup = forwardRef((props, ref) => {
  const type = props.type;

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;

      if (chatterGroupId.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      }
      return hasError;
    },
  }));
  const [error, setError] = useState('');

  // Combobox Variables
  const [searchValue, setSearchValue] = useState('');
  const [chatterGroups, setChatterGroups] = useState([]);
  const [selectedChatterGroup, setSelectedChatterGroup] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  // TODO: implement ORG62 chatter group, pending backend lookup
  const [chatterGroupId, setChatterGroupId] = useState(
    type === constants.TYPE_GUS_CHATTER && props.payload.gusChatterGroupId !== undefined
      ? props.payload.gusChatterGroupId
      : type === constants.TYPE_ORG62_CHATTER && props.payload.org62ChatterGroupId !== undefined
      ? props.payload.org62ChatterGroupId
      : ''
  );

  const debouncedSearchValue = useDebounce(searchValue, constants.SEARCH_DELAY);

  useEffect(() => {
    const setChatterLabel = async () => {
      let response;
      if (type === constants.TYPE_GUS_CHATTER) {
        response = await apiCalls.getGusChatterGroupById(props.payload.gusChatterGroupId);
      } else {
        response = await apiCalls.getGusChatterGroupById(props.payload.org62ChatterGroupId);
      }
      const data = response.data;
      if (data[0] !== undefined) {
        setSelectedChatterGroup([
          {
            id: data[0].id,
            label: data[0].name,
            icon: getChannelTypeImage(constants.TYPE_GUS_CHATTER, 'notification-channels-modal'),
          },
        ]);
      }
    };
    setChatterLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === constants.TYPE_GUS_CHATTER) {
      props.setPayload({ ...props.payload, gusChatterGroupId: chatterGroupId });
    } else {
      props.setPayload({ ...props.payload, org62ChatterGroupId: chatterGroupId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatterGroup]);

  useEffect(() => {
    const fetchChatterGroups = async () => {
      let query = debouncedSearchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.getGusChatterGroups(query);
        const data = response.data;
        if (data !== '') {
          const results = data.map((datum) => {
            return {
              id: datum.id,
              label: datum.name,
              icon: getChannelTypeImage(constants.TYPE_GUS_CHATTER, 'notification-channels-modal'),
            };
          });
          setChatterGroups(results);
        }
        setComboboxDropdown(true);
        setSpinner(false);
      }
    };
    fetchChatterGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <Fragment>
      <Combobox
        className="channel-editor-chatter-group"
        classNameMenu="modal-combobox-dropdown"
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedChatterGroup(data.selection);
            setChatterGroups([]);
            setChatterGroupId('');
          },
          onSelect: (event, data) => {
            setComboboxDropdown(false);
            setSelectedChatterGroup(data.selection);
            setChatterGroupId(data.selection[0].id);
            setError('');
          },
        }}
        labels={{
          label: type === constants.TYPE_GUS_CHATTER ? 'GUS Chatter Group' : 'Org 62 Chatter Group',
          placeholder: 'Search Chatter Groups...',
        }}
        menuItemVisibleLength={7}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: chatterGroups,
          selection: selectedChatterGroup,
        })}
        selection={selectedChatterGroup}
        value={searchValue}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        required
        errorText={error}
      />
    </Fragment>
  );
});

ChannelEditorTypeChatterGroup.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.object,
  setPayload: PropTypes.func,
};

export default ChannelEditorTypeChatterGroup;
