import React, { Fragment } from 'react';
import { Button, Modal, Settings } from '@salesforce/design-system-react';
import './DeleteTokenModal.scss';
import apiCalls from '../shared/api';

Settings.setAppElement('#root');

const DeleteTokenModal = (props) => {
  const name = props.deleteTokenData.name;
  const id = props.deleteTokenData.id;

  const close = () => {
    props.setIsDeleteModalOpen(false);
  };

  const onDelete = async () => {
    await sendDelete();
    close();
  };

  const sendDelete = async () => {
    const response = await apiCalls.deleteToken(id);
    if (response.status === 200 || response.status === 204) {
      if (props.setShowToast !== undefined) {
        props.onTokenDelete();
        props.setShowToast(true);
      }
    }
  };

  return (
    <Fragment>
      <Modal
        containerClassName="delete-token-modal-container"
        contentClassName="delete-token-modal-content"
        isOpen={props.isOpen}
        heading={'Delete API token?'}
        dismissOnClickOutside={false}
        onRequestClose={() => props.setIsDeleteModalOpen(false)}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => close()} />,
          <Button key="delete" label="Delete" variant="destructive" onClick={() => onDelete()} />,
        ]}
      >
        <span>
          Any API access using the <strong>{name}</strong> token will stop working immediately once deleted. This
          operation cannot be undone. Are you sure you want to delete?
        </span>
      </Modal>
    </Fragment>
  );
};

export default DeleteTokenModal;
