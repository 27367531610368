import React, { useState, Fragment, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Input, Tooltip } from '@salesforce/design-system-react';
import { constants, errors } from '../../../../../constants';

const ChannelEditorTypePagerDuty = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let key = inputKey.trim();

      if (key.length === 0) {
        hasError = true;
        setError(errors.REQUIRED);
      } else if (key.length > constants.TEXT_CHAR_LIMIT) {
        hasError = true;
        setError(errors.CHAR_LIMIT);
      } else {
        setError('');
      }

      return hasError;
    },
  }));

  const [inputKey, setInputKey] = useState(
    props.payload !== undefined ? (props.payload.serviceKey !== undefined ? props.payload.serviceKey : '') : ''
  );
  const [error, setError] = useState('');

  useEffect(() => {
    props.setPayload({ ...props.payload, serviceKey: inputKey.trim() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputKey]);

  return (
    <Fragment>
      <Input
        id="input-service-key"
        label="Events API v2 Integration Key"
        placeholder="e.g. 132ab99fc9cd4296abcba612350b9032"
        required
        errorText={error}
        value={inputKey}
        onChange={(e) => {
          setInputKey(e.target.value);
        }}
        fieldLevelHelpTooltip={
          <Tooltip
            id="input-default-channel-tooltip"
            align="right"
            content="Events API v2 integration key is displayed in the Integrations tab of the PagerDuty service page."
          />
        }
      />
    </Fragment>
  );
});

export default ChannelEditorTypePagerDuty;
