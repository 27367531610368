import React, { useContext, useState, useEffect } from 'react';
import apiCalls from '../shared/api';
import {
  Icon,
  IconSettings,
  Input,
  InputIcon,
  Button,
  PageHeader,
  PageHeaderControl,
  Dropdown,
} from '@salesforce/design-system-react';
import './ServicesList.scss';
import ServicesModal from './ServicesModal/ServicesModal';
import ServicesListTable from './ServicesListTable';
import { constants } from '../../constants';
import CenteredSpinner from '../shared/Spinner/CenteredSpinner';
import { uamStore } from '../shared/store';

const serviceDropdownOptions = [
  { label: constants.MY_SERVICES_LABEL, value: constants.MY_SERVICES },
  { label: constants.ALL_SERVICES_LABEL, value: constants.ALL_SERVICES },
];
const defaultServiceOption = { label: constants.MY_SERVICES_LABEL, value: constants.MY_SERVICES };

const ServicesList = (props) => {
  const [loadAllServices, setLoadAllServices] = useState(false);
  const [services, setServices] = useState([]);
  const [sortProperties, setSortProperties] = useState({ sortField: 'serviceName', sortDirection: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedServices, setSearchedServices] = useState([]);
  const [foundItems, setFoundItems] = useState(0);
  const [fetchingServices, setFetchingServices] = useState(false);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;

  const setOpenCreate = React.useCallback(() => {
    setModalData({
      id: '',
      name: '',
      label: '',
      serviceCatalogId: '',
    });
    setMode(constants.CREATE_MODE);
    setIsModalOpen(true);
  }, []);

  const fetchServices = async (serviceType) => {
    setFetchingServices(true);
    let response;
    try {
      if (serviceType === constants.MY_SERVICES) {
        response = await apiCalls.getServicesByUsername(globalState.userNameLowercase);
        setLoadAllServices(false);
      } else {
        response = await apiCalls.getServices();
        setLoadAllServices(true);
      }
      const data = response.data;
      var serviceNamesList = [];

      // Transform API data to something better suited to DataTable.
      Object.entries(data).forEach((datum) => {
        const service = {};
        service.id = datum[1].id.toString();
        service.apiName = datum[1].name;
        service.serviceName = datum[1].label;
        service.owners = datum[1].serviceOwners.join(', ');
        if (datum[1].serviceCatalogId) {
          service.serviceCatalog = datum[1].serviceCatalogId;
          service.serviceCatalogId = datum[1].serviceCatalogId;
        } else {
          service.serviceCatalog = '-';
        }
        service.active = datum[1].isActive;
        service.status = datum[1].status;

        serviceNamesList.push(service);
      });
      sortTable(serviceNamesList, sortProperties.sortField, sortProperties.sortDirection);
    } catch {
      console.log('ERROR getting Services');
    } finally {
      setFetchingServices(false);
    }
  };

  // Fetch and set the service list, only if the service type clicked is different from the one currently selected
  const setServiceList = (value) => {
    if (value.value === constants.MY_SERVICES && loadAllServices) {
      fetchServices(constants.MY_SERVICES);
    } else if (value.value === constants.ALL_SERVICES && !loadAllServices) {
      fetchServices(constants.ALL_SERVICES);
    }
  };

  useEffect(() => {
    fetchServices(constants.MY_SERVICES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortTable = (data, sortField, sortDirection) => {
    // Now sort the data
    data.sort((x, y) => {
      let result = 1;
      if (typeof x[sortField] === 'string') {
        result = x[sortField].localeCompare(y[sortField], 'en', { sensitivity: 'case' });
      } else if (x[sortField] < y[sortField]) {
        result = -1;
      } else if (x[sortField] === y[sortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });
    setServices(data);
  };

  const actions = () => (
    <>
      <PageHeaderControl>
        <Button iconSize="medium" label="New Service" onClick={setOpenCreate} />
      </PageHeaderControl>
    </>
  );

  const controls = () => (
    <React.Fragment>
      <PageHeaderControl>
        <Input
          align="right"
          iconLeft={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          iconRight={
            searchTerm.length > 0 ? (
              <InputIcon
                assistiveText={{
                  icon: 'Clear',
                }}
                name="clear"
                category="utility"
                onClick={() => {
                  setSearchTerm('');
                }}
              />
            ) : (
              <div style={{ width: '20px' }} />
            )
          }
          id="search"
          placeholder="Search this list..."
          onChange={(event, data) => setSearchTerm(data.value)}
          value={searchTerm}
          disabled={fetchingServices}
        />
      </PageHeaderControl>
    </React.Fragment>
  );

  const handleSort = React.useCallback(
    (sortColumn, ...rest) => {
      sortTable(searchedServices, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [searchedServices]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      serviceName: 'Service Name',
      serviceCatalog: 'GUS Service',
      owners: 'Owner',
      active: 'Active',
      apiName: 'API Name',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  useEffect(() => {
    let searchedList = [];
    if (searchTerm.length > 0) {
      const terms = searchTerm.toUpperCase().split(' ');
      searchedList = services.filter(
        (item) => terms.filter((term) => item.serviceName.toUpperCase().indexOf(term) >= 0).length === terms.length
      );
    } else {
      searchedList = [...services];
    }
    setSearchedServices(searchedList);
    setFoundItems(searchedList.length);
  }, [services, searchTerm]);

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        {isModalOpen && mode === constants.CREATE_MODE ? (
          <ServicesModal
            history={props.history}
            isOpen={true}
            setIsModalOpen={setIsModalOpen}
            data={modalData}
            services={services}
          />
        ) : null}
        <PageHeader
          label="Services"
          title={loadAllServices ? constants.ALL_SERVICES_LABEL : constants.MY_SERVICES_LABEL}
          info={fetchingServices ? <span className="list-info-summary">Loading...</span> : getListInfo(foundItems)}
          joined
          variant="object-home"
          onRenderActions={actions}
          onRenderControls={controls}
          icon={<Icon assistiveText={{ label: 'Services' }} category="standard" name="dynamic_record_choice" />}
          nameSwitcherDropdown={
            <Dropdown
              assistiveText={{ icon: 'Rule State Switcher' }}
              buttonClassName="slds-button_icon-small"
              buttonVariant="icon"
              checkmark
              iconCategory="utility"
              iconName="down"
              id="page-header-name-switcher-dropdown"
              options={serviceDropdownOptions}
              onSelect={setServiceList}
              value={defaultServiceOption.value}
              width="xx-small"
            />
          }
        />
        {fetchingServices ? (
          <div className="loading-div">
            <CenteredSpinner variant="brand" size="medium" />
          </div>
        ) : (
          <ServicesListTable handleSort={handleSort} searchedList={searchedServices} sortProperties={sortProperties} />
        )}
        <div className="footer-bar"></div>
      </IconSettings>
    </div>
  );
};

export default React.memo(ServicesList);
