import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from './UserAvatar';
import './UserProfilePopover.scss';

const UserProfilePopover = ({ userName, userEmail, avatarUrl }) => {
  return (
    <div>
      <section className="slds-grid slds-grid--vertical-align-center slds-p-vertical--x-small">
        <UserAvatar userName={userName} avatarUrl={avatarUrl} />
        <div className="slds-grid slds-grid_vertical slds-grid_vertical-align-start slds-m-left--small ">
          <label className="slds-text-heading_small">{userName}</label>
          <label className="slds-text-body_regular">{userEmail}</label>
        </div>
      </section>
      <hr className="divider" />
      <section className="slds-text-align_left slds-p-vertical--xx-small">
        <a href="/tokens">API Tokens</a>
      </section>
    </div>
  );
};

UserProfilePopover.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  avatarUrl: PropTypes.string,
};

export default UserProfilePopover;
