import axios from 'axios';
import { endpoints, constants } from '../../constants';
import { deleteToken, getToken } from './store';

// Install an interceptor that handles 401-Unauthorized repsonses
axios.interceptors.response.use(
  (success) => success,
  (error) => {
    console.log('axios.interceptor.response.error: ', error.response);
    if (error.response.status === 401) {
      console.log('axios.interceptor.response.error: 401 - Removing token and redirecting');
      deleteToken();
      window.location.assign('/');
    } else {
      console.log('axios.interceptor.response.error: Rethrowing');
      throw error;
    }
  }
);

const apiCalls = {
  whoAmI: () => {
    const url = process.env.REACT_APP_URL + endpoints.WHO_AM_I_ENDPOINT;
    return axios({
      method: 'get',
      url: url,
      headers: {
        Accept: 'application/json',
        Authorization: getToken(),
      },
    });
  },

  createServiceEndpoint: (payload) => {
    const url = process.env.REACT_APP_API_URL + endpoints.CREATE_SERVICE_ENDPOINT;
    return axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },

  updateServiceEndpoint: (payload) => {
    const url = process.env.REACT_APP_API_URL + endpoints.UPDATE_SERVICE_ENDPOINT;
    return axios({
      method: 'put',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },

  deleteServiceEndpoint: (serviceId) => {
    const url = process.env.REACT_APP_API_URL + endpoints.DELETE_SERVICES_ENDPOINT.replace('{id}', serviceId);
    return axios({
      method: 'delete',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getServices: () => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_SERVICES_ENDPOINT;
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
      },
    });
  },

  getServicesByUsername: (username) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_SERVICES_BY_USERNAME_ENDPOINT.replace('{username}', username);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
      },
    });
  },

  getNotifiersByServiceId: (serviceId) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_NOTIFIERS_BY_SERVICE_ID_ENDPOINT.replace('{id}', serviceId);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
  createNotifierEndpoint: (payload) => {
    const url = process.env.REACT_APP_API_URL + endpoints.CREATE_NOTIFIER_ENDPOINT;
    return axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },
  updateNotifierEndpoint: (payload) => {
    const url = process.env.REACT_APP_API_URL + endpoints.CREATE_NOTIFIER_ENDPOINT;
    return axios({
      method: 'put',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },
  deleteNotifierEndpoint: (notifierId) => {
    const url = process.env.REACT_APP_API_URL + endpoints.DELETE_NOTIFIER_ENDPOINT.replace('{id}', notifierId);
    return axios({
      method: 'delete',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getRuleById: (ruleId, getTree) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_RULES_BY_ID_ENDPOINT.replace('{id}', ruleId).replace('{tree}', getTree ? 'true' : 'false');
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  deleteRuleById: (ruleId) => {
    const url = process.env.REACT_APP_API_URL + endpoints.DELETE_RULE_BY_ID_ENDPOINT.replace('{id}', ruleId);
    return axios({
      method: 'delete',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getRulesByServiceId: (serviceId, getTree) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_RULES_BY_SERVICE_ID_ENDPOINT.replace('{id}', serviceId).replace(
        '{tree}',
        getTree ? 'true' : 'false'
      );
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getRulesByServiceIdAndType: (serviceId, getTree, type) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_RULES_BY_SERVICE_ID_AND_TYPE_ENDPOINT.replace('{id}', serviceId)
        .replace('{tree}', getTree ? 'true' : 'false')
        .replace('{type}', type);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getServiceById: (serviceId) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_SERVICE_DETAILS_ENDPOINT.replace('{id}', serviceId);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getAlertFields: () => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_ALERT_FIELDS_ENDPOINT;
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getTokens: () => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_TOKENS_ENDPOINT;
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  createOrUpdateRuleForService: (mode, rule) => {
    const url = process.env.REACT_APP_API_URL + endpoints.UPDATE_RULE_ENDPOINT;
    return axios({
      method: mode === constants.CREATE_MODE || mode === constants.CLONE_MODE ? 'post' : 'put',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(rule),
    });
  },

  createToken: (tokenName) => {
    const url = process.env.REACT_APP_API_URL + endpoints.CREATE_TOKEN_ENDPOINT;
    return axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { name: tokenName },
    });
  },

  deleteToken: (id) => {
    const url = process.env.REACT_APP_API_URL + endpoints.DELETE_TOKEN_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'delete',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  queryServiceCatalog: (query) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_GUS_SERVICE_CATALOG_ID_QUERY_BY_NAME_ENDPOINT.replace('{query}', query);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getServiceCatalogNameById: (id) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_GUS_SERVICE_CATALOG_NAME_QUERY_BY_ID_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getProductTags: (query) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_PRODUCT_TAG_QUERY_BY_NAME_ENDPOINT.replace('{query}', query);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getProductTagNameById: (id) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_PRODUCT_TAG_NAME_QUERY_BY_ID_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getGusChatterGroups: (query) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_GUS_CHATTER_GROUP_QUERY_BY_NAME_ENDPOINT.replace('{query}', query);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getGusChatterGroupById: (id) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_GUS_CHATTER_GROUP_NAME_QUERY_BY_ID_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getUsers: (query) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_USERS_QUERY_BY_NAME_ENDPOINT.replace('{query}', query);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getUserById: (id) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_USER_QUERY_BY_ID_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getAlertsByServiceId: (serviceId) => {
    const url =
      process.env.REACT_APP_API_URL + endpoints.GET_ALERTS_BY_SERVICE_ID_ENDPOINT.replace('{query}', serviceId);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getAlertsByServiceIdQueryBySourceAlertId: (serviceId, sourceAlertId) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_ALERTS_BY_SERVICE_ID_AND_SOURCE_ALERT_ID_ENDPOINT.replace('{query}', serviceId).replace(
        '{id}',
        encodeURIComponent(sourceAlertId)
      );
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  createAlert: (payload) => {
    const url = process.env.REACT_APP_API_URL + endpoints.CREATE_ALERT_ENDPOINT;
    return axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },

  getTeams: (query) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_TEAMS_QUERY_BY_NAME_ENDPOINT.replace('{query}', query);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getTeamById: (id) => {
    const url = process.env.REACT_APP_API_URL + endpoints.GET_TEAM_QUERY_BY_ID_ENDPOINT.replace('{id}', id);
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getEventsByServiceIdAndSourceAlertId: (id, sourceAlertId) => {
    const url =
      process.env.REACT_APP_API_URL +
      endpoints.GET_EVENTS_ENDPOINT.replace('{id}', id).replace('{sourceAlertId}', encodeURIComponent(sourceAlertId));
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
};

export default apiCalls;
