import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { Button, Modal, Settings } from '@salesforce/design-system-react';
import './ServiceModal.scss';
import apiCalls from '../../shared/api';
import ServiceNameInput from './ServiceEditor/ServiceEditorName';
import ApiInput from './ServiceEditor/ServiceEditorApiName';
import ServiceCatalogIdInput from './ServiceEditor/ServiceEditorServiceCatalog';
import { ToastContext } from '../../shared/toast-context';
import PropTypes from 'prop-types';
import CheckboxEx from './ServiceEditor/CheckboxEx';
import UsersCombobox from './ServiceEditor/UsersCombobox';

Settings.setAppElement('#root');

const ServicesEditModal = (props) => {
  // Input Variables
  const serviceNameRef = useRef();
  const apiNameRef = useRef();
  const serviceOwnersRef = useRef();

  const [active, setActive] = useState(props.data.active);
  const [serviceName, setServiceName] = useState(props.data.serviceName);
  const [apiName, setApiName] = useState(props.data.apiName);
  const [serviceCatalogId, setServiceCatalogId] = useState(props.data.serviceCatalogId);

  const [serviceOwners, setServiceOwners] = useState([]);
  const [usersComboboxInput, setUsersComboboxInput] = useState([]);

  const [populateApiName, setPopulateApiName] = useState(false);

  // Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const [services, setServices] = useState([]);

  // Update serviceOwners payload when users comobobox is changed
  useEffect(() => {
    const usernames = usersComboboxInput.map((user) => {
      return user.username;
    });
    setServiceOwners(usernames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersComboboxInput]);

  useEffect(() => {
    const fetchServices = async () => {
      let response;
      try {
        response = await apiCalls.getServices();
        if (response.status === 204) {
          // no services
          return;
        }
      } catch {
        return;
      }

      const data = response.data;
      var servicesList = [];

      // Transform API data to something better suited to DataTable.
      Object.entries(data).forEach((datum) => {
        const service = {};
        service.id = datum[1].id.toString();
        service.apiName = datum[1].name;
        service.serviceName = datum[1].label;
        service.owners = datum[1].serviceOwners.join(', ');
        if (datum[1].serviceCatalogId) {
          service.serviceCatalogId = datum[1].serviceCatalogId;
        } else {
          service.serviceCatalogId = '';
        }
        service.active = datum[1].isActive;
        service.status = datum[1].status;

        servicesList.push(service);
      });
      setServices(servicesList);
    };
    fetchServices();
  }, []);

  const close = () => {
    props.setIsModalOpen(false);
  };

  const onSave = async () => {
    let hasError = false;
    hasError = await validateInput();

    if (!hasError) {
      const servicePayload = {
        id: props.data.id,
        name: apiName.trim(),
        label: serviceName.trim(),
        ...(serviceCatalogId !== '' && serviceCatalogId !== '-' && { serviceCatalogId: serviceCatalogId.trim() }),
        isActive: active,
        serviceOwners: serviceOwners,
      };
      const serviceResponse = await apiCalls.updateServiceEndpoint(servicePayload);
      if (serviceResponse.status === 200 || serviceResponse.status === 201) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: { ...toastState, toastMessage: 'Service was saved.', toastVariant: 'success', showToast: true },
        });
        props.setRefresh(!props.refresh);
        close();
      }
    }
  };

  const validateInput = async () => {
    let hasError = false;
    let nameError = await serviceNameRef.current.validateInput();
    let apiNameError = await apiNameRef.current.validateInput();
    let ownersError = await serviceOwnersRef.current.validateInput();

    if (nameError || apiNameError || ownersError) {
      hasError = true;
    }
    return hasError;
  };

  return (
    <Fragment>
      <Modal
        containerClassName="service-modal-container"
        contentClassName="service-modal-content"
        isOpen={props.isOpen}
        heading={'Edit ' + props.data.serviceName}
        dismissOnClickOutside={false}
        onRequestClose={() => close()}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => close()} />,
          <Button key="save" label="Save" onClick={() => onSave()} variant="brand" />,
        ]}
      >
        <>
          <ServiceNameInput
            id={props.data.id}
            name={serviceName}
            setName={setServiceName}
            apiName={apiName}
            setApiName={setApiName}
            populateApiName={populateApiName}
            setPopulateApiName={setPopulateApiName}
            services={services}
            ref={serviceNameRef}
          />
          <ApiInput
            id={props.data.id}
            apiName={apiName}
            setApiName={setApiName}
            populateApiName={populateApiName}
            setPopulateApiName={setPopulateApiName}
            services={services}
            ref={apiNameRef}
          />
          <UsersCombobox
            label={'Service Owners'}
            placeholder={'Search users in Alert Router...'}
            errorMessage={'At least one owner is required.'}
            preselectedUsers={props.data.serviceOwners}
            onSelect={setUsersComboboxInput}
            required={true}
            ref={serviceOwnersRef}
          />
          <CheckboxEx
            label={'Active'}
            checked={active}
            tooltip={'If active, alerts for this service will be processed. Otherwise, alerts will be dropped.'}
            disabled={props.data.status === 'MISSING_DEFAULT_NOTIFIERS' ? true : false}
            disabledText={
              'This service has no default notification channel set. At least one is required before a service can be active.'
            }
            onChange={setActive}
          />
          <ServiceCatalogIdInput serviceCatalogId={serviceCatalogId} setServiceCatalogId={setServiceCatalogId} />
        </>
      </Modal>
    </Fragment>
  );
};

ServicesEditModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  data: PropTypes.object,
  services: PropTypes.array,
};

export default ServicesEditModal;
