import React, { useContext, useEffect, useState } from 'react';
import { Button, IconSettings, PageHeader, PageHeaderControl } from '@salesforce/design-system-react';
import NoItemsCard from '../../components/NoItemsCard';
import './Rules.scss';
import NotificationRuleModal from './NotificationRuleModal';
import apiCalls from '../shared/api';
import { constants } from '../../constants';
import { ToastContext } from '../shared/toast-context';
import NotificationRulesTable from './NotificationRulesTable';
import { RefreshContext } from '../shared/refresh-context';
import RuleDeleteModal from './RuleDeleteModal';
import { parseFilter } from '../shared/utilities';

const NotificationRules = (props) => {
  const serviceOwners = props.serviceOwners;
  const serviceId = props.serviceId;

  const [loadingNotificationRules, setLoadingNotificationRules] = useState(false);
  const [sortProperties, setSortProperties] = useState({ sortField: 'label', sortDirection: 'asc' });
  const [notificationRules, setNotificationRules] = useState([]);
  const [groupingRules, setGroupingRules] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const refreshContext = useContext(RefreshContext);

  const setDeleteOpen = React.useCallback((a) => {
    setModalData({
      name: a.name,
      label: a.label,
      ruleId: a.id,
      serviceId: serviceId,
      createdBy: a.createdBy,
    });
    setMode(constants.DELETE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeleteClosed = async (deleteRule) => {
    if (deleteRule === constants.DELETE) {
      const response = await apiCalls.deleteRuleById(modalData.ruleId);
      if (response.status === 200 || response.status === 204) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Notification rule was deleted.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      }
      await fetchNotificationRules(-1);
    }
    setIsModalOpen(false);
  };

  const setEditOpen = React.useCallback((a) => {
    setModalData({
      label: a.label,
      name: a.name,
      ruleId: a.id,
      serviceId: serviceId,
    });
    setMode(constants.EDIT_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEditClosed = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const setOpenCreate = React.useCallback(() => {
    setModalData({
      label: '',
      name: '',
      ruleId: -1,
      serviceId: serviceId,
    });
    setMode(constants.CREATE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotificationRules = async (id) => {
    setLoadingNotificationRules(true);

    let response = [];
    try {
      // Get the Notification Rules
      if (id === -1) {
        response = await apiCalls.getRulesByServiceIdAndType(serviceId, false, 'ROUTING');
      } else {
        response = await apiCalls.getRuleById(id, false);
      }
      if (response.status !== 200) {
        // no rules returned
        setLoadingNotificationRules(false);
        return;
      }
    } catch {
      setLoadingNotificationRules(false);
      return;
    }

    // We want the results in an array, regardless of whether we got all the rules or just one.
    const rulesData = id === -1 ? response.data : [response.data];
    let notifierResponse;

    // Get the Notification Channels
    try {
      notifierResponse = await apiCalls.getNotifiersByServiceId(serviceId);
      if (notifierResponse.status !== 200) {
        // no notifiers returned
        setLoadingNotificationRules(false);
        return;
      }
    } catch {
      setLoadingNotificationRules(false);
      return;
    }

    // Get grouping rules if at least one of the routing rules references groupingRuleId in its rule filter
    let shouldGetGroupingRules = Object.entries(rulesData).some((datum) => {
      return (
        datum[0] !== 'error' &&
        datum[0] !== 'code' &&
        datum[0] !== 'errors' &&
        datum[1].filter.includes('groupingRuleId')
      );
    });
    if (shouldGetGroupingRules) {
      let groupingRulesList = [];
      try {
        const groupingRulesResponse = await apiCalls.getRulesByServiceIdAndType(serviceId, false, 'GROUPING');
        if (response.status === 200) {
          groupingRulesList = groupingRulesResponse.data.map((groupingRule) => ({
            id: groupingRule.id.toString(),
            label: groupingRule.label,
          }));
        }
        setGroupingRules(groupingRulesList);
      } catch {
        setLoadingNotificationRules(false);
        return;
      }
    }

    const notifiers = notifierResponse.data;
    let rulesList = [];

    // Transform API data to something better suited to DataTable.
    Object.entries(rulesData).forEach((datum) => {
      if (datum[0] !== 'error' && datum[0] !== 'code' && datum[0] !== 'errors') {
        const rule = {};
        rule.id = datum[1].id.toString();
        rule.name = datum[1].name;
        rule.label = datum[1].label;
        rule.modifiedBy = datum[1].modifiedBy;
        rule.modifiedDate = new Date(datum[1].modifiedDate).toLocaleString('en', {
          timeStyle: 'short',
          dateStyle: 'short',
        });
        rule.modifiedDateMs = datum[1].modifiedDate;
        rule.ruleConditions = parseFilter(datum[1].filter);
        rule.filter = datum[1].filter;
        rule.createdBy = datum[1].createdBy;

        // Find the notifier(s) for this rule in the list of all notifiers of this service
        const ruleNotifiers = datum[1].typeConfig.notifiers.map((n) => notifiers.find((nt) => nt.name === n));

        // Map the type and name of the found notifiers to the rule
        rule.notificationChannels = ruleNotifiers.map((n) => {
          return { type: n.type, typeLabel: n.label };
        });
        rule.notificationChannels.sort((x, y) => x.typeLabel.localeCompare(y.typeLabel, 'en', { sensitivity: 'case' }));
        if (id === -1) {
          rulesList.push(rule);
        } else {
          let newRulesList = [];
          let added = false;
          for (let i = 0; i < rulesList.length; i++) {
            if (rulesList[i].id === id) {
              newRulesList.push(rule);
              // Rule to refresh replaced in rules
              added = true;
            } else {
              newRulesList.push(rulesList[i]);
            }
          }
          if (!added) {
            // Rule to refresh not found in rules, adding
            newRulesList.push(rule);
          }
          rulesList = newRulesList;
        }
      }
    });

    // Now sort the data
    sortTable(rulesList, sortProperties.sortField, sortProperties.sortDirection);
    setLoadingNotificationRules(false);
  };

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      // Sort modified by date using the epoch time field instead of the human readable one
      let actualSortField = sortField;
      if (sortField === 'modifiedDate') {
        actualSortField = 'modifiedDateMs';
      }

      let result = 1;
      if (typeof x[actualSortField] === 'string') {
        result = x[actualSortField].localeCompare(y[actualSortField], 'en', { sensitivity: 'case' });
      } else if (x[actualSortField] < y[actualSortField]) {
        result = -1;
      } else if (x[actualSortField] === y[actualSortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });

    setNotificationRules(data);
  };

  const handleSort = React.useCallback(
    (sortColumn, ...rest) => {
      sortTable(notificationRules, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [notificationRules]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      label: 'Rule Name',
      ruleConditions: 'Rule Criteria',
      notificationChannels: 'Notification Channels',
      modifiedBy: 'Last Modified By',
      modifiedDate: 'Last Modified',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by&nbsp;
        {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  useEffect(() => {
    async function fetchNotificationRulesData() {
      await fetchNotificationRules(-1);
    }

    fetchNotificationRulesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, refreshContext.rules]);

  const foundItems = notificationRules.length;

  const actions = () => (
    <>
      <PageHeaderControl>
        <Button label="New Notification Rule" onClick={setOpenCreate} />
      </PageHeaderControl>
    </>
  );

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        {isModalOpen && mode !== constants.DELETE_MODE ? (
          <NotificationRuleModal
            isOpen={true}
            setClosed={setEditClosed}
            mode={mode}
            existingRuleNames={props.existingRuleNames}
            existingRuleLabels={props.existingRuleLabels}
            serviceId={serviceId}
            serviceName={props.serviceName}
            data={modalData}
          />
        ) : null}
        {isModalOpen && mode === constants.DELETE_MODE ? (
          <RuleDeleteModal
            type="Routing"
            serviceOwners={serviceOwners}
            data={modalData}
            onRequestCloseCallback={setDeleteClosed}
          />
        ) : null}
        {foundItems > 0 && !loadingNotificationRules ? (
          <>
            <PageHeader
              title="Notification Rules"
              info={getListInfo(foundItems)}
              joined
              variant="object-home"
              onRenderActions={actions}
            />
            <NotificationRulesTable
              onEdit={setEditOpen}
              onDelete={setDeleteOpen}
              notificationRules={notificationRules}
              sortField={sortProperties.sortField}
              sortDirection={sortProperties.sortDirection}
              handleSort={handleSort}
              groupingRules={groupingRules}
            />
            <div className="footer-bar"></div>
          </>
        ) : !loadingNotificationRules ? (
          <NoItemsCard
            itemName="Notification Rule"
            createDescription="Create a rule to specify what should cause the notification to trigger."
            onCreateClicked={setOpenCreate}
          />
        ) : null}
      </IconSettings>
    </div>
  );
};

export default NotificationRules;
