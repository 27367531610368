import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { uamStore, getToken } from '../modules/shared/store';
import apiCalls from '../modules/shared/api';

const WhoAmI = () => {
  const globalContext = useContext(uamStore);
  const dispatch = globalContext.dispatch;
  const token = getToken();

  useEffect(() => {
    // console.log('WhoAmI.useEffect: token: ', token);
    if (token) {
      // console.log('WhoAmI.useEffect: calling API');
      apiCalls
        .whoAmI()
        .then((response) => {
          // console.log('WhoAmI.useEffect: API response: ', response);
          dispatch({ type: 'SET_USER_DATA', value: response.data });
        })
        .catch((reason) => {
          // WhoAmIAPI failed
          // console.log('WhoAmI.useEffect: API failed: ', reason);
        });
    }
  }, [token, dispatch]);

  if (!token) {
    // No token set, redirecting to auth with a return to /login
    const redirectTarget = process.env.REACT_APP_URL + 'auth?RelayState=' + window.location.origin + '/login';
    // console.log('WhoAmI: no token, redirect to auth: ', redirectTarget);
    window.location.assign(redirectTarget);
    return <div />;
  } else if (!globalContext.userName) {
    // Token is set, username is NOT set, wait for effect
    // console.log('WhoAmI: token set, username is not.');
    return <div />;
  } else {
    // Token is set, username is set, redirect to /services
    // console.log('WhoAmI: token set, username set, redirect to /services');
    return <Redirect To="/services" />;
  }
};

export default WhoAmI;
