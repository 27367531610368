import React from 'react';
import PropTypes from 'prop-types';
import noItemsImage from '../images/emptyLandscapeImage.svg';

const NoItemsCard = ({ itemName, createDescription, onCreateClicked }) => {
  const noneOfThose = `No ${itemName}s`;
  const newOneOfThose = `New ${itemName}`;
  return (
    <article className="slds-card empty-card">
      <div className="slds-card__body">
        <div>
          <img alt={noneOfThose} className="empty-card-image" src={noItemsImage}></img>
          <p className="slds-text-heading_medium slds-m-top_small">{noneOfThose}</p>
          <h3 className="slds-m-top_small empty-card-message">{createDescription}</h3>
          <div className="slds-no-flex slds-m-top_medium slds-m-bottom_large">
            <button className="slds-button slds-button_brand" onClick={() => onCreateClicked()}>
              {newOneOfThose}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

NoItemsCard.propTypes = {
  itemName: PropTypes.string,
  createDescription: PropTypes.string,
  onCreateClicked: PropTypes.func,
};

export default NoItemsCard;
