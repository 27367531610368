import React, { Fragment, useState, useEffect } from 'react';
import { Button, PageHeader, PageHeaderControl, IconSettings, Spinner } from '@salesforce/design-system-react';
import apiCalls from '../../shared/api';
import AlertsList from './AlertsList';
import NoContentCard from '../../../components/NoContentCard';
import moment from 'moment';
import { constants } from '../../../constants';
import './Alerts.scss';

const Alerts = (props) => {
  const serviceId = props.serviceId;

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const [sortProperties, setSortProperties] = useState({ sortField: 'modifiedDate', sortDirection: 'desc' });
  const [refresh, setRefresh] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiCalls.getAlertsByServiceId(serviceId);
      const data = response.data;
      if (response.status === 200) {
        const alertsList = [];
        Object.entries(data).forEach((datum) => {
          const alert = {};
          alert.component = datum[1].component;
          alert.custom = datum[1].custom;
          alert.description = datum[1].description;
          alert.knowledgeArticleId = datum[1].knowledgeArticleId;
          alert.modifiedDateMs = datum[1].modifiedDateMs;
          alert.modifiedDate = moment(datum[1].modifiedDateMs).format(constants.DATE_TIME_FORMAT);
          alert.modifiedDateUTC = moment(datum[1].modifiedDateMs).utc().format(constants.DATE_TIME_FORMAT);
          alert.fromNow = new moment(datum[1].modifiedDateMs).fromNow();
          alert.name = datum[1].name;
          alert.requestId = datum[1].requestId;
          alert.service = datum[1].service;
          alert.serviceId = datum[1].serviceId;
          alert.severity = datum[1].severity;
          alert.source = datum[1].source;
          alert.sourceAlertId = datum[1].sourceAlertId;
          alert.status = datum[1].status;
          alert.visitedServices = datum[1].visitedServices;
          alertsList.push(alert);
        });
        sortTable(alertsList, sortProperties.sortField, sortProperties.sortDirection);
      }
      setTimeout(() => {
        setShowSpinner(false);
        setIsDataLoaded(true);
      }, 300);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      // Sort modified by date using the epoch time field instead of the human readable one
      let actualSortField = sortField;
      if (sortField === 'modifiedDate') {
        actualSortField = 'modifiedDateMs';
      }

      let result = 1;
      if (typeof x[actualSortField] === 'string') {
        result = x[actualSortField].localeCompare(y[actualSortField], 'en', { sensitivity: 'case' });
      } else if (x[actualSortField] < y[actualSortField]) {
        result = -1;
      } else if (x[actualSortField] === y[actualSortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });
    setAlerts(data);
  };

  const handleSort = React.useCallback(
    (sortColumn, ...rest) => {
      sortTable(alerts, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [alerts]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      sourceAlertId: 'Source Alert ID',
      status: 'Status',
      severity: 'Severity',
      name: 'Name',
      description: 'Description',
      component: 'Component',
      source: 'Source',
      knowledgeArticleId: 'KB Article',
      modifiedDate: 'Last Modified',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  const controls = () => (
    <Fragment>
      {/* <PageHeaderControl>
        <Input
          align="right"
          iconLeft={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search"
          placeholder="Search this list..."
        />
      </PageHeaderControl> */}
      {showSpinner ? (
        <PageHeaderControl>
          <div className="alerts-spinner">
            <Spinner
              size="x-small"
              variant="brand"
              isInline
              hasContainer={false}
              assistiveText={{ label: 'Reloading...' }}
            />
            <span className="alerts-spinner-message">Reloading...</span>
          </div>
        </PageHeaderControl>
      ) : null}
      <PageHeaderControl>
        <Button
          assistiveText={{ icon: 'Refresh' }}
          iconCategory="utility"
          iconName="refresh"
          iconVariant="border-filled"
          variant="icon"
          disabled={showSpinner ? true : false}
          onClick={() => {
            setShowSpinner(true);
            setRefresh(!refresh);
          }}
        />
      </PageHeaderControl>
      {/* <PageHeaderControl>
        <Button
          assistiveText={{ icon: 'Filters' }}
          iconCategory="utility"
          iconName="filterList"
          iconVariant="border-filled"
          variant="icon"
        />
      </PageHeaderControl> */}
    </Fragment>
  );

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        {isDataLoaded && alerts.length === 0 ? (
          <NoContentCard title="No Alerts Received" description="Enjoy the quiet time." />
        ) : null}
        {isDataLoaded && alerts.length > 0 ? (
          <Fragment>
            <PageHeader
              title="Alerts Received"
              info={getListInfo(alerts.length)}
              onRenderControls={controls}
              variant="object-home"
              joined
            />
            <AlertsList
              history={props.history}
              alerts={alerts}
              sortField={sortProperties.sortField}
              sortDirection={sortProperties.sortDirection}
              handleSort={handleSort}
              refresh={refresh}
              setRefresh={setRefresh}
            />
            <div className="footer-bar"></div>
          </Fragment>
        ) : null}
      </IconSettings>
    </div>
  );
};

export default Alerts;
