import React, { useContext, useState } from 'react';
import { Fragment } from 'react';
import ConfirmModal from '../shared/Modal/ConfirmModal';
import { constants, errors } from '../../constants';
import { uamStore } from '../shared/store';
import PropTypes from 'prop-types';

const RuleDeleteModal = (props) => {
  const type = props.type;
  const label = props.data.label;
  const serviceOwners = props.serviceOwners;
  const createdBy = props.data.createdBy;

  // Delete Validation Variables
  const [hasPermissionToDelete, setHasPermissionToDelete] = useState(false);
  const [validated, setValidated] = useState(true);

  // ConfirmModal Variables
  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;
  const [deleteHeader, setDeleteHeader] = useState('');
  const [variant, setVariant] = useState('');

  const hasNoPermissionsContent = (
    <Fragment>
      <span>
        You do not have permission to delete the rule, <strong>{label}</strong>. Only the rule creator, service owners
        or an administrator are permitted to delete the rule.
      </span>
    </Fragment>
  );
  const deleteRuleContent = (
    <Fragment>
      Are you sure you want to delete the rule, <strong>{label}</strong>? This operation cannot be undone.
    </Fragment>
  );

  useState(() => {
    const checkHasPermissionToDelete = async () => {
      const isAdmin = globalState.userNameLowercase === constants.ADMIN;
      const isServiceOwner = serviceOwners.includes(globalState.userNameLowercase);
      const isCreator = globalState.userNameLowercase === createdBy.toLowerCase();
      if (isAdmin || isServiceOwner || isCreator) {
        setHasPermissionToDelete(true);
        return true;
      }
      return false;
    };

    const validateDelete = async () => {
      let hasPermsToDelete = await checkHasPermissionToDelete();
      if (hasPermsToDelete) {
        setDeleteHeader(`Delete ${type} Rule`);
        setVariant(constants.CONFIRM);
      } else {
        setDeleteHeader(errors.UNABLE_TO_DELETE);
        setVariant(constants.INFROM);
      }
      setValidated(true);
    };
    validateDelete();
  }, []);

  return (
    <Fragment>
      {validated ? (
        <ConfirmModal
          heading={deleteHeader}
          dismissOnClickOutside={false}
          onRequestCloseCallback={props.onRequestCloseCallback}
          deleteContent={deleteRuleContent}
          informContent={!hasPermissionToDelete ? hasNoPermissionsContent : <span />}
          variant={variant}
        />
      ) : null}
    </Fragment>
  );
};

RuleDeleteModal.propTypes = {
  type: PropTypes.string,
  serviceOwners: PropTypes.array,
  data: PropTypes.object,
  onRequestCloseCallback: PropTypes.func,
};

export default RuleDeleteModal;
