import './AlertDetailsModal.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Settings,
  ExpandableSection,
  DataTable,
  DataTableCell,
  DataTableColumn,
  Icon,
  Spinner,
} from '@salesforce/design-system-react';
import CenteredSpinner from '../../../shared/Spinner/CenteredSpinner';
import Item from '../../../shared/Form/Item';
import { constants, endpoints } from '../../../../constants';
import apiCalls from '../../../shared/api';
import moment from 'moment';
import { ToastContext } from '../../../shared/toast-context';

Settings.setAppElement('#root');

const AlertDetailsModal = (props) => {
  const sourceAlertId = props.data[constants.SOURCE_ALERT_ID];
  const serviceId = props.data[constants.SERVICE_ID];

  const visitedServices = props.data[constants.VISITED_SERVICES];
  const [visitedServicesData, setVisitedServicesData] = useState([]);
  const [fetchingVisitedServicesData, setFetchingVisitedServicesData] = useState(false);

  const [displayCustomFields, setDisplayCustomFields] = useState(false);
  const [filteredCustomFields, setFilteredCustomFields] = useState({});
  const [events, setEvents] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [isEventsSectionOpen, setIsEventsSectionOpen] = useState(false);

  // Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  useEffect(() => {
    if (props.data.custom) {
      let customFieldsFiltered = props.data.custom;
      delete customFieldsFiltered.routedViaAlertRouter;
      setFilteredCustomFields(customFieldsFiltered);

      if (Object.keys(customFieldsFiltered).length === 0) {
        setDisplayCustomFields(false);
      } else {
        setDisplayCustomFields(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const locale = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

  const STATUS_ENUMS = {
    ACTIVE: 'Active',
    ACKNOWLEDGE: 'Acknowledged',
    CLEAR: 'Cleared',
  };

  const close = () => {
    props.history.replace({
      search: '?' + new URLSearchParams({ tab: 0 }).toString(),
    });
    props.setIsModalOpen(false);
  };

  const handleCopy = () => {
    let copiedPayload = { ...props.data };
    if (props.data.custom) {
      delete copiedPayload.custom.routedViaAlertRouter;
      if (Object.keys(copiedPayload.custom).length === 0) {
        delete copiedPayload.custom;
      }
    }
    delete copiedPayload.modifiedDate;
    delete copiedPayload.modifiedDateMs;
    delete copiedPayload.modifiedDateUTC;
    delete copiedPayload.fromNow;
    delete copiedPayload.serviceId;
    delete copiedPayload.requestId;
    delete copiedPayload.visitedServices;
    navigator.clipboard.writeText(JSON.stringify(copiedPayload, null, 4));
    toastDispatch({
      type: 'SET_TOAST_STATE',
      value: {
        ...toastState,
        toastMessage: 'Alert payload copied.',
        toastVariant: 'success',
        showToast: true,
      },
    });
  };

  // Get the service labels of the visitedServices by serviceId
  useEffect(() => {
    const getVisitedServices = () => {
      if (visitedServices !== undefined) {
        setFetchingVisitedServicesData(true);
        let visitedServicesData = visitedServices.map((serviceId) => ({
          id: serviceId,
          label: '',
        }));
        visitedServicesData.push({ id: serviceId, label: '' });
        Promise.allSettled(
          visitedServicesData.map(async (obj) => {
            try {
              const serviceResponse = await apiCalls.getServiceById(obj.id);
              if (serviceResponse.status === 200 && serviceResponse.data) {
                visitedServicesData = visitedServicesData.map((x) =>
                  x.id === obj.id ? { ...x, label: serviceResponse.data.label } : x
                );
              }
            } catch (ex) {
              console.log(`Error fetching name for service with id ${obj.id}:`, ex);
            }
          })
        ).then(() => {
          setVisitedServicesData(visitedServicesData);
          setFetchingVisitedServicesData(false);
        });
      }
    };
    getVisitedServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedServices]);

  const DetailsSection = (
    <ExpandableSection id="details-section" title="Alert Details">
      <div className="slds-form alert-details-form" role="list">
        <div className="slds-form__row">
          <Item label="Status">
            <div
              className={
                props.data[constants.STATUS] === 'ACTIVE'
                  ? 'status-active'
                  : props.data[constants.STATUS] === 'ACKNOWLEDGE'
                  ? 'status-acknowledge'
                  : 'status-clear'
              }
            >
              {STATUS_ENUMS[props.data[constants.STATUS]]}
            </div>
          </Item>
          <Item label="Severity">{props.data[constants.SEVERITY]}</Item>
        </div>

        <div className="slds-form__row">
          <Item label="Name" layout="standalone">
            {props.data[constants.NAME]}
          </Item>
        </div>

        <div className="slds-form__row">
          <Item label="Description" layout="standalone">
            <div className="alert-details-description">{props.data[constants.DESCRIPTION]}</div>
          </Item>
        </div>

        <div className="slds-form__row">
          <Item label="Component">{props.data[constants.COMPONENT]}</Item>
          <Item label="Source Alert ID">{props.data[constants.SOURCE_ALERT_ID]}</Item>
        </div>

        <div className="slds-form__row">
          <Item label="Source">{props.data[constants.SOURCE]}</Item>
          <Item label="Service">{props.data[constants.SERVICE]}</Item>
        </div>

        <div className="slds-form__row">
          <Item label="KB Article">
            <a
              href={`${endpoints.GUS_ENDPOINT}${props.data[constants.KNOWLEDGE_ARTICLE_ID]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.data[constants.KNOWLEDGE_ARTICLE_ID]}
            </a>
          </Item>
          <Item label="Last Modified">
            <div
              title={
                props.data[constants.MODIFIED_DATE] + ' (' + locale + ')\n' + props.data['modifiedDateUTC'] + ' (UTC)'
              }
            >
              {props.data[constants.FROM_NOW]}
            </div>
          </Item>
        </div>

        <div className="slds-form__row">
          <Item label="Request ID" layout="standalone">
            {props.data[constants.REQUEST_ID]}
          </Item>
        </div>

        <div className="slds-form__row">
          <Item label="Visited Services" layout="standalone">
            {fetchingVisitedServicesData ? (
              <Spinner className="slds-form__row-spinner" size="x-small" isInline={true} hasContainer={true} />
            ) : (
              visitedServicesData.map((obj, index) => {
                return (
                  <span>
                    <a
                      href={`/services/${obj.id}?${new URLSearchParams({
                        serviceId: obj.id,
                        sourceAlertId: sourceAlertId,
                      }).toString()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ paddingRight: '4px' }}
                    >
                      {obj.label}
                    </a>
                    {index < visitedServicesData.length - 1 ? (
                      <Icon category="utility" name="forward" size="x-small" style={{ paddingRight: '4px' }} />
                    ) : null}
                  </span>
                );
              })
            )}
          </Item>
        </div>
      </div>
    </ExpandableSection>
  );

  const buildCustomSection = () => {
    let entries = Object.entries(filteredCustomFields);
    entries.sort((x, y) => {
      let result = x[0].localeCompare(y[0], 'en', { sensitivity: 'case' });
      return result;
    });
    let rows = [];
    for (var i = 0; i < entries.length; i += 2) {
      rows.push(
        <div className="slds-form__row" key={i}>
          <Item label={entries[i][0]}>{entries[i][1].toString()}</Item>
          {entries[i + 1] === undefined ? (
            <div className="slds-form__item" role="listitem"></div>
          ) : (
            <Item label={entries[i + 1][0]}>{entries[i + 1][1].toString()}</Item>
          )}
        </div>
      );
    }
    return rows;
  };

  const CustomDetailsSection = (
    <ExpandableSection id="custom-details-section" title="Custom Details">
      <div className="slds-form alert-details-form" role="list">
        {buildCustomSection()}
      </div>
    </ExpandableSection>
  );

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      // Sort modified by date using the epoch time field instead of the human readable one
      let result = 1;
      if (typeof x[sortField] === 'string') {
        result = x[sortField].localeCompare(y[sortField], 'en', { sensitivity: 'case' });
      } else if (x[sortField] < y[sortField]) {
        result = -1;
      } else if (x[sortField] === y[sortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });
    setEvents(data);
  };

  useEffect(() => {
    const getEvents = async () => {
      setFetchingEvents(true);
      try {
        const eventsResponse = await apiCalls.getEventsByServiceIdAndSourceAlertId(serviceId, sourceAlertId);
        if (eventsResponse.status === 200 && eventsResponse.data && eventsResponse.data.length > 0) {
          let eventsData = [];
          Object.entries(eventsResponse.data).forEach((datum) => {
            eventsData.push({
              message: datum[1].message,
              recordId: datum[1].recordId,
              timestampMs: datum[1].timestampMs,
              timestampDate: moment(datum[1].timestampMs).format(constants.DATE_TIME_FORMAT),
              timestampDateUTC: moment(datum[1].timestampMs).utc().format(constants.DATE_TIME_FORMAT),
              fromNow: new moment(datum[1].timestampMs).fromNow(),
            });
          });
          sortTable(eventsData, 'timestampMs', 'desc');
        }
      } catch (ex) {
        console.log('ERROR:', ex);
      } finally {
        setFetchingEvents(false);
      }
    };
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, sourceAlertId]);

  const TimestampDataTableCell = ({ children, ...props }) => {
    const locale = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    return (
      <DataTableCell
        {...props}
        title={props.item.timestampDate + ' (' + locale + ')\n' + props.item.timestampDateUTC + ' (UTC)'}
      >
        {props.item.fromNow}
      </DataTableCell>
    );
  };
  TimestampDataTableCell.displayName = DataTableCell.displayName;

  const EventsSection = (
    <ExpandableSection
      id="events-section"
      title="Events"
      isOpen={isEventsSectionOpen}
      onToggleOpen={() => {
        setIsEventsSectionOpen(!isEventsSectionOpen);
      }}
    >
      {fetchingEvents ? (
        <CenteredSpinner variant="brand" size="medium" />
      ) : (
        <>
          {events.length === 0 ? (
            <div id="no-events" className="no-events">
              No items to display.
            </div>
          ) : (
            <DataTable id="events-table" className="events-list" fixedHeader fixedLayout items={events}>
              <DataTableColumn label="Message" primaryColumn property="message" width="60%" />
              <DataTableColumn label="Record ID" property="recordId" width="20%" />
              <DataTableColumn label="Event Date" property="fromNow" width="20%">
                <TimestampDataTableCell />
              </DataTableColumn>
            </DataTable>
          )}
        </>
      )}
    </ExpandableSection>
  );

  return (
    <>
      <Modal
        containerClassName="alert-details-container"
        contentClassName="alert-details-content"
        isOpen={true}
        heading={<div className="slds-truncate">Alert: {props.data[constants.NAME]}</div>}
        dismissOnClickOutside={false}
        onRequestClose={() => close()}
        footer={
          <div>
            <Button onClick={() => handleCopy()}>Copy as JSON</Button>
            <Button key="done" label="Done" onClick={() => close()} variant="brand" />
          </div>
        }
      >
        {DetailsSection}
        {displayCustomFields ? CustomDetailsSection : null}
        {EventsSection}
      </Modal>
    </>
  );
};

export default AlertDetailsModal;
