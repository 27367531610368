import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IconSettings } from '@salesforce/design-system-react';

import { StateProvider } from './modules/shared/store.js';
import { ToastContextProvider } from './modules/shared/toast-context';
import { RefreshContextProvider } from './modules/shared/refresh-context';

ReactDOM.render(
  <StateProvider>
    <ToastContextProvider>
      <RefreshContextProvider>
        <IconSettings iconPath="/assets/icons">
          <App />
        </IconSettings>
      </RefreshContextProvider>
    </ToastContextProvider>
  </StateProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
