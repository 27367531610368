import { Datepicker, Input } from '@salesforce/design-system-react';
import { getTimeStrFromMs, getDateAndTimeInMs } from '../shared/utilities';
import React, { useEffect, useState } from 'react';
import { errors } from '../../constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import './DateTimePicker.scss';

const DateTimePicker = (props) => {
  const [date, setDate] = useState(new Date(props.dateMs));
  const [timeStr, setTimeStr] = useState(getTimeStrFromMs(props.dateMs));
  const [errorText, setErrorText] = useState('');
  const [utcDateTime, setUtcDateTime] = useState('');

  useEffect(() => {
    if (validateDate(date) && validateTime(timeStr)) {
      setUtcDateTime(moment(getDateAndTimeInMs(date, timeStr)).utc().format('M/D/YYYY h:mm A') + ' UTC');
    } else {
      setUtcDateTime(errors.INVALID_DATE_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, timeStr]);

  const validateDate = (inputDate) => {
    let hasError = false;
    if (!(inputDate instanceof Date) || isNaN(inputDate)) {
      hasError = true;
    }
    props.hasDateError(hasError);
    return !hasError;
  };

  const validateTime = (inputStr) => {
    let hasError = false;
    if (inputStr === undefined || inputStr.trim().length === 0) {
      hasError = true;
      setErrorText(errors.REQUIRED);
    } else {
      setErrorText('');
    }
    props.hasTimeError(hasError);
    return !hasError;
  };

  return (
    <div className="suppression-rule-modal-datetimepicker">
      <Datepicker
        triggerClassName="suppression-rule-modal-datepicker"
        required
        labels={{
          label: props.dateLabel,
        }}
        onChange={(event, data) => {
          setDate(data.date);
          if (validateDate(data.date)) {
            props.setDate(data.date);
          }
        }}
        formatter={(date) => {
          return date ? moment(date).format('M/D/YYYY') : '';
        }}
        parser={(dateString) => {
          return moment(dateString, 'MM-DD-YYYY').toDate();
        }}
        value={date}
      />
      <Input
        className="suppression-rule-modal-timeinput"
        required
        label="Time (Local)"
        errorText={errorText}
        onChange={(event, data) => {
          setTimeStr(data.value);
          if (validateTime(data.value)) {
            props.setTime(data.value);
          }
        }}
        type="time"
        value={timeStr}
      />
      <span className="suppression-rule-modal-utctime">{utcDateTime}</span>
    </div>
  );
};

DateTimePicker.propTypes = {
  dateMs: PropTypes.number,
  label: PropTypes.string,
  setDate: PropTypes.func,
  hasDateError: PropTypes.func,
  setTime: PropTypes.func,
  hasTimeError: PropTypes.func,
};

export default DateTimePicker;
