import React, { useCallback } from 'react';
import {
  Tooltip,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
} from '@salesforce/design-system-react';
import { constants } from '../../constants';

const SuppressionRulesTable = (props) => {
  const suppressionRules = props.suppressionRules;
  const sortField = props.sortField;
  const sortDirection = props.sortDirection;

  const options =
    props.selectedRuleState === 'expired'
      ? [
          {
            id: 0,
            label: 'Clone',
            value: constants.CLONE_MODE,
          },
          {
            id: 1,
            label: 'Delete',
            value: constants.DELETE_MODE,
          },
        ]
      : [
          {
            id: 0,
            label: 'Edit',
            value: constants.EDIT_MODE,
          },
          {
            id: 1,
            label: 'Delete',
            value: constants.DELETE_MODE,
          },
        ];

  const onEdit = useCallback((data) => {
    props.onEdit(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback((data) => {
    props.onDelete(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClone = useCallback((data) => {
    props.onClone(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowAction = (item, action) => {
    switch (action.value) {
      case constants.EDIT_MODE:
        onEdit(item);
        break;
      case constants.CLONE_MODE:
        onClone(item);
        break;
      case constants.DELETE_MODE:
        onDelete(item);
        break;
      default:
    }
  };

  const createFilterContent = useCallback((filter) => {
    return (
      <div className="rules-display">
        <div className="rules-header">Rule Criteria</div>
        <div className="rules-display-row">
          <div className="rules-display-cell rules-low-text">Alert matches when:</div>
        </div>
        <div className="rules-display-row ">
          <div className="rules-display-cell rules-bold-text ">{filter}</div>
        </div>
      </div>
    );
  }, []);

  const LinkDataTableCell = ({ item, field }) => {
    if (field === 'ruleConditions') {
      return (
        <DataTableCell title={item.ruleConditions} key="ruleConditions">
          <span className="rules-cell-space-to-icon">{item.ruleConditions}</span>
          <Tooltip
            id="base"
            align="top left"
            content={createFilterContent(item.filter)}
            variant="learnMore"
            position="overflowBoundaryElement"
            dialogClassName="dialog-className rules-popup"
          />
        </DataTableCell>
      );
    }
    return null;
  };
  LinkDataTableCell.displayName = DataTableCell.displayName;

  return (
    <DataTable
      id="suppressionrules"
      className="suppression-rules"
      assistiveText={{
        actionsHeader: 'actions',
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
        selectAllRows: 'Select all rows',
        selectRow: 'Select this row',
      }}
      fixedHeader
      fixedLayout
      joined
      items={suppressionRules}
    >
      <DataTableColumn
        isSorted={sortField === 'label'}
        sortDirection={sortField === 'label' ? sortDirection : null}
        sortable
        width="22%"
        key="ruleName"
        label="Rule Name"
        primaryColumn
        property="label"
        onSort={props.handleSort}
      />

      <DataTableColumn
        key="ruleCriteria"
        label="Rule Criteria"
        property="ruleConditions"
        width="11%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'ruleConditions'}
        sortDirection={sortField === 'ruleConditions' ? sortDirection : null}
      >
        <LinkDataTableCell field="ruleConditions" property="ruleConditions" />
      </DataTableColumn>

      <DataTableColumn
        width="22%"
        key="reason"
        label="Reason"
        property="reason"
        sortable
        onSort={props.handleSort}
        isSorted={sortField === 'reason'}
        sortDirection={sortField === 'reason' ? sortDirection : null}
      />

      <DataTableColumn
        key="startTime"
        label="Start Time"
        property="startDate"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'startDate'}
        sortDirection={sortField === 'startDate' ? sortDirection : null}
      />

      <DataTableColumn
        key="endTime"
        label="End Time"
        property="endDate"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'endDate'}
        sortDirection={sortField === 'endDate' ? sortDirection : null}
      />

      <DataTableColumn
        key="lastModifiedBy"
        label="Last Modified By"
        property="modifiedBy"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedBy'}
        sortDirection={sortField === 'modifiedBy' ? sortDirection : null}
      />

      <DataTableColumn
        key="lastModified"
        label="Last Modified"
        property="modifiedDate"
        width="15%"
        onSort={props.handleSort}
        sortable
        isSorted={sortField === 'modifiedDate'}
        sortDirection={sortField === 'modifiedDate' ? sortDirection : null}
      />

      <DataTableRowActions
        className="alerts-list-actions"
        options={options}
        menuPosition="overflowBoundaryElement"
        onAction={handleRowAction}
        dropdown={<Dropdown length="5" />}
      />
    </DataTable>
  );
};

export default SuppressionRulesTable;
