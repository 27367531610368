import React from 'react';
import PropTypes from 'prop-types';
import {
  GlobalNavigationBar,
  GlobalNavigationBarRegion,
  GlobalNavigationBarLink,
  Icon,
  Button,
  Popover,
} from '@salesforce/design-system-react';
import { useLocation } from 'react-router-dom';

import './Header.scss';
import logo from '../images/logo.svg';
import UserAvatar from './UserAvatar';
import UserProfilePopover from './UserProfilePopover';

const Header = (props) => {
  const { userName, userEmail } = props;
  let { pathname } = useLocation();
  if (pathname === '/') pathname += 'services';

  return (
    <GlobalNavigationBar className="slds-p-horizontal_medium app-header">
      <GlobalNavigationBarRegion region="primary">
        <a href="/" className="slds-context-bar__item slds-no-hover slds-text-link--reset">
          <div className="slds-context-bar__icon-action">
            <div className="slds-button slds-context-bar__button app-logo" title="Alert Router">
              <img src={logo} alt="Alert Router Home Page" />
            </div>
          </div>
          <span className="slds-context-bar__label-action slds-context-bar__app-name slds-text-heading_small">
            <span className="slds-truncate app-title" title="Alert Router">
              Alert Router
            </span>
          </span>
        </a>
      </GlobalNavigationBarRegion>
      <GlobalNavigationBarRegion region="secondary">
        <GlobalNavigationBarLink label="Services" active={pathname.includes('services')} href="/services" />
      </GlobalNavigationBarRegion>
      <GlobalNavigationBarRegion region="tertiary">
        <div className="slds-grid slds-gutters_xx-small slds-grid_vertical-align-center">
          <a
            className="slds-col slds-button slds-button_icon"
            href="https://salesforce.quip.com/GntNAaGUa6EP"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              assistiveText={{ label: 'help link' }}
              category="utility"
              colorVariant="light"
              name="help"
              size="small"
            />
          </a>
          <Popover
            align="bottom right"
            hasNoCloseButton
            body={<UserProfilePopover userName={userName} userEmail={userEmail} />}
          >
            <Button className="slds-col" variant="base">
              <UserAvatar userName={userName} />
            </Button>
          </Popover>
        </div>
      </GlobalNavigationBarRegion>
    </GlobalNavigationBar>
  );
};

Header.propTypes = {
  userName: PropTypes.string,
};

export default Header;
