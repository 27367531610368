import React, { createContext, useReducer } from 'react';
import { constants } from '../../constants';

const initialState = {
  userName: '',
  userEmail: '',
  userNameLowercase: '',
  error: '',
};

const uamStore = createContext(initialState);
const { Provider } = uamStore;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_ERROR': {
        const newState = { ...state, ...action.value };
        return newState;
      }
      case 'SET_USER_DATA': {
        const newState = {
          ...state,
          userName: action.value.username,
          userEmail: action.value.email,
          userNameLowercase: action.value.username.toLowerCase(),
        };
        return newState;
      }
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const getToken = () => {
  return localStorage.getItem(constants.TOKEN);
};

const setToken = (token) => {
  localStorage.setItem(constants.TOKEN, token);
};

const deleteToken = () => {
  localStorage.removeItem(constants.TOKEN);
};

export { uamStore, StateProvider, getToken, setToken, deleteToken };
