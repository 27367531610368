import { Button, ExpandableSection, Input, Modal, Settings } from '@salesforce/design-system-react';
import React, { useContext, useEffect, useRef, useState, Fragment } from 'react';
import { constants, errors, excludedFieldLabels } from '../../constants';
import DateTimePicker from './DateTimePicker';
import PropTypes from 'prop-types';
import apiCalls from '../shared/api';
import {
  getDateAndTimeInMs,
  getTimeStrFromMs,
  getNameFromLabel,
  getFieldLabel,
  getFieldInfo,
  getValidOperators,
  getUIDate,
} from '../shared/utilities';
import { ToastContext } from '../shared/toast-context';
import { RefreshContext } from '../shared/refresh-context';
import RuleExpressionBuilder from './RuleExpressionBuilder';
import './SuppressionRuleModal.scss';

Settings.setAppElement('#root');

const useDateTime = (dateTimeMs) => {
  const [date, setDate] = useState(new Date(dateTimeMs));
  const [timeStr, setTimeStr] = useState(getTimeStrFromMs(dateTimeMs));

  return [getDateAndTimeInMs(date, timeStr), setDate, setTimeStr];
};

const SuppressionRuleModal = (props) => {
  const existingRuleLabels = props.existingRuleLabels;
  const existingRuleNames = props.existingRuleNames;
  const mode = props.mode;
  const ruleId = props.data.ruleId;
  const serviceId = props.serviceId;
  const serviceName = props.serviceName;

  const labelRef = useRef();
  const ruleBuilderRef = useRef();

  // Input Variables
  const [label, setLabel] = useState(props.data.label);
  const [ruleLabelError, setRuleLabelError] = useState('');
  const [name, setName] = useState(props.data.name);
  const [conditions, setConditions] = useState([]);
  const [resourceFields, setResourceFields] = useState([]);
  const [displayResourceFields, setDisplayResourceFields] = useState([]);
  const [triggerType, setTriggerType] = useState('all');
  const [customLogic, setCustomLogic] = useState('');
  const [currentTimer, setCurrentTimer] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [dataIsHere, setDataIsHere] = useState(false);
  const [ruleIsHere, setRuleIsHere] = useState(false);
  const [reason, setReason] = useState(props.data.reason);
  const [ruleReasonError, setRuleReasonError] = useState('');
  const [endDate, setEndDate, setEndTime] = useDateTime(props.data.endDateMs);
  const [hasEndDateError, setHasEndDateError] = useState(false);
  const [hasEndTimeError, setHasEndTimeError] = useState(false);
  const [startDate, setStartDate, setStartTime] = useDateTime(props.data.startDateMs);
  const [hasStartDateError, setHasStartDateError] = useState(false);
  const [hasStartTimeError, setHasStartTimeError] = useState(false);
  const [dateTimeErrorText, setDateTimeErrorText] = useState('');

  const modeText = (mode === constants.EDIT_MODE ? 'Edit' : 'New') + ' Suppression Rule';

  // Refresh Context
  const refreshContext = useContext(RefreshContext);

  // Rules Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  // No idea why Lint complains about useCallback here....
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const addCondition = () => {
    const newCondition = {
      isGroup: false,
      resource: '',
      operator: '',
      value: '',
    };
    setConditions([...conditions, newCondition]);
  };

  useEffect(() => {
    if (currentTimer && timerExpired && ruleIsHere) {
      // Reached timeout, hide spinner
      setShowLoading(false);
    }
  }, [currentTimer, ruleIsHere, timerExpired]);

  // The general useEffect that loads all the data for this rule along with fields.
  useEffect(() => {
    const fetchRule = async (id) => {
      try {
        const response = await apiCalls.getRuleById(ruleId, true);
        const data = response.data;

        if (response.status === 200) {
          // if the rule is being cloned, use the data passed in via modalData
          // else, use the data retrieved from the api call
          if (mode === constants.CLONE_MODE) {
            setName(props.data.name);
            setReason(props.data.reason);
          } else {
            setName(data.name.trim());
            setReason(data.reason.trim());
          }

          // Set the Trigger type
          setTriggerType(data.isCustom ? 'custom' : data.tree.logicalExpression.indexOf(' AND ') > 0 ? 'all' : 'any');

          // Set the expression string for custom logic
          setCustomLogic(data.tree.logicalExpression);

          const NUM_RULE_CONDITIONS_THRESHOLD = 30;
          const LOADING_RULES_SPINNER_DURATION = 1200;

          if (data.tree.expressionList.length > NUM_RULE_CONDITIONS_THRESHOLD) {
            // Too many conditions, showing Spinner, and start a timer that will hide the spinner
            // in 1.2s (will be blocked until initial render is complete)
            setCurrentTimer(setTimeout(() => setTimerExpired(true), LOADING_RULES_SPINNER_DURATION));
            setShowLoading(true);
          }

          // Build the condition lines from the conditions tree
          const fields = [...resourceFields];
          const displayFields = [...displayResourceFields];

          const newConditions = data.tree.expressionList.map((expr) => {
            let field = getFieldInfo(resourceFields, expr.operand1);
            if (!field) {
              if (expr.operand1.toLowerCase().startsWith(constants.CUSTOM_PREFIX)) {
                const fieldLabel = getFieldLabel(expr.operand1);
                field = {
                  id: constants.CUSTOM_PREFIX + expr.operand1.substring(7),
                  label: fieldLabel,
                  type: { name: 'custom' }, // Custom fields support all operators
                };
                fields.push(field);
                displayFields.push({ id: field.id, label: field.label });
                resourceFields.push(field);
              }
            } else if (field.type.name === 'enum') {
              const value = field.valuesList.find((v) => v.id.toLowerCase() === val.toLowerCase());
              if (value) {
                field.valueSelected = { id: value.id, label: value.label };
              } else {
                field.valueSelected = { id: 'none', label: '' };
              }
            }

            const allowedOperators = getValidOperators(field);
            const operator = allowedOperators.find((op) => (op.sqlOp ? op.sqlOp : op.id) === expr.operator);
            let val = expr.operand2;
            if (field.type.name.toLowerCase() === 'date') {
              let dtVal = new Date(Number(val));
              val = getUIDate(dtVal);
            }
            const newCondition = {
              isGroup: false,
              resource: expr.operand1.toLowerCase().startsWith(constants.CUSTOM_PREFIX)
                ? constants.CUSTOM_PREFIX + expr.operand1.substring(7)
                : expr.operand1,
              operator: operator,
              value: val,
              valuesList: field.valueSelected && field.valueSelected.id === 'none' ? undefined : field.valuesList, // Determines whether to show the Combobox or Input box
              valueSelected: field.valueSelected,
            };
            return newCondition;
          });

          setResourceFields(fields);
          setDisplayResourceFields(displayFields);
          setConditions(newConditions);
          setRuleIsHere(true);
        }
      } catch (ex) {
        console.log('ERROR getting Rule ' + id + ': ', ex);
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: ex.message,
            toastVariant: 'error',
            showToast: true,
            errorHeading: 'An unexpected error was encountered while processing the rule.',
          },
        });
      }
    };

    // Call the API to retrieve the list of fields in a rule, the notifiers that the rules can use and the rule itself.
    const fetchDataFields = async () => {
      // First, get the available fields
      try {
        const response = await apiCalls.getAlertFields();
        const data = response.data;
        if (response.status === 200) {
          const fields = [];
          const displayFields = [];
          for (let i = 0; i < data.length; i++) {
            if (!excludedFieldLabels.includes(data[i].fieldName) && data[i].fieldName !== 'groupingRuleId') {
              const fieldLabel = getFieldLabel(data[i].fieldName);
              // if data[i].type.name is enum, populate valuesList with data[i].type.values
              if (data[i].type.name === 'enum') {
                const enumValuesList = [];
                for (let j = 0; j < data[i].type.values.length; j++) {
                  enumValuesList.push({ id: data[i].type.values[j].toLowerCase(), label: data[i].type.values[j] });
                }
                fields.push({
                  id: data[i].fieldName,
                  label: fieldLabel,
                  type: data[i].type,
                  valuesList: enumValuesList,
                });
                displayFields.push({ id: data[i].fieldName, label: fieldLabel });
              } else {
                // else, do original flow
                fields.push({
                  id: data[i].fieldName,
                  label: fieldLabel,
                  type: data[i].type,
                });
                if (data[i].fieldName !== 'custom') {
                  displayFields.push({ id: data[i].fieldName, label: fieldLabel });
                }
              }
            }
          }
          displayFields.sort((x, y) => x.label.localeCompare(y.label));
          setResourceFields(fields);
          setDisplayResourceFields(displayFields);

          setDataIsHere(true);
        }
      } catch {
        console.log('ERROR getting Fields');
      }
    };

    // Have we retrieved the field data?
    if (!dataIsHere) {
      // No, so get it
      fetchDataFields();
    } else if (!ruleIsHere) {
      // Yes, we got the fields, but not the rule, so ....
      if (mode === constants.EDIT_MODE || mode === constants.CLONE_MODE) {
        // ... in edit/clone mode get the rule
        fetchRule(ruleId);
      } else {
        // ... but in create mode, add a default (empty) condition
        addCondition();
        setRuleIsHere(true);
      }
    } else {
      // Everything is here, so set focus to the rule name field.
      labelRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIsHere, ruleIsHere, labelRef]);

  // Simple validation for start and end date times
  const validateDateTimes = () => {
    let hasError = false;
    if (hasDateTimeErrors()) {
      hasError = true;
    } else if (endDate < Date.now()) {
      setDateTimeErrorText(errors.INVALID_END_DATE_IN_PAST);
      hasError = true;
    } else if (endDate < startDate) {
      setDateTimeErrorText(errors.INVALID_END_DATE_BEFORE_START_DATE);
      hasError = true;
    } else {
      setDateTimeErrorText('');
    }
    return !hasError;
  };

  // Validate that all the rule conditions are set correctly
  const validateRuleFilter = () => {
    let ruleBuilderHasError = ruleBuilderRef.current.validateInput();
    return !ruleBuilderHasError;
  };

  const validateReason = (newReason) => {
    let hasError = false;
    if (newReason.trim().length === 0) {
      setRuleReasonError(errors.REQUIRED);
      hasError = true;
    } else if (newReason.trim().length > constants.LONG_TEXT_CHAR_LIMIT) {
      setRuleReasonError(errors.CHAR_LIMIT_LONG);
      hasError = true;
    } else {
      setRuleReasonError('');
    }
    return hasError;
  };

  // Make sure the rule name is specified, and is not already present in the service.
  const validateLabel = (newLabel) => {
    let hasError = false;
    if (newLabel.trim().length === 0) {
      setRuleLabelError(errors.REQUIRED);
      hasError = true;
    } else if (newLabel.trim().length > constants.TEXT_CHAR_LIMIT) {
      setRuleLabelError(errors.CHAR_LIMIT);
      hasError = true;
    } else if (newLabel.trim() !== props.data.label) {
      // If it's changed, check the other existing rules for this service.
      if (existingRuleLabels.find((ern) => ern.localeCompare(newLabel.trim()) === 0)) {
        setRuleLabelError(errors.NON_UNIQUE_NAME);
        hasError = true;
      } else {
        setRuleLabelError('');
      }
    }
    return hasError;
  };

  // Callback from the Input field when editing the rule label (live error checking)
  const changeLabel = (newLabel) => {
    validateLabel(newLabel);
    setLabel(newLabel);

    // auto-gen name field on create, append suffix if collision
    if (mode === constants.CREATE_MODE || mode === constants.CLONE_MODE) {
      let autoGeneratedName = getNameFromLabel('rule-', newLabel, existingRuleNames);
      setName(autoGeneratedName);
    }
  };

  const changeReason = (newReason) => {
    validateReason(newReason);
    setReason(newReason);
  };

  const hasDateTimeErrors = () => {
    return hasStartDateError || hasStartTimeError || hasEndDateError || hasEndTimeError;
  };

  const shouldDisableSave = () => {
    return hasDateTimeErrors() || ruleReasonError.trim().length > 0 || ruleLabelError.trim().length > 0;
  };

  // Save the state of the rule to the API
  const sendSaveRequest = async () => {
    let payload = {
      label: label.trim(),
      name: name.trim(),
      service: serviceName,
      serviceId: serviceId,
      type: 'SUPPRESSION',
      filter: ruleBuilderRef.current.getFilter(),
      isCustom: triggerType === 'custom',
      startDate: startDate,
      endDate: endDate,
      reason: reason,
    };
    if (mode === constants.EDIT_MODE) {
      payload = { ...payload, id: Number(ruleId) };
    }

    const response = await apiCalls.createOrUpdateRuleForService(mode, payload);

    if (response.status === 200 || response.status === 201) {
      if (mode === constants.CREATE_MODE || mode === constants.CLONE_MODE) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Suppression rule was created.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      } else if (mode === constants.EDIT_MODE) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Suppression rule was saved.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      }
      refreshContext.refreshSuppressionRules();
    }
  };

  // Attempt to save the rule after validating all input.
  // Only saves if all validation succeeds
  const save = async () => {
    let hasError = validateLabel(label);

    if (validateReason(reason)) {
      hasError = true;
    }

    if (!validateRuleFilter()) {
      hasError = true;
    }

    if (!validateDateTimes()) {
      hasError = true;
    }

    if (!hasError) {
      await sendSaveRequest();
      close();
    }
  };

  // Close the modal
  const close = () => {
    props.setClosed();
  };

  return (
    <Fragment>
      <Modal
        containerClassName="suppression-rule-modal-container"
        contentClassName="suppression-rule-modal-content"
        isOpen={true}
        heading={modeText}
        dismissOnClickOutside={false}
        onRequestClose={close}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => close()} />,
          <Button key="save" label="Save" disabled={shouldDisableSave()} variant="brand" onClick={() => save()} />,
        ]}
      >
        <ExpandableSection title="Rule Information">
          <Input
            id="input-rule-label"
            inputRef={labelRef}
            className="suppression-rule-modal-nameinput"
            required
            label="Rule Name"
            errorText={ruleLabelError}
            onChange={(event, data) => changeLabel(data.value)}
            value={label}
          />
          <RuleExpressionBuilder
            ref={ruleBuilderRef}
            showLoading={showLoading}
            triggerType={triggerType}
            setTriggerType={setTriggerType}
            resourceFields={resourceFields}
            setResourceFields={setResourceFields}
            displayResourceFields={displayResourceFields}
            setDisplayResourceFields={setDisplayResourceFields}
            conditions={conditions}
            setConditions={setConditions}
            customLogic={customLogic}
            setCustomLogic={setCustomLogic}
          />
        </ExpandableSection>
        <ExpandableSection title="Suppression Rule">
          <Input
            id="input-rule-reason"
            className="suppression-rule-modal-reasoninput"
            required
            label="Reason"
            errorText={ruleReasonError}
            onChange={(event, data) => changeReason(data.value)}
            value={reason}
          />
          <div className="suppression-rule-modal-reasoncount">
            <span>{reason.length}</span>/{constants.LONG_TEXT_CHAR_LIMIT}
          </div>
          <DateTimePicker
            dateLabel="Start Date"
            dateMs={startDate}
            setDate={setStartDate}
            hasDateError={setHasStartDateError}
            setTime={setStartTime}
            hasTimeError={setHasStartTimeError}
          />
          <DateTimePicker
            dateLabel="End Date"
            dateMs={endDate}
            setDate={setEndDate}
            hasDateError={setHasEndDateError}
            setTime={setEndTime}
            hasTimeError={setHasEndTimeError}
          />
          {dateTimeErrorText.length > 0 ? (
            <div className="suppression-rule-modal-datetime-error">{dateTimeErrorText}</div>
          ) : null}
        </ExpandableSection>
      </Modal>
    </Fragment>
  );
};

SuppressionRuleModal.propTypes = {
  isOpen: PropTypes.bool,
  setClosed: PropTypes.func,
  mode: PropTypes.string,
  serviceId: PropTypes.number,
  existingRuleNames: PropTypes.array,
  existingRuleLabels: PropTypes.array,
  data: PropTypes.object,
  setToastMessage: PropTypes.func, // optional
  setToastVariant: PropTypes.func, // optional
  setShowToast: PropTypes.func, // optional
};

export default SuppressionRuleModal;
