import React, { Fragment, forwardRef, useImperativeHandle, useRef } from 'react';
import ChannelEditorProductTag from '../ChannelEditorProductTag';

const ChannelEditorTypeGOC = forwardRef((props, ref) => {
  const productTagRef = useRef();

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = await productTagRef.current.validateInput();
      return hasError;
    },
  }));

  return (
    <Fragment>
      <ChannelEditorProductTag ref={productTagRef} payload={props.payload} setPayload={props.setPayload} />
    </Fragment>
  );
});

export default ChannelEditorTypeGOC;
