import React, { useState } from 'react';
import { Button, Icon, Toast, Modal, ToastContainer } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import './TokenCreatedModal.scss';
import { constants } from '../../constants';

const TokenCreatedModal = ({ tokenName, token, onClose, isClosed }) => {
  const [showingToast, setShowingToast] = useState(false);
  const tokenCopiedToast = (
    <Toast
      labels={{
        heading: 'API token copied.',
      }}
      className="slds-var-m-bottom_medium slds-var-m-top_x-large"
      onRequestClose={() => setShowingToast(false)}
      duration={constants.TOAST_DURATION}
      variant="success"
    />
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    if (!showingToast) {
      setShowingToast(true);
    }
  };

  return isClosed ? null : (
    <ToastContainer>
      <Modal
        assistiveText={{ dialogLabel: 'Token created' }}
        isOpen={!isClosed}
        dismissOnClickOutside={false}
        onRequestClose={() => onClose()}
        width="38.75rem"
        footer={[<Button key="1" label="Done" variant="neutral" onClick={() => onClose()} />]}
      >
        <div className="slds-text-align_center">
          {showingToast ? (
            tokenCopiedToast
          ) : (
            <Icon
              containerClassName="token-success slds-var-m-bottom_medium slds-var-m-top_x-large"
              assistiveText={{ label: 'Token created' }}
              category="action"
              name="check"
              size="medium"
            />
          )}
          <h4 className="slds-var-m-bottom_x-small slds-hyphenate slds-text-title_bold">
            New API token created successfully
          </h4>

          <div className="slds-form-element token-input slds-align_absolute-center slds-var-m-vertical_medium">
            <div className="slds-form-element__control slds-list_horizontal">
              <input
                className="slds-input slds-truncate token-created-input slds-var-m-right_x-small"
                value={token}
                onChange={() => {}}
              />
              <button className="slds-button slds-button_brand" onClick={copyToClipboard}>
                Copy
              </button>
            </div>
          </div>
          <p className="slds-var-p-horizontal_xx-large slds-var-m-top_x-small slds-var-m-horizontal_xx-large slds-var-m-bottom_x-large created-token-footer">
            Copy the token for <strong>{tokenName}</strong> before closing. For security reasons, you will not be able
            to see this token again.
          </p>
        </div>
      </Modal>
    </ToastContainer>
  );
};

TokenCreatedModal.propTypes = {
  token: PropTypes.string,
};

export default TokenCreatedModal;
