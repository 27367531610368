import React, { useState } from 'react';
import noTokensImage from '../../images/noTokensImage.svg';
import './TokenList.scss';
import apiCalls from '../shared/api';

const TokenList = ({ tokenListChangeCallback, user, refresh, onDeleteCallback, setModalIsHidden }) => {
  const [tokens, setTokens] = useState([]);
  const [fetchingTokens, setFetchingTokens] = useState(true);
  const [fetchAgain, setFetchAgain] = useState(false); // toggle to fetch after token creation

  const fetchTokens = async () => {
    const response = await apiCalls.getTokens();
    if (response.status === 204) {
      // no tokens for user
      return;
    }
    const data = response.data;
    setTokens(data);
    var tokenNamesList = [];
    data.forEach((datum) => tokenNamesList.push(datum.name.toLowerCase()));
    tokenListChangeCallback(tokenNamesList);
    setFetchingTokens(false);
  };

  if (refresh !== fetchAgain) {
    setFetchAgain(!fetchAgain);
    setFetchingTokens(true);
  }

  if (fetchingTokens) {
    fetchTokens();
  }

  if (tokens.length === 0 && !fetchingTokens) {
    return (
      <article className="slds-card">
        <div className="slds-card__body">
          <div>
            <img alt="No API tokens" className="no-tokens-image" src={noTokensImage}></img>
            <p className="slds-text-heading_medium slds-m-top_small">No API tokens</p>
            <h3 className="slds-m-top_small">Create a token to gain API access to Alert Router.</h3>
            <div className="slds-no-flex slds-m-top_medium slds-m-bottom_large">
              <button className="slds-button slds-button_brand" onClick={() => setModalIsHidden(false)}>
                New API Token
              </button>
            </div>
          </div>
        </div>
      </article>
    );
  }
  return fetchingTokens ? null : (
    <div>
      <div className="slds-size_2-of-2 create-token-dialogue slds-m-bottom_medium slds-text-align_left">
        Create a token to gain access to Alert Router.
      </div>
      <article className="slds-card">
        <div className="slds-card__body">
          <table className="slds-table slds-table_cell-buffer slds-table_bordered slds-border_left slds-border_right slds-table_fixed-layout token-table">
            <thead>
              <tr className="slds-line-height_reset">
                <th className="" scope="col">
                  <div className="slds-truncate" title="Token Name">
                    Token Name
                  </div>
                </th>
                <th className="" scope="col">
                  <div className="slds-truncate" title="Last Accessed">
                    Last Accessed
                  </div>
                </th>
                <th className="" scope="col">
                  <div className="slds-truncate" title="Delete"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tokens
                .sort((a, b) => {
                  return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
                }) // sort alphabetically by token name
                .map((token, key) => {
                  return (
                    <tr className="slds-hint-parent" key={key}>
                      <td datalabel="Token Name">
                        <p className="slds-truncate" title={token.name}>
                          {token.name}
                        </p>
                      </td>
                      <td datalabel="Last Accessed">{token.lastUsed ? getFormattedDate(token.lastUsed) : 'Never'}</td>
                      <td className="slds-p-horizontal_none">
                        <button
                          onClick={() => {
                            onDeleteCallback({ id: token.id, name: token.name });
                          }}
                          className="slds-button slds-var-m-vertical_x-small slds-var-m-right_small slds-button_text-destructive slds-float_right"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </article>
    </div>
  );
};

const getFormattedDate = (dateTimeString) => {
  var date = new Date(dateTimeString);
  var dateString = date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
  var timeString = date.toLocaleTimeString();
  return dateString + ' ' + timeString;
};

export default TokenList;
