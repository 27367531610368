import gus_case from './images/icons/Case.png';
import chatter from './images/icons/ChatterGroup.png';
import email from './images/icons/Email.png';
import goc from './images/icons/GOC++.png';
import pagerduty from './images/icons/PagerDuty.png';
import service from './images/icons/Service.png';
import slack from './images/icons/Slack.png';
import noop from './images/icons/Noop.png';
import work from './images/icons/Work.png';
import bug from './images/icons/Bug.png';
import user_story from './images/icons/UserStory.png';
import investigation from './images/icons/Investigation.png';

const IMAGES = {
  GUS_CASE: gus_case,
  CHATTER: chatter,
  EMAIL: email,
  GOC: goc,
  PAGERDUTY: pagerduty,
  SERVICE: service,
  SLACK: slack,
  NOOP: noop,
  WORK: work,
  BUG: bug,
  USER_STORY: user_story,
  INVESTIGATION: investigation,
};

export default IMAGES;
