import React, { useImperativeHandle, forwardRef, Fragment, useRef } from 'react';
import ChannelEditorAssignee from '../ChannelEditorAssignee';
import ChannelEditorTeam from '../ChannelEditorTeam';
import ChannelEditorProductTag from '../ChannelEditorProductTag';
import { constants } from '../../../../../constants';

const IncidentFields = forwardRef((props, ref) => {
  const assigneeRef = useRef();
  const teamRef = useRef();
  const productTagRef = useRef();

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let productTagError = await productTagRef.current.validateInput();
      let assigneeError = await assigneeRef.current.validateInput();
      let teamError = await teamRef.current.validateInput();
      return productTagError || assigneeError || teamError;
    },
  }));

  return (
    <Fragment>
      <ChannelEditorProductTag
        ref={productTagRef}
        payload={props.payload}
        setPayload={props.setPayload}
        required={true}
      />
      <ChannelEditorTeam ref={teamRef} payload={props.payload} setPayload={props.setPayload} required={false} />
      <ChannelEditorAssignee
        ref={assigneeRef}
        payload={props.payload}
        setPayload={props.setPayload}
        required={false}
        label={constants.CASE_OWNER_LABEL}
      />
    </Fragment>
  );
});

export default IncidentFields;
