import React from 'react';

const Item = (props) => {
  return (
    <div className="slds-form__item" role="listitem">
      <div
        className={`slds-form-element slds-form-element_readonly ${
          props.layout === 'horizontal'
            ? 'slds-form-element_horizontal'
            : props.layout === 'stacked'
            ? 'slds-form-element_stacked'
            : 'slds-form-element_horizontal'
        }
        ${props.layout === 'standalone' ? 'slds-form-element_1-col' : null}
        `}
      >
        <span className="slds-form-element__label">{props.label}</span>
        <div className="slds-form-element__control">
          <div className="slds-form-element__static">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Item;
