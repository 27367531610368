import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, comboboxFilterAndLimit, Icon, Tooltip } from '@salesforce/design-system-react';
import apiCalls from '../../../shared/api';
import './ServiceEditorServiceCatalog.scss';
import { constants } from '../../../../constants';

const ServiceEditorServiceCatalog = (props) => {
  // Combobox Variables
  const [searchValue, setSearchValue] = useState('');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [comboboxDropdown, setComboboxDropdown] = useState(false);

  useEffect(() => {
    const setServiceLabel = async () => {
      if (props.serviceCatalogId !== undefined && props.serviceCatalogId !== '' && props.serviceCatalogId !== '-') {
        const response = await apiCalls.getServiceCatalogNameById(props.serviceCatalogId);
        const data = response.data;
        if (data[0] !== undefined) {
          setSelectedService([
            {
              id: props.serviceCatalogId,
              label: data[0].name,
              icon: (
                <Icon
                  containerClassName="service-editor-service-catalog-icon-container"
                  className="service-editor-service-catalog-icon-svg"
                  assistiveText={{ label: 'Service' }}
                  category="custom"
                  size="x-small"
                  name="custom67"
                />
              ),
            },
          ]);
        }
      }
    };
    setServiceLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchGusServices = async () => {
      let query = searchValue.trim();
      if (query.length === 0) {
        setComboboxDropdown(false);
      }
      if (query.length >= constants.MIN_SEARCH_LENGTH) {
        setSpinner(true);
        const response = await apiCalls.queryServiceCatalog(query);
        const data = response.data;
        setSpinner(false);
        if (data !== '') {
          const results = data.map(function (datum) {
            return {
              id: datum.id,
              name: datum.name,
              label: datum.name,
              icon: (
                <Icon
                  containerClassName="service-editor-service-catalog-icon-container"
                  className="service-editor-service-catalog-icon-svg"
                  assistiveText={{ label: 'Service' }}
                  category="custom"
                  size="x-small"
                  name="custom67"
                />
              ),
            };
          });
          setServices(results);
        }
        setComboboxDropdown(true);
      }
    };
    fetchGusServices();
  }, [searchValue]);

  return (
    <Fragment>
      <Combobox
        classNameMenu="modal-combobox-dropdown"
        events={{
          onChange: (event, { value }) => {
            setSearchValue(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSearchValue('');
            setSelectedService(data.selection);
            setServices([]);
            props.setServiceCatalogId('');
          },
          onSelect: (event, data) => {
            setSearchValue('');
            setSelectedService(data.selection);
            props.setServiceCatalogId(data.selection[0].id);
          },
        }}
        labels={{
          label: 'GUS Service',
          placeholder: 'Search Services in GUS...',
        }}
        menuItemVisibleLength={7}
        options={comboboxFilterAndLimit({
          inputValue: searchValue,
          limit: 100,
          options: services,
          selection: selectedService,
        })}
        selection={selectedService}
        value={searchValue}
        menuPosition="overflowBoundaryElement"
        variant="inline-listbox"
        hasInputSpinner={spinner}
        isOpen={comboboxDropdown}
        fieldLevelHelpTooltip={
          <Tooltip
            id="service-editor-service-catalog-tooltip"
            align="right"
            content="Optionally, associate this Alert Router service with a service defined in GUS."
          />
        }
      />
    </Fragment>
  );
};

export default ServiceEditorServiceCatalog;
