import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TokenCreatedModal from './TokenCreatedModal';
import { Button, Modal, Input } from '@salesforce/design-system-react';
import './CreateTokenModal.scss';
import apiCalls from '../shared/api';

const CreateTokenModal = ({ tokenList, isClosed, onClose, onTokenCreate }) => {
  const [created, setCreated] = useState(false);
  const [inputErrorStatus, setInputErrorStatus] = useState(null);
  const [createdToken, setCreatedToken] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const tokenInputCallback = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  });

  const create = async () => {
    if (tokenName == null || tokenName.length === 0) {
      setInputErrorStatus('This field is required.');
    } else if (/\s/g.test(tokenName)) {
      setInputErrorStatus('Token name should not contain whitespace.');
    } else if (tokenName.length > 100) {
      setInputErrorStatus('Token name must be less than 100 characters.');
    } else if (tokenList.includes(tokenName.toLowerCase())) {
      setInputErrorStatus('Token must be unique!');
    } else {
      setInputErrorStatus(null);
      await fetchNewToken();
      setCreated(true);
    }
  };

  const close = () => {
    setInputErrorStatus(null);
    setTokenName(null);
    setCreated(false);
    setCreatedToken(null);
    onClose();
  };

  const updateTokenName = (event) => {
    var token = event.target.value;
    setTokenName(token);
  };

  const tokenInput = (
    <Input
      className="token-input slds-var-m-horizontal_large slds-var-m-bottom_x-large"
      aria-describedby="error-3"
      id="unique-id-3"
      label="Token Name"
      required
      onChange={updateTokenName}
      maxLength="100"
      type="text"
      inputRef={tokenInputCallback}
      errorText={inputErrorStatus}
      placeholder="What will this token be used for?"
      autoComplete="off"
    />
  );

  const fetchNewToken = async () => {
    const response = await apiCalls.createToken(tokenName);
    const data = response.data;
    if (response.status === 200) {
      setCreatedToken(data.token);
      onTokenCreate({
        name: tokenName,
        lastUsed: null,
      });
    }
  };

  if (created) {
    return (
      <TokenCreatedModal
        tokenName={tokenName}
        token={createdToken}
        onClose={close}
        isClosed={isClosed}
      ></TokenCreatedModal>
    );
  }

  return (
    <Modal
      heading="New API Token"
      assistiveText={{ dialogLabel: 'Token created' }}
      isOpen={!isClosed}
      dismissOnClickOutside={false}
      onRequestClose={close}
      width="38.75rem"
      footer={[
        <Button key="1" label="Cancel" variant="neutral" onClick={close} />,
        <Button key="2" label="Create" variant="brand" onClick={create} />,
      ]}
    >
      <p className="slds-var-m-horizontal_large slds-var-m-top_large slds-var-m-bottom_medium">
        Give the token a descriptive name to help you remember what it will be used for.
      </p>
      {tokenInput}
    </Modal>
  );
};

CreateTokenModal.propTypes = {
  tokenList: PropTypes.array,
  onTokenCreate: PropTypes.func,
  isClosed: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateTokenModal;
