import React, { forwardRef, Fragment, useEffect, useState, useImperativeHandle, useRef } from 'react';
import TeamCombobox from './TeamCombobox';
import PropTypes from 'prop-types';

const ChannelEditorTeam = forwardRef((props, ref) => {
  const scrumTeamComboboxRef = useRef();
  const [scrumTeam, setScrumTeam] = useState(
    props.payload !== undefined ? (props.payload.scrumTeam !== undefined ? props.payload.scrumTeam : '') : ''
  );

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = await scrumTeamComboboxRef.current.validateInput();
      return hasError;
    },
  }));

  useEffect(() => {
    if (scrumTeam === '') {
      let removeScrumTeamPayload = { ...props.payload };
      delete removeScrumTeamPayload.scrumTeam;
      props.setPayload({ ...removeScrumTeamPayload });
    } else {
      props.setPayload({ ...props.payload, scrumTeam: scrumTeam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrumTeam]);

  const onSelect = (team) => {
    if (team === undefined) {
      setScrumTeam('');
    } else {
      setScrumTeam(team.id);
    }
  };

  return (
    <Fragment>
      <TeamCombobox
        ref={scrumTeamComboboxRef}
        label="Team"
        placeholder="Search Teams..."
        required={props.required}
        onSelect={onSelect}
        teamId={
          props.payload !== undefined ? (props.payload.scrumTeam !== undefined ? props.payload.scrumTeam : '') : ''
        }
      />
    </Fragment>
  );
});

ChannelEditorTeam.propTypes = {
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  required: PropTypes.bool,
};

export default ChannelEditorTeam;
