import { Icon, Toast, ToastContainer } from '@salesforce/design-system-react';
import TokenList from './TokenList';
import React from 'react';
import './TokenCard.scss';
import CreateTokenModal from './CreateTokenModal';
import { useState } from 'react';
import DeleteTokenModal from './DeleteTokenModal';
import { constants } from '../../constants';

const TokenCard = () => {
  const [modalIsHidden, setModalIsHidden] = useState(true);
  const [tokenNameList, setTokenNameList] = useState([]);
  const [refresh, setRefresh] = useState(false); // toggle to refresh the tokenList after creation

  // delete modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTokenData, setDeleteTokenData] = useState({});
  const onDeleteCallback = (data) => {
    setDeleteTokenData(data);
    setIsDeleteModalOpen(true);
  };
  // delete toast
  const [showToast, setShowToast] = useState(false);
  const deleteToast = (
    <ToastContainer>
      <Toast
        labels={{
          heading: 'Token was deleted.',
        }}
        onRequestClose={() => setShowToast(false)}
        duration={constants.TOAST_DURATION}
        variant={'success'}
      />
    </ToastContainer>
  );

  return (
    <article className="slds-card tokenCard flex-column-container">
      <div className="slds-card__header slds-grid token-header">
        <header className="slds-media slds-media_center slds-has-flexi-truncate">
          <div className="slds-media__figure">
            <span className="slds-icon_container slds-icon-standard-account" title="account">
              <Icon assistiveText={{ label: 'API Tokens' }} category="custom" name="custom76" size="medium" />
              <span className="slds-assistive-text">API Tokens</span>
            </span>
          </div>
          <div className="slds-media__body">
            <h2 className="slds-card__header-title">
              <div className="slds-card__header-link slds-truncate slds-text-align_left" title="API Tokens">
                <span>API Tokens</span>
              </div>
            </h2>
          </div>
          {tokenNameList.length !== 0 ? (
            <div className="slds-no-flex">
              <button className="slds-button slds-button_neutral" onClick={() => setModalIsHidden(false)}>
                New API Token
              </button>
            </div>
          ) : null}
        </header>
      </div>
      <div className="slds-card__body slds-m-vertical_none slds-p-top_medium slds-p-bottom_xx-small slds-card__body_inner slds-scrollable_y">
        <TokenList
          refresh={refresh}
          tokenListChangeCallback={setTokenNameList}
          onDeleteCallback={onDeleteCallback}
          setModalIsHidden={setModalIsHidden}
        />
        <CreateTokenModal
          isClosed={modalIsHidden}
          onClose={() => setModalIsHidden(true)}
          tokenList={tokenNameList}
          onTokenCreate={() => setRefresh(!refresh)}
        ></CreateTokenModal>
        {isDeleteModalOpen ? (
          <DeleteTokenModal
            isOpen={true}
            deleteTokenData={deleteTokenData}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setShowToast={setShowToast}
            onTokenDelete={() => setRefresh(!refresh)}
          ></DeleteTokenModal>
        ) : null}
        {showToast ? deleteToast : null}
      </div>
    </article>
  );
};

export default TokenCard;
