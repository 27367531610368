import React, { Fragment } from 'react';
import { Button, Modal, Settings } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import { constants } from '../../../constants';
import './ConfirmModal.scss';

Settings.setAppElement('#root');

const ConfirmModal = ({
  heading,
  dismissOnClickOutside,
  onRequestCloseCallback,
  informContent,
  variant,
  deleteContent,
}) => {
  const onClose = () => {
    onRequestCloseCallback(constants.CLOSE);
  };
  const onDelete = () => {
    onRequestCloseCallback(constants.DELETE);
  };

  const defaultDeleteContent = (
    <Fragment>
      <span>This operation cannot be undone. Are you sure you want to delete?</span>
    </Fragment>
  );

  return (
    <Modal
      containerClassName={'confirm-modal-container'}
      contentClassName={'confirm-modal-content'}
      isOpen={true}
      heading={heading}
      dismissOnClickOutside={dismissOnClickOutside}
      onRequestClose={() => onClose()}
      footer={
        variant === constants.CONFIRM
          ? [
              <Button key="cancel" label="Cancel" onClick={() => onClose()} />,
              <Button key="delete" label="Delete" variant="destructive" onClick={() => onDelete()} />,
            ]
          : [<Button key="cancel" label="Got It" variant="brand" onClick={() => onClose()} />]
      }
    >
      {variant === constants.CONFIRM
        ? deleteContent !== undefined
          ? deleteContent
          : defaultDeleteContent
        : informContent}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  heading: PropTypes.string,
  dismissOnClickOutside: PropTypes.bool,
  onRequestCloseCallback: PropTypes.func,
  informContent: PropTypes.node,
  variant: PropTypes.string,
  deleteContent: PropTypes.node,
};

export default ConfirmModal;
