import React, { Fragment, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import UserCombobox from './UserCombobox';
import { useEffect } from 'react';

const ChannelEditorAssignee = forwardRef((props, ref) => {
  const userComboboxRef = useRef();
  const [assignee, setAssignee] = useState(
    props.payload !== undefined ? (props.payload.assignee !== undefined ? props.payload.assignee : '') : ''
  );

  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = await userComboboxRef.current.validateInput();
      return hasError;
    },
  }));

  useEffect(() => {
    if (assignee === '') {
      let removeAssigneePayload = { ...props.payload };
      delete removeAssigneePayload.assignee;
      props.setPayload({ ...removeAssigneePayload });
    } else {
      props.setPayload({ ...props.payload, assignee: assignee });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignee]);

  const onSelect = (user) => {
    if (user === undefined) {
      setAssignee('');
    } else {
      setAssignee(user.id);
    }
  };

  return (
    <Fragment>
      <UserCombobox
        ref={userComboboxRef}
        label={props.label}
        placeholder="Search People..."
        required={props.required}
        onSelect={onSelect}
        userId={props.payload !== undefined ? (props.payload.assignee !== undefined ? props.payload.assignee : '') : ''}
      />
    </Fragment>
  );
});

ChannelEditorAssignee.propTypes = {
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  required: PropTypes.bool,
};
export default ChannelEditorAssignee;
