import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, IconSettings, PageHeader, PageHeaderControl } from '@salesforce/design-system-react';
import apiCalls from '../shared/api';
import { parseFilter } from '../shared/utilities';
import { constants } from '../../constants';
import { ToastContext } from '../shared/toast-context';
import { RefreshContext } from '../shared/refresh-context';
import GroupingRulesTable from './GroupingRulesTable';
import NoItemsCard from '../../components/NoItemsCard';
import GroupingRuleModal from './GroupingRuleModal';
import RuleDeleteModal from './RuleDeleteModal';
import './Rules.scss';

const GroupingRules = (props) => {
  const serviceOwners = props.serviceOwners;
  const [loadingGroupingRules, setLoadingGroupingRules] = useState(false);
  const [sortProperties, setSortProperties] = useState({ sortField: 'label', sortDirection: 'asc' });
  const [groupingRules, setGroupingRules] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  const refreshContext = useContext(RefreshContext);

  const setDeleteOpen = React.useCallback((a) => {
    setModalData({
      name: a.name,
      label: a.label,
      ruleId: a.id,
      serviceId: props.serviceId,
      createdBy: a.createdBy,
    });
    setMode(constants.DELETE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeleteClosed = async (deleteRule) => {
    if (deleteRule === constants.DELETE) {
      const response = await apiCalls.deleteRuleById(modalData.ruleId);
      if (response.status === 200 || response.status === 204) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Notification rule was deleted.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      }
      await fetchGroupingRules(-1);
    }
    setIsModalOpen(false);
  };

  const setEditOpen = React.useCallback((a) => {
    setModalData({
      label: a.label,
      name: a.name,
      ruleId: a.id,
      serviceId: props.serviceId,
    });
    setMode(constants.EDIT_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEditClosed = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const setOpenCreate = React.useCallback(() => {
    setModalData({
      label: '',
      name: '',
      ruleId: -1,
      serviceId: props.serviceId,
    });
    setMode(constants.CREATE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGroupingRules = async (id) => {
    setLoadingGroupingRules(true);

    let response = [];
    try {
      // Get the grouping rules
      if (id === -1) {
        response = await apiCalls.getRulesByServiceIdAndType(props.serviceId, false, 'GROUPING');
      } else {
        response = await apiCalls.getRuleById(id, false);
      }

      if (response.status !== 200) {
        // No grouping rules found
        setLoadingGroupingRules(false);
        return;
      }
    } catch {
      setLoadingGroupingRules(false);
      return;
    }

    // We want the results in an array, regardless of whether we got all the rules or just one.
    const rulesData = id === -1 ? response.data : [response.data];

    // Transform API data to something better suited to DataTable.
    let rulesList = [];
    Object.entries(rulesData).forEach((datum) => {
      if (datum[0] !== 'error' && datum[0] !== 'code' && datum[0] !== 'errors') {
        const rule = {};
        rule.id = datum[1].id.toString();
        rule.name = datum[1].name;
        rule.label = datum[1].label;
        rule.modifiedBy = datum[1].modifiedBy;
        rule.modifiedDate = new Date(datum[1].modifiedDate).toLocaleString('en', {
          timeStyle: 'short',
          dateStyle: 'short',
        });
        rule.modifiedDateMs = datum[1].modifiedDate;
        rule.ruleConditions = parseFilter(datum[1].filter);
        rule.filter = datum[1].filter;
        rule.createdBy = datum[1].createdBy;

        if (id === -1) {
          rulesList.push(rule);
        } else {
          let newRulesList = [];
          let added = false;
          for (let i = 0; i < rulesList.length; i++) {
            if (rulesList[i].id === id) {
              newRulesList.push(rule);
              // Rule to refresh replaced in rules
              added = true;
            } else {
              newRulesList.push(rulesList[i]);
            }
          }
          if (!added) {
            // Rule to refresh not found in rules, adding
            newRulesList.push(rule);
          }
          rulesList = newRulesList;
        }
      }
    });

    // Now sort the data
    sortTable(rulesList, sortProperties.sortField, sortProperties.sortDirection);
    setLoadingGroupingRules(false);
  };

  const sortTable = (data, sortField, sortDirection) => {
    data.sort((x, y) => {
      // Sort modified by date using the epoch time field instead of the human readable one
      let actualSortField = sortField;
      if (sortField === 'modifiedDate') {
        actualSortField = 'modifiedDateMs';
      }

      let result = 1;
      if (typeof x[actualSortField] === 'string') {
        result = x[actualSortField].localeCompare(y[actualSortField], 'en', { sensitivity: 'case' });
      } else if (x[actualSortField] < y[actualSortField]) {
        result = -1;
      } else if (x[actualSortField] === y[actualSortField]) {
        result = 0;
      }

      return sortDirection === 'asc' ? result : -result;
    });

    setGroupingRules(data);
  };

  const handleSort = useCallback(
    (sortColumn, ...rest) => {
      sortTable(groupingRules, sortColumn.property, sortColumn.sortDirection);
      setSortProperties({ sortField: sortColumn.property, sortDirection: sortColumn.sortDirection });
    },
    [groupingRules]
  );

  const getListInfo = (numItems) => {
    const fieldNameMap = {
      label: 'Rule Name',
      ruleConditions: 'Rule Criteria',
      notificationChannels: 'Notification Channels',
      modifiedBy: 'Last Modified By',
      modifiedDate: 'Last Modified',
    };

    let items = '0 items';
    if (numItems === 1) {
      items = '1 item';
    } else {
      items = `${numItems} items`;
    }
    return (
      <span className="list-info-summary">
        {items} • Sorted by&nbsp;
        {fieldNameMap[sortProperties.sortField]}
      </span>
    );
  };

  useEffect(() => {
    async function fetchGroupingRulesData() {
      await fetchGroupingRules(-1);
    }

    fetchGroupingRulesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceId, refreshContext.groupingRules]);

  const foundItems = groupingRules.length;

  const actions = () => (
    <>
      <PageHeaderControl>
        <Button label="New Grouping Rule" onClick={setOpenCreate} />
      </PageHeaderControl>
    </>
  );

  return (
    <div className="flex-column-container">
      <IconSettings iconPath="/assets/icons">
        {isModalOpen && mode !== constants.DELETE_MODE ? (
          <GroupingRuleModal
            isOpen={true}
            setClosed={setEditClosed}
            mode={mode}
            existingRuleNames={props.existingRuleNames}
            existingRuleLabels={props.existingRuleLabels}
            serviceId={props.serviceId}
            serviceName={props.serviceName}
            data={modalData}
          />
        ) : null}
        {isModalOpen && mode === constants.DELETE_MODE ? (
          <RuleDeleteModal
            type="Grouping"
            serviceOwners={serviceOwners}
            data={modalData}
            onRequestCloseCallback={setDeleteClosed}
          />
        ) : null}
        {foundItems > 0 && !loadingGroupingRules ? (
          <>
            <PageHeader
              title="Grouping Rules"
              info={getListInfo(foundItems)}
              joined
              variant="object-home"
              onRenderActions={actions}
            />
            <GroupingRulesTable
              onEdit={setEditOpen}
              onDelete={setDeleteOpen}
              groupingRules={groupingRules}
              sortField={sortProperties.sortField}
              sortDirection={sortProperties.sortDirection}
              handleSort={handleSort}
            />
            <div className="footer-bar"></div>
          </>
        ) : !loadingGroupingRules ? (
          <NoItemsCard
            itemName="Grouping Rule"
            createDescription="Create a rule to specify which alerts to group together."
            onCreateClicked={setOpenCreate}
          />
        ) : null}
      </IconSettings>
    </div>
  );
};

export default GroupingRules;
