import React, { createContext, useState } from 'react';

const initialState = {
  rules: false,
  notifiers: false,
  groupingRules: false,
  suppressionRules: false,
  refreshRules: () => {},
  refreshNotifiers: () => {},
  refreshGroupingRules: () => {},
  refreshSuppressionRules: () => {},
};

const RefreshContext = createContext(initialState);

const RefreshContextProvider = (props) => {
  const [rulesBool, setRulesBool] = useState(false);
  const [notifiersBool, setNotifiersBool] = useState(false);
  const [groupingRulesBool, setGroupingRulesBool] = useState(false);
  const [suppressionRulesBool, setSuppressionRulesBool] = useState(false);

  const refreshRulesHandler = () => {
    setRulesBool(!rulesBool);
  };

  const refreshNotifiersHandler = () => {
    setNotifiersBool(!notifiersBool);
  };

  const refreshSuppressionRulesHandler = () => {
    setSuppressionRulesBool(!suppressionRulesBool);
  };

  const refreshGroupingRulesHandler = () => {
    setGroupingRulesBool(!groupingRulesBool);
  };

  return (
    <RefreshContext.Provider
      value={{
        rules: rulesBool,
        notifiers: notifiersBool,
        groupingRules: groupingRulesBool,
        suppressionRules: suppressionRulesBool,
        refreshRules: refreshRulesHandler,
        refreshNotifiers: refreshNotifiersHandler,
        refreshGroupingRules: refreshGroupingRulesHandler,
        refreshSuppressionRules: refreshSuppressionRulesHandler,
      }}
    >
      {props.children}
    </RefreshContext.Provider>
  );
};

export { RefreshContext, RefreshContextProvider };
