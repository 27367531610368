import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@salesforce/design-system-react';

import avatar from '../images/avatar.png';

const UserAvatar = ({ avatarUrl, userName = 'User Name' }) => {
  return <Avatar assistiveText={{ icon: 'Avatar image' }} imgSrc={avatarUrl || avatar} imgAlt={userName} />;
};

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  userName: PropTypes.string,
};

export default UserAvatar;
