import React, { useState, Fragment, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Input } from '@salesforce/design-system-react';
import { errors } from '../../../../../constants';

const ChannelEditorTypeEmail = forwardRef((props, ref) => {
  // eslint-disable-next-line
  const emailRegex = RegExp(
    '^\\s*[^\\s,@]+((@[a-zA-Z0-9-_\\.]*salesforce\\.com|@[a-zA-Z0-9-_\\.]*slack\\.com|@salesforce\\.pagerduty\\.com)$)'
  );
  useImperativeHandle(ref, () => ({
    async validateInput() {
      let hasError = false;
      let input = recipients.trim();

      const emails = input.split(',').map((email) => {
        return email.trim().toLowerCase();
      });

      if (emails.length === 1 && emails[0] === '') {
        hasError = true;
        setError(errors.REQUIRED);
      } else {
        emails.forEach((email) => {
          if (!emailRegex.test(email)) {
            hasError = true;
            setError(errors.INVALID_EMAIL);
          }
        });
      }

      if (!hasError) {
        setError('');
      }

      return hasError;
    },
  }));

  // Input Variables
  const [recipients, setRecipients] = useState(
    props.payload !== undefined ? (props.payload.recipients !== undefined ? props.payload.recipients : '') : ''
  );
  // End Input Variables

  // Update payload only after input variable is set
  useEffect(() => {
    let parsedRecipients = recipients.split(',').map((el) => el.trim().toLowerCase());
    let uniqueRecipients = [...new Set(parsedRecipients)];
    props.setPayload({ ...props.payload, recipients: uniqueRecipients.join(', ') });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients]);

  const [error, setError] = useState('');

  return (
    <Fragment>
      <Input
        id="input-email"
        label="Email Addresses"
        errorText={error}
        placeholder="Enter a comma separated list of emails..."
        required
        onChange={(e) => {
          setRecipients(e.target.value);
        }}
        value={recipients}
      />
    </Fragment>
  );
});

export default ChannelEditorTypeEmail;
