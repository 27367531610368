import React, { useContext, useState, useEffect, useRef, Fragment } from 'react';
import {
  Button,
  Combobox,
  ExpandableSection,
  Icon,
  Input,
  Modal,
  Settings,
  Textarea,
  Toast,
} from '@salesforce/design-system-react';
import comboboxFilterAndLimit from '@salesforce/design-system-react/components/combobox/filter';
import './NotificationRuleModal.scss';
import NotificationChannelsModal from './ChannelModal/NotificationChannelsModal';
import { constants, errors, excludedFieldLabels } from '../../constants';
import PropTypes from 'prop-types';
import apiCalls from '../shared/api';
import {
  getChannelTypeImage,
  getNameFromLabel,
  getValidOperators,
  getFieldLabel,
  getFieldInfo,
  getUIDate,
} from '../shared/utilities';

import { ToastContext } from '../shared/toast-context';
import { RefreshContext } from '../shared/refresh-context';

import RuleExpressionBuilder from './RuleExpressionBuilder';

Settings.setAppElement('#root');

const NotificationRuleModal = (props) => {
  const serviceId = props.serviceId;
  const serviceName = props.serviceName;
  const ruleId = props.data.ruleId;
  const mode = props.mode;
  const existingRuleNames = props.existingRuleNames;
  const existingRuleLabels = props.existingRuleLabels;
  const labelRef = useRef();
  const addNewChannelID = 'addNewChannelID';
  const ruleBuilderRef = useRef();

  // Input Variables
  const [label, setLabel] = useState(props.data.label);
  const [name, setName] = useState(props.data.name);
  const [description, setDescription] = useState(props.data.description);
  const [conditions, setConditions] = useState([]);
  const [resourceFields, setResourceFields] = useState([]);
  const [displayResourceFields, setDisplayResourceFields] = useState([]);
  const [triggerType, setTriggerType] = useState('all');
  const [customLogic, setCustomLogic] = useState('');
  const [availableChannels, setAvailableChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [ruleLabelError, setRuleLabelError] = useState('');
  const [channelRequiredError, setChannelRequiredError] = useState('');
  const [dataIsHere, setDataIsHere] = useState(false);
  const [ruleIsHere, setRuleIsHere] = useState(false);
  const [isNotificationChannelModalOpen, setNotificationChannelModalOpen] = useState(false);
  const [notificationChannelSearchValue, setNotificationChannelSearchValue] = useState('');
  const [addChannelPressed, setAddChannelPressed] = useState(false);
  const [currentTimer, setCurrentTimer] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  // End Input Variables

  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  const [groupingRules, setGroupingRules] = useState([]);
  const [shouldFetchGroupingRules, setShouldFetchGroupingRules] = useState(true);
  const [hasGroupingRuleCondition, setHasGroupingRuleCondition] = useState(false);

  // Refresh Context
  const refreshContext = useContext(RefreshContext);

  // Rules Toast
  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  // Created Channel Toast
  const modeText = (mode === constants.EDIT_MODE ? 'Edit' : 'New') + ' Notification Rule';
  const [showCreatedChannelToast, setShowCreatedChannelToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('Notification channel was created.');
  const [toastVariant, setToastVariant] = useState('success');
  const rulesModalHeading = (
    <div>
      <h2 className="slds-text-heading_medium">{modeText}</h2>
      {showCreatedChannelToast ? (
        <Toast
          className="embedded-create-channel-toast"
          labels={{
            heading: toastMessage,
          }}
          onRequestClose={() => setShowCreatedChannelToast(false)}
          duration={constants.TOAST_DURATION}
          variant={toastVariant}
        />
      ) : null}
    </div>
  );

  // No idea why Lint complains about useCallback here....
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const addCondition = () => {
    const newCondition = {
      isGroup: false,
      resource: '',
      operator: '',
      value: '',
    };
    setConditions([...conditions, newCondition]);
  };

  useEffect(() => {
    if (currentTimer && timerExpired && ruleIsHere) {
      // Reached timeout, hide spinner
      setShowLoading(false);
    }
  }, [currentTimer, ruleIsHere, timerExpired]);

  // The general useEffect that loads all the data for this rule along with fields and channels.
  useEffect(() => {
    const fetchRule = async (id) => {
      try {
        const response = await apiCalls.getRuleById(ruleId, true);
        const data = response.data;

        if (response.status === 200) {
          setName(data.name.trim());
          setDescription(data.template ? data.template.description : '');

          // Set the Trigger type
          setTriggerType(data.isCustom ? 'custom' : data.tree.logicalExpression.indexOf(' AND ') > 0 ? 'all' : 'any');

          // Set the expression string for custom logic
          setCustomLogic(data.tree.logicalExpression);

          // Set the Selected channel
          const newChannels = data.typeConfig.notifiers.map((elem) => {
            const channel = availableChannels.find((chan) => chan.name === elem);
            return {
              ...channel,
              ...{
                icon: getChannelTypeImage(channel.type, 'notification-rule-modal'),
                label: channel.label,
              },
            };
          });
          setSelectedChannels(newChannels);

          const NUM_RULE_CONDITIONS_THRESHOLD = 30;
          const LOADING_RULES_SPINNER_DURATION = 1200;

          if (data.tree.expressionList.length > NUM_RULE_CONDITIONS_THRESHOLD) {
            // Too many conditions, showing Spinner, and start a timer that will hide the spinner
            // in 1.2s (will be blocked until initial render is complete)
            setCurrentTimer(setTimeout(() => setTimerExpired(true), LOADING_RULES_SPINNER_DURATION));
            setShowLoading(true);
          }

          // Build the condition lines from the conditions tree
          const fields = [...resourceFields];
          const displayFields = [...displayResourceFields];

          // Fetch grouping rules only if the rule filter contains 'groupingRuleId'
          let groupingRulesList;
          if (data.filter.includes('groupingRuleId')) {
            groupingRulesList = await fetchGroupingRulesList();
          }

          const newConditions = data.tree.expressionList.map((expr) => {
            let field = getFieldInfo(resourceFields, expr.operand1);
            let val = expr.operand2;
            if (!field) {
              if (expr.operand1.toLowerCase().startsWith(constants.CUSTOM_PREFIX)) {
                const fieldLabel = getFieldLabel(expr.operand1);
                field = {
                  id: constants.CUSTOM_PREFIX + expr.operand1.substring(7),
                  label: fieldLabel,
                  type: { name: 'custom' }, // Custom fields support all operators
                };
                fields.push(field);
                displayFields.push({ id: field.id, label: field.label });
                resourceFields.push(field);
              }
            } else if (expr.operand1 === 'groupingRuleId') {
              const groupingRule = groupingRulesList.find((rule) => rule.id === parseInt(val));
              // If the grouping rule wasn't found, don't show the Combobox and instead show the value as is in an Input box
              if (groupingRule) {
                field.valueSelected = { id: groupingRule.id, label: groupingRule.label };
                field.valuesList = groupingRulesList;
              } else {
                field.valueSelected = { id: 'none', label: '' };
                field.valuesList = undefined;
              }
            } else if (field.type.name === 'enum') {
              const value = field.valuesList.find((v) => v.id.toLowerCase() === val.toLowerCase());
              if (value) {
                field.valueSelected = { id: value.id, label: value.label };
              } else {
                field.valueSelected = { id: 'none', label: '' };
              }
            }

            const allowedOperators = getValidOperators(field);
            const operator = allowedOperators.find((op) => (op.sqlOp ? op.sqlOp : op.id) === expr.operator);
            if (field.type.name.toLowerCase() === 'date') {
              let dtVal = new Date(Number(val));
              val = getUIDate(dtVal);
            }
            const newCondition = {
              isGroup: false,
              resource: expr.operand1.toLowerCase().startsWith(constants.CUSTOM_PREFIX)
                ? constants.CUSTOM_PREFIX + expr.operand1.substring(7)
                : expr.operand1,
              operator: operator,
              value: val,
              valuesList: field.valueSelected && field.valueSelected.id === 'none' ? undefined : field.valuesList, // Determines whether to show the Combobox or Input box
              valueSelected: field.valueSelected,
            };
            return newCondition;
          });

          setResourceFields(fields);
          setDisplayResourceFields(displayFields);
          setConditions(newConditions);
          setRuleIsHere(true);
        }
      } catch (ex) {
        console.log('ERROR getting Rule ' + id + ': ', ex);
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: ex.message,
            toastVariant: 'error',
            showToast: true,
            errorHeading: 'An unexpected error was encountered while processing the rule.',
          },
        });
      }
    };

    // Call the API to retrieve the list of fields in a rule, the notifiers that the rules can use and the rule itself.
    const fetchDataFields = async () => {
      // First, get the available fields
      try {
        const response = await apiCalls.getAlertFields();
        const data = response.data;
        if (response.status === 200) {
          const fields = [];
          const displayFields = [];
          for (let i = 0; i < data.length; i++) {
            if (!excludedFieldLabels.includes(data[i].fieldName)) {
              const fieldLabel = getFieldLabel(data[i].fieldName);
              // If data[i].type.name is enum, populate valuesList with data[i].type.values
              if (data[i].type.name === 'enum') {
                const enumValuesList = [];
                for (let j = 0; j < data[i].type.values.length; j++) {
                  enumValuesList.push({ id: data[i].type.values[j].toLowerCase(), label: data[i].type.values[j] });
                }
                fields.push({
                  id: data[i].fieldName,
                  label: fieldLabel,
                  type: data[i].type,
                  valuesList: enumValuesList,
                });
                displayFields.push({ id: data[i].fieldName, label: fieldLabel });
              } else if (data[i].fieldName === 'groupingRuleId') {
                fields.push({
                  id: data[i].fieldName,
                  label: fieldLabel,
                  type: { name: 'groupingRule' },
                });
                displayFields.push({ id: data[i].fieldName, label: fieldLabel });
              } else {
                // else, do original flow
                fields.push({
                  id: data[i].fieldName,
                  label: fieldLabel,
                  type: data[i].type,
                });
                if (data[i].fieldName !== 'custom') {
                  displayFields.push({ id: data[i].fieldName, label: fieldLabel });
                }
              }
            }
          }
          displayFields.sort((x, y) => x.label.localeCompare(y.label));
          setResourceFields(fields);
          setDisplayResourceFields(displayFields);
        }
      } catch {
        console.log('ERROR getting Fields');
      }

      // Then get the available notifier channels for this service
      try {
        const response = await apiCalls.getNotifiersByServiceId(serviceId);
        const data = response.data;
        if (response.status === 200) {
          const channels = data.map((elem) => ({
            ...elem,
            ...{
              icon: getChannelTypeImage(elem.type, 'notification-rule-modal'),
              label: elem.label,
            },
          }));

          channels.sort((x, y) => x.label.localeCompare(y.label));

          setAvailableChannels(channels);
          setDataIsHere(true);
        }
      } catch {
        console.log('ERROR getting channels');
      }
    };

    // Have we retrieved the field data?
    if (!dataIsHere) {
      // No, so get it
      fetchDataFields();
    } else if (!ruleIsHere) {
      // Yes, we got the fields, but not the rule, so ....
      if (mode === constants.EDIT_MODE) {
        // ... in edit mode get the rule
        fetchRule(ruleId);
      } else {
        // ... but in create mode, add a default (empty) condition
        addCondition();
        setRuleIsHere(true);
      }
    } else {
      // Everything is here, so set focus to the rule name field.
      labelRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIsHere, ruleIsHere, labelRef]);

  // Refresh the Notification channels and automatically add any new ones to the selected channels
  // This is invoked when the Notification Channel creation workflow modal is invoked.
  useEffect(() => {
    const fetchChannels = async () => {
      // Get the available notifier channels for this service
      try {
        const response = await apiCalls.getNotifiersByServiceId(serviceId);
        const data = response.data;
        if (response.status === 200) {
          const channels = data.map((elem) => ({
            ...elem,
            ...{
              icon: getChannelTypeImage(elem.type, 'notification-rule-modal'),
              label: elem.label,
            },
          }));
          // Find the newly created channel...
          const newChannel = channels.filter((ch) => !availableChannels.find((ach) => ach.id === ch.id));

          // ... and add it to the selected channels
          const newSelectedChannels = [...selectedChannels, ...newChannel];
          setSelectedChannels(newSelectedChannels);
          setAvailableChannels(channels);
        }
      } catch {
        console.log('ERROR getting channels');
      }
    };
    if (addChannelPressed) {
      fetchChannels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContext.notifiers]);

  // Get the grouping rules for the service.
  const fetchGroupingRulesList = async () => {
    if (!shouldFetchGroupingRules) {
      return groupingRules;
    }
    let rulesList;
    try {
      const response = await apiCalls.getRulesByServiceIdAndType(serviceId, false, 'GROUPING');
      const data = response.data;
      if (response.status === 200) {
        rulesList = data.map((groupingRule) => ({
          id: groupingRule.id,
          label: groupingRule.label,
        }));
      }
      setShouldFetchGroupingRules(false);
      setGroupingRules(rulesList);
    } catch (ex) {
      console.log('ERROR fetching grouping rules: ', ex);
      toastDispatch({
        type: 'SET_TOAST_STATE',
        value: {
          ...toastState,
          toastMessage: ex.message,
          toastVariant: 'error',
          showToast: true,
          errorHeading: 'An unexpected error was encountered while fetching the grouping rules.',
        },
      });
    }
    return rulesList;
  };

  // Checks if at least one of the conditions uses groupingRuleId in order to update the RuleExpressionBuilder's note
  useEffect(() => {
    const containsGroupingRuleCondition = conditions.some((condition) => {
      return condition.resource === 'groupingRuleId';
    });
    setHasGroupingRuleCondition(containsGroupingRuleCondition);
  }, [conditions]);

  // Make sure the rule name is specified, and is not already present in the service.
  const validateLabel = (newLabel) => {
    let hasError = false;
    if (newLabel.trim().length === 0) {
      setRuleLabelError(errors.REQUIRED);
      hasError = true;
    } else if (newLabel.trim().length > constants.TEXT_CHAR_LIMIT) {
      setRuleLabelError(errors.CHAR_LIMIT);
      hasError = true;
    } else if (newLabel.trim() !== props.data.label) {
      // If it's changed, check the other existing rules for this service.
      if (existingRuleLabels.find((ern) => ern.localeCompare(newLabel.trim()) === 0)) {
        setRuleLabelError(errors.NON_UNIQUE_NAME);
        hasError = true;
      } else {
        setRuleLabelError('');
      }
    }
    return hasError;
  };

  // Callback from the Input field when editing the rule label (live error checking)
  const changeLabel = (newLabel) => {
    validateLabel(newLabel);
    setLabel(newLabel);

    // auto-gen name field on create, append suffix if collision
    if (mode === constants.CREATE_MODE) {
      let autoGeneratedName = getNameFromLabel('rule-', newLabel, existingRuleNames);
      setName(autoGeneratedName);
    }
  };

  // Close the modal
  const close = () => {
    props.setClosed();
  };

  // Attempt to save the rule after validating all input.
  // Only saves if all validation succeeds
  const save = async () => {
    let hasError = validateLabel(label);

    if (!validateInput()) {
      hasError = true;
    }

    if (!validateAdditionalDetailsInput(description)) {
      hasError = true;
    }

    if (!hasError) {
      await sendSaveRequest();
      close();
    }
  };

  /* eslint-disable no-lone-blocks */
  // Disabled so that the case blocks look the same.

  // Validate Additional details
  const validateAdditionalDetailsInput = (newDescription) => {
    let hasError = false;
    if (newDescription !== undefined && newDescription.length > constants.TEXTAREA_CHAR_LIMIT) {
      hasError = true;
      setDescriptionErrorText(errors.CHAR_LIMIT_SQL);
    } else {
      setDescriptionErrorText('');
    }
    return !hasError;
  };

  // Validate that all the conditions are set correctly and a notification has been selected
  const validateInput = () => {
    let hasError = false;

    // Check that at least one notification channel is selected
    if (selectedChannels.length === 0) {
      setChannelRequiredError('This field is required.');
      hasError = true;
    } else {
      setChannelRequiredError('');
    }

    let ruleBuilderHasError = ruleBuilderRef.current.validateInput();

    return !ruleBuilderHasError && !hasError;
  };

  // Save the state of the rule to the API
  const sendSaveRequest = async () => {
    let payload = {
      label: label.trim(),
      name: name.trim(),
      service: serviceName,
      serviceId: serviceId,
      type: 'ROUTING',
      typeConfig: {
        notifiers: [...selectedChannels.map((ch) => ch.name)],
      },
      filter: ruleBuilderRef.current.getFilter(),
      isCustom: triggerType === 'custom',
      template: {
        description: description,
      },
    };
    if (mode === constants.EDIT_MODE) {
      payload = { ...payload, id: Number(ruleId) };
    }

    const response = await apiCalls.createOrUpdateRuleForService(mode, payload);

    if (response.status === 200 || response.status === 201) {
      if (mode === constants.CREATE_MODE) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Notification rule was created.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      } else if (mode === constants.EDIT_MODE) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Notification rule was saved.',
            toastVariant: 'success',
            showToast: true,
          },
        });
      }
      refreshContext.refreshRules();
    }
  };

  const onShowChange = (evt, dta) => {
    validateAdditionalDetailsInput(evt.target.value);
    setDescription(evt.target.value);
  };

  const channelData = { id: '', name: '', type: '', isDefault: false, typeConfig: {} };

  return (
    <Fragment>
      <Modal
        containerClassName="notification-rule-modal-container"
        contentClassName="notification-rule-modal-content"
        isOpen={props.isOpen}
        heading={rulesModalHeading}
        dismissOnClickOutside={false}
        onRequestClose={close}
        footer={[
          <Button key="cancel" label="Cancel" onClick={() => close()} />,
          <Button key="save" label="Save" variant="brand" onClick={() => save()} />,
        ]}
      >
        <ExpandableSection title="Rule Information">
          <Input
            inputRef={labelRef}
            className="notification-rule-modal-nameinput"
            required
            label="Rule Name"
            errorText={ruleLabelError}
            onChange={(event, data) => changeLabel(data.value)}
            value={label}
          />
          <RuleExpressionBuilder
            ref={ruleBuilderRef}
            showLoading={showLoading}
            triggerType={triggerType}
            setTriggerType={setTriggerType}
            resourceFields={resourceFields}
            setResourceFields={setResourceFields}
            displayResourceFields={displayResourceFields}
            setDisplayResourceFields={setDisplayResourceFields}
            conditions={conditions}
            setConditions={setConditions}
            customLogic={customLogic}
            setCustomLogic={setCustomLogic}
            fetchGroupingRulesList={fetchGroupingRulesList}
            hasGroupingRuleCondition={hasGroupingRuleCondition}
          />
        </ExpandableSection>
        <ExpandableSection title="Notification Channels">
          {/* ref={notifierRef}  */}

          <Combobox
            className="notification-rule-modal-channelscombo notification-rule-modal-image"
            classNameMenu="modal-combobox-dropdown"
            id="combobox-base"
            required
            errorText={channelRequiredError}
            events={{
              onChange: (event, { value }) => {
                setNotificationChannelSearchValue(value);
              },
              onRequestRemoveSelectedOption: (event, data) => {
                setSelectedChannels(data.selection);
              },
              onSubmit: (event, { value }) => {},
              onSelect: (event, data) => {
                setNotificationChannelSearchValue('');
                if (data.selection.find((ch) => ch.id === addNewChannelID)) {
                  setAddChannelPressed(true);
                  setNotificationChannelModalOpen(true);
                } else {
                  setSelectedChannels(data.selection);
                }
              },
            }}
            optionsAddItem={[
              {
                id: addNewChannelID,
                icon: <Icon assistiveText={{ label: 'Add' }} category="utility" size="x-small" name="add" />,
                label: 'New Notification Channel',
              },
            ]}
            labels={{
              label: 'Specify where to send the notification:',
              placeholder: 'Search Channels...',
              noOptionsFound: 'No available channels to add',
            }}
            menuItemVisibleLength={5}
            multiple
            predefinedOptionsOnly
            options={comboboxFilterAndLimit({
              inputValue: notificationChannelSearchValue,
              limit: 100,
              options: availableChannels,
              selection: selectedChannels,
            })}
            menuPosition="overflowBoundaryElement"
            value={notificationChannelSearchValue}
            selection={selectedChannels}
          />
        </ExpandableSection>
        <ExpandableSection title="Additional Details">
          <Textarea
            label="Custom description to include with notification"
            // eslint-disable-next-line no-template-curly-in-string
            placeholder="Standard alert fields can be referenced using the syntax ${fieldName} or ${custom.fieldName} for custom alert fields. Ex: Sev ${severity} alert received for domain: ${custom.domain}. Field names are case sensitive."
            onChange={(dta) => onShowChange(dta)}
            value={description}
            errorText={descriptionErrorText}
          />
        </ExpandableSection>

        {isNotificationChannelModalOpen ? (
          <NotificationChannelsModal
            isOpen={true}
            setIsOpen={setNotificationChannelModalOpen}
            mode={constants.CREATE_MODE}
            serviceName={serviceName}
            serviceId={serviceId}
            data={channelData}
            notifiers={availableChannels}
            setShowToast={setShowCreatedChannelToast}
            setToastMessage={setToastMessage}
            setToastVariant={setToastVariant}
          />
        ) : null}
      </Modal>
    </Fragment>
  );
};

NotificationRuleModal.propTypes = {
  isOpen: PropTypes.bool,
  setClosed: PropTypes.func,
  mode: PropTypes.string,
  serviceId: PropTypes.number,
  existingRuleNames: PropTypes.array,
  existingRuleLabels: PropTypes.array,
  data: PropTypes.object,
  setToastMessage: PropTypes.func, // optional
  setToastVariant: PropTypes.func, // optional
  setShowToast: PropTypes.func, // optional
};

export default NotificationRuleModal;
