import React from 'react';
import { DataTable, DataTableCell, DataTableColumn, Icon } from '@salesforce/design-system-react';
import { constants } from '../../constants';

const ServicesListTable = (props) => {
  const handleSort = props.handleSort;
  const searchedList = props.searchedList;
  const sortProperties = props.sortProperties;

  const LinkDataTableCell = ({ children, ...props }) => {
    if (props.field === constants.SERVICE_CATALOG) {
      const url = `https://gus.lightning.force.com/lightning/r/Service__c/${props.item.serviceCatalogId}/view`;

      return (
        <DataTableCell {...props}>
          {props.item.serviceCatalogId ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {props.item.serviceCatalogId}
            </a>
          ) : null}
        </DataTableCell>
      );
    } else if (props.field === constants.SERVICE_NAME) {
      const url = `/services/${props.item.id}?tab=0`;
      return (
        <DataTableCell {...props}>
          <a href={url}>{props.item.serviceName}</a>
        </DataTableCell>
      );
    }
  };
  LinkDataTableCell.displayName = DataTableCell.displayName;

  const CheckboxDataTableCell = ({ children, ...props }) => {
    return (
      <DataTableCell {...props}>
        {props.item.active ? (
          <Icon
            style={{ marginLeft: '12px', marginBottom: '3px' }}
            assistiveText={{ label: 'help link' }}
            category="utility"
            name="check"
            size="xx-small"
            className="slds-icon-text-success"
          />
        ) : null}
      </DataTableCell>
    );
  };
  CheckboxDataTableCell.displayName = DataTableCell.displayName;

  return (
    <DataTable
      id="services"
      assistiveText={{
        actionsHeader: 'actions',
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
        selectAllRows: 'Select all rows',
        selectRow: 'Select this row',
      }}
      fixedHeader
      fixedLayout
      joined
      items={searchedList}
    >
      <DataTableColumn
        isSorted={sortProperties.sortField === constants.SERVICE_NAME}
        sortDirection={sortProperties.sortField === constants.SERVICE_NAME ? sortProperties.sortDirection : null}
        sortable
        label="Service Name"
        primaryColumn
        property={constants.SERVICE_NAME}
        onSort={handleSort}
        truncate={true}
        width="30%"
      >
        <LinkDataTableCell field={constants.SERVICE_NAME} />
      </DataTableColumn>
      <DataTableColumn
        label="API Name"
        property={constants.API_NAME}
        onSort={handleSort}
        sortable
        isSorted={sortProperties.sortField === constants.API_NAME}
        sortDirection={sortProperties.sortField === constants.API_NAME ? sortProperties.sortDirection : null}
        width="25%"
      />
      <DataTableColumn
        label="Owners"
        property={constants.OWNERS}
        onSort={handleSort}
        sortable
        isSorted={sortProperties.sortField === constants.OWNERS}
        sortDirection={sortProperties.sortField === constants.OWNERS ? sortProperties.sortDirection : null}
        width="25%"
      />
      <DataTableColumn
        label="GUS Service"
        property="catalog"
        onSort={handleSort}
        sortable
        isSorted={sortProperties.sortField === constants.SERVICE_CATALOG}
        sortDirection={sortProperties.sortField === constants.SERVICE_CATALOG ? sortProperties.sortDirection : null}
        width="12%"
      >
        <LinkDataTableCell field={constants.SERVICE_CATALOG} property={constants.SERVICE_CATALOG} />
      </DataTableColumn>
      <DataTableColumn
        label="Active"
        property={constants.ACTIVE}
        onSort={handleSort}
        sortable
        isSorted={sortProperties.sortField === constants.ACTIVE}
        sortDirection={sortProperties.sortField === constants.ACTIVE ? sortProperties.sortDirection : null}
        width="8%"
      >
        <CheckboxDataTableCell />
      </DataTableColumn>
    </DataTable>
  );
};

export default React.memo(ServicesListTable);
