import React from 'react';
import PropTypes from 'prop-types';
import noContentImage from '../images/NoTask.svg';

const NoContentCard = ({ title, description }) => {
  return (
    <article className="slds-card empty-card">
      <div className="slds-card__body">
        <div>
          <img alt={'Empty Card'} className="empty-card-image" src={noContentImage}></img>
          <p className="slds-text-heading_medium slds-m-top_small">{title}</p>
          <h3 className="slds-m-top_small empty-card-message">{description}</h3>
        </div>
      </div>
    </article>
  );
};

NoContentCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default NoContentCard;
