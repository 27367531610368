import React, { Fragment } from 'react';
import { Tooltip, Checkbox } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import './CheckboxEx.scss';

const CheckboxEx = (props) => {
  return (
    <Fragment>
      <div className="slds-form-element">
        <label className="slds-form-element__label">{props.label}</label>
        {props.tooltip ? <Tooltip id="checkboxEx-tooltip" align="right" content={props.tooltip} /> : null}
        <Checkbox
          className="checkboxEx-checkbox"
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
          checked={props.checked}
          disabled={props.disabled}
        />
      </div>
      {props.disabled ? <div className="checkboxEx-disabled-text">{props.disabledText}</div> : null}
    </Fragment>
  );
};

CheckboxEx.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
};

export default CheckboxEx;
