import React, { useContext, useState, useEffect, Fragment } from 'react';
import {
  Card,
  Button,
  ButtonGroup,
  Icon,
  PageHeader,
  PageHeaderControl,
  Tabs,
  TabsPanel,
  Dropdown,
} from '@salesforce/design-system-react';
import NotificationRules from './NotificationRules';
import SuppressionRules from './SuppressionRules';
import GroupingRules from './GroupingRules';
import NotificationChannels from './NotificationChannels';
import Alerts from './Alerts/Alerts';
import apiCalls from '../shared/api';
import queryString from 'query-string';
import './ServiceDetail.scss';
import { constants, errors } from '../../constants';
import ServicesEditModal from './ServicesModal/ServicesEditModal';
import ConfirmModal from '../shared/Modal/ConfirmModal';
import { uamStore } from '../shared/store';
import { ToastContext } from '../shared/toast-context';
import { RefreshContext } from '../shared/refresh-context';

const ServiceDetail = (props) => {
  const serviceId = props.match.params.serviceId;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(parseInt(queryString.parse(props.location.search).tab));
  const [existingRuleNames, setExistingRuleNames] = useState([]);
  const [existingRuleLabels, setExistingRuleLabels] = useState([]);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(constants.CREATE_MODE);
  const [modalData, setModalData] = useState({});

  const [refresh, setRefresh] = useState(false);

  const toastContext = useContext(ToastContext);
  const toastState = toastContext.state;
  const toastDispatch = toastContext.dispatch;

  // ConfirmModal Variables
  const globalContext = useContext(uamStore);
  const globalState = globalContext.state;
  const [variant, setVariant] = useState('');
  const [deleteHeader, setDeleteHeader] = useState('');

  const [showUnableToEditModal, setShowUnableToEditModal] = useState(false);

  // Refresh Context
  const refreshContext = useContext(RefreshContext);

  const setOpenEdit = React.useCallback((data) => {
    setModalData({
      id: data.id.toString(),
      active: data.isActive,
      serviceName: data.label,
      apiName: data.name,
      serviceCatalogId: data.serviceCatalogId === undefined ? '' : data.serviceCatalogId,
      status: data.status,
      serviceOwners: data.serviceOwners,
    });
    setMode(constants.EDIT_MODE);
    setIsModalOpen(true);
  }, []);

  const checkOwner = (owners) => {
    const isOwner = owners.find((owner) => owner.toLowerCase() === globalState.userNameLowercase);
    return isOwner ? true : false;
  };

  const setOpenDelete = React.useCallback(async (data) => {
    let allowOwnerToDelete = checkOwner(data.serviceOwners);
    if (allowOwnerToDelete) {
      setVariant(constants.CONFIRM);
      setDeleteHeader('Delete ' + data.label);
    } else {
      setVariant(constants.INFORM);
      setDeleteHeader(errors.UNABLE_TO_DELETE);
    }
    setMode(constants.DELETE_MODE);
    setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteCallback = async (deleteService) => {
    if (deleteService === constants.DELETE) {
      const response = await apiCalls.deleteServiceEndpoint(serviceId);
      if (response.status === 200 || response.status === 204) {
        toastDispatch({
          type: 'SET_TOAST_STATE',
          value: {
            ...toastState,
            toastMessage: 'Service was deleted.',
            toastVariant: 'success',
            showToast: true,
          },
        });
        props.history.push('/services');
      }
    }
    setIsModalOpen(false);
  };

  const deleteContent = (
    <Fragment>
      <span>
        Are you sure you want to delete? Once deleted, any alerts sent to Alert Router for this service will not be
        received and processed and should be updated or removed.
      </span>
    </Fragment>
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await apiCalls.getServiceById(serviceId);
        const data = response.data;
        if (response.status === 200) {
          setData(data);
        } else {
          setIsError(true);
          setData(data);
        }
      } catch (ex) {
        console.log('ERROR getting service with id ' + serviceId + ': ', ex);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [serviceId, refresh]);

  useEffect(() => {
    const fetchRules = async () => {
      try {
        const response = await apiCalls.getRulesByServiceId(serviceId);
        const data = response.data;
        if (response.status === 200) {
          const ruleNames = data.map((rule) => rule.name);
          const ruleLabels = data.map((rule) => rule.label);
          setExistingRuleNames(ruleNames);
          setExistingRuleLabels(ruleLabels);
        }
      } catch (ex) {
        console.log('ERROR getting the rules for the service with id ' + serviceId + ': ', ex);
      }
    };
    fetchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, refreshContext.rules, refreshContext.groupingRules, refreshContext.suppressionRules]);

  useEffect(() => {
    setSelectedTab(parseInt(queryString.parse(props.location.search).tab));
  }, [props.location]);

  const actions = () => (
    <Fragment>
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-action">
          <Button
            title="Edit"
            label="Edit"
            onClick={() => {
              if (checkOwner(data.serviceOwners)) {
                setOpenEdit(data);
              } else {
                setShowUnableToEditModal(true);
              }
            }}
          />
          <Dropdown
            className="service-detail-dropdown"
            assistiveText={{ icon: 'More options' }}
            buttonVariant="icon"
            iconCategory="utility"
            iconName="down"
            iconVariant="border-filled"
            onSelect={(item) => {
              setOpenDelete(data);
            }}
            options={[{ label: 'Delete', value: serviceId }]}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </Fragment>
  );

  const details = (data) => [
    {
      label: 'Owners',
      content: data.serviceOwners.join(', '),
    },
    {
      label: 'API Name',
      content: data.name,
    },
    {
      label: 'Active',
      content: data.isActive ? (
        <Icon className="slds-icon-text-success slds-icon_xx-small" category="utility" name="check" />
      ) : null,
    },
    {
      label: 'GUS Service',
      content: data.serviceCatalogId ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={'https://gus.lightning.force.com/lightning/r/Service__c/' + data.serviceCatalogId + '/view'}
        >
          {data.serviceCatalogId}
        </a>
      ) : null,
    },
  ];

  const tabSelected = (idx) => {
    props.history.replace({
      pathname: '/services/' + serviceId,
      search: '?' + new URLSearchParams({ tab: idx }).toString(),
    });
    setSelectedTab(idx);
  };

  return (
    <Fragment>
      {isModalOpen && mode === constants.EDIT_MODE ? (
        <ServicesEditModal
          isOpen={true}
          setIsModalOpen={setIsModalOpen}
          data={modalData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
      {showUnableToEditModal ? (
        <ConfirmModal
          heading={'Unable to Edit'}
          dismissOnClickOutside={false}
          onRequestCloseCallback={() => {
            setShowUnableToEditModal(false);
          }}
          informContent={
            <span>
              You do not have permission to edit the service, <strong>{data.label}</strong>. Only service owners or an
              administrator are permitted to edit the service.
            </span>
          }
          variant={constants.INFORM}
        />
      ) : null}
      {isModalOpen && mode === constants.DELETE_MODE ? (
        <ConfirmModal
          heading={deleteHeader}
          dismissOnClickOutside={false}
          onRequestCloseCallback={onDeleteCallback}
          deleteContent={deleteContent}
          informContent={
            <span>
              You do not have permission to delete the service, <strong>{data.label}</strong>. Only service owners or an
              administrator are permitted to delete the service.
            </span>
          }
          variant={variant}
        />
      ) : null}
      {!isLoading && !isError && data ? (
        <div className="flex-column-container">
          <PageHeader
            details={details(data)}
            icon={<Icon category="standard" name="dynamic_record_choice" />}
            label={<a href="/services">Services</a>}
            onRenderActions={actions}
            title={data.label}
            variant="record-home"
          />
          <Card hasNoHeader={true} className="service-detail-card slds-m-top_small flex-column-container">
            <Tabs onSelect={tabSelected} selectedIndex={selectedTab}>
              {process.env.REACT_APP_ALERTS_ENABLED === 'TRUE' ? (
                <TabsPanel label="Alerts Received" className="slds-text-body flex-column-container">
                  <Alerts history={props.history} serviceId={data.id} />
                </TabsPanel>
              ) : null}
              <TabsPanel label="Suppression Rules" className="slds-text-body flex-column-container">
                <SuppressionRules
                  serviceId={data.id}
                  serviceName={data.name}
                  serviceOwners={data.serviceOwners}
                  existingRuleNames={existingRuleNames}
                  existingRuleLabels={existingRuleLabels}
                ></SuppressionRules>
              </TabsPanel>
              <TabsPanel label="Grouping Rules" className="slds-text-body flex-column-container">
                <GroupingRules
                  serviceId={data.id}
                  serviceName={data.name}
                  serviceOwners={data.serviceOwners}
                  existingRuleNames={existingRuleNames}
                  existingRuleLabels={existingRuleLabels}
                ></GroupingRules>
              </TabsPanel>
              <TabsPanel label="Notification Rules" className="slds-text-body flex-column-container">
                <NotificationRules
                  serviceId={data.id}
                  serviceName={data.name}
                  serviceOwners={data.serviceOwners}
                  existingRuleNames={existingRuleNames}
                  existingRuleLabels={existingRuleLabels}
                ></NotificationRules>
              </TabsPanel>
              <TabsPanel label="Notification Channels" className="slds-text-body flex-column-container">
                <NotificationChannels
                  serviceId={data.id}
                  serviceName={data.name}
                  serviceOwners={data.serviceOwners}
                ></NotificationChannels>
              </TabsPanel>
            </Tabs>
          </Card>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ServiceDetail;
